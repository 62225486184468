import * as React from 'react';
import Button from '@material-ui/core/Button/Button';
import styled from 'styled-components';
import Icon, { IconName } from './Icon';
import darken from 'polished/lib/color/darken';

interface FabProps {
  type: 'ADD' | 'EDIT';
  onClick?: any;
  footerMargin?: boolean;
}

const StyledFab = styled(Button)<{ footermargin?: string }>`
  && {
    position: fixed;
    bottom: calc(
      ${props =>
          props.footermargin ? props.theme.layout.footerHeight : '1rem'} + 1rem
    );
    right: 1rem;
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    background: ${props => props.theme.colors.blue};
    cursor: pointer;
    &:hover {
      background: ${props => darken(0.07, props.theme.colors.blue)};
    }
    @media screen and (min-width: 1600px) {
      right: 2vw;
    }
    @media screen and (min-width: 2000px) {
      right: 20vw;
    }
  }
`;

const Fab: React.FC<FabProps> = props => (
  <StyledFab
    onClick={props.onClick}
    footermargin={(props.footerMargin && props.footerMargin.toString()) || ''}
  >
    {props.type === 'ADD' && <Icon name={IconName.ADD} />}
    {props.type === 'EDIT' && <Icon name={IconName.EDIT} />}
  </StyledFab>
);

export default Fab;
