import {InjectedFormikProps, withFormik} from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';
import {SecondaryButton} from '../../_common/_elements';
import {auth} from '../../../database/fbApp';
import firebase from "firebase/compat/app";
import AuthCredential = firebase.auth.AuthCredential;
import EmailAuthProvider = firebase.auth.EmailAuthProvider;
import {toast} from 'react-toastify';
import {UserController} from '../../../controllers/userController';
import {User} from 'src/models/user';
import {TextField} from '@material-ui/core';
import { updateEmail, sendEmailVerification, reauthenticateWithCredential } from "firebase/auth";

interface FormValues {
    newEmail: string;
    confirmNewEmail: string;
    currentEmail: string;
    currentPassword: string;
}

interface FormProps {
    currentUser: User;
}

const Form = styled.form`
  margin-top: 2rem;
  margin-bottom: 2rem;
  > .btn {
    margin-top: 4rem;
  }
  padding: 2rem;
`;

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = ({
                                                                             handleSubmit,
                                                                             isSubmitting,
                                                                             handleChange,
                                                                             values,
                                                                             errors,
                                                                             touched,
                                                                             setFieldValue,
                                                                         }) => (
    <Form
        onSubmit={e => {
            e.stopPropagation();
            handleSubmit(e);
        }}
    >
        <TextField
            id="newEmail"
            name="newEmail"
            fullWidth={true}
            label={errors.newEmail && touched.newEmail ? errors.newEmail : 'Email'}
            error={Boolean(touched.newEmail && errors.newEmail)}
            type="email"
            onChange={handleChange}
            value={values.newEmail}
        />
        <TextField
            id="confirmNewEmail"
            name="confirmNewEmail"
            fullWidth={true}
            label={errors.confirmNewEmail && touched.confirmNewEmail ? errors.confirmNewEmail : 'confirmNewEmail'}
            error={Boolean(touched.confirmNewEmail && errors.confirmNewEmail)}
            type="email"
            onChange={handleChange}
            value={values.confirmNewEmail}
        />

        <h3>for security reason please provider your current email and password to perform this action</h3>
        <TextField
            id="currentEmail"
            name="currentEmail"
            required
            fullWidth={true}
            label={errors.currentEmail && touched.currentEmail ? errors.currentEmail : 'Current Email'}
            error={Boolean(touched.currentEmail && errors.currentEmail)}
            type="email"
            onChange={handleChange}
            value={values.currentEmail}
        />
        <TextField
            id="currentPassword"
            name="currentPassword"
            required
            fullWidth={true}
            label={errors.currentPassword && touched.currentPassword ? errors.currentPassword : 'Current Password'}
            error={Boolean(touched.currentPassword && errors.currentPassword)}
            type="password"
            onChange={handleChange}
            value={values.currentPassword}
        />
        <SecondaryButton className="btn" type="submit" disabled={isSubmitting}>
            Submit
        </SecondaryButton>
    </Form>
);

const ChangeEmailForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: props => ({
        newEmail: '',
        confirmNewEmail: '',
        currentEmail: '',
        currentPassword: '',
    }),
    validationSchema: Yup.object().shape({
        currentEmail: Yup.string()
            .email()
            .required('This field is required'),
        currentPassword: Yup.string(),
        newEmail: Yup.string()
            .email('This is not a valid email')
            .required('This field is required'),
        confirmNewEmail: Yup.string()
            .email('This is not a valid email')
            .required('This is a required field'),
    }),
    handleSubmit: async (values, {props, setSubmitting, setFieldError}) => {
        setSubmitting(true);
        const credential: AuthCredential = EmailAuthProvider.credential(values.currentEmail, values.currentPassword);
        try {
            if (auth.currentUser) {
                await reauthenticateWithCredential(auth.currentUser, credential).then(() => {
                    // Update successful.
                }).catch(() => {
                    toast.error("Please provide the correct credentials")
                });
                await updateEmail(auth.currentUser, values.newEmail).then(() => {
                    // Update successful.
                }).catch(() => {
                    toast.error("We couldn't update your email, please try again")
                });
               await sendEmailVerification(auth.currentUser).then(() => {
                    // Update successful.
                }).catch(() => {
                    toast.error("There was an error sending your activation email, please try again")
                });
                await new UserController().update(
                    auth.currentUser.uid,
                    new User({...props.currentUser, email: values.newEmail})
                );
                await auth.signOut();
                toast.success('Your email was changed successfully, please check your mail for the activation email');
            }
        } catch (e) {
            console.log(e);
            toast.error('There was an error, please try again');
            setSubmitting(false);
        }
        setSubmitting(false);
    },
})(InnerForm);

export default ChangeEmailForm;
