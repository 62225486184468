export class TimeTableItem {
    id: string;
    title: string;
    timeStart: Date;
    timeEnd: Date;
    isRoute: boolean;
    routeStart: string;
    routeStartPre?: string;
    routeEnd: string;
    distanceKm: string;
    distanceH: string;
    constructor(o: Partial<TimeTableItem>){
        this.id = o.id  || "";
        this.title = o.title || "";
        this.timeStart = new Date(o.timeStart || new Date());
        this.timeEnd = new Date(o.timeEnd || new Date());
        this.isRoute = o.isRoute || false;
        this.routeStart = o.routeStart || "";
        this.routeEnd = o.routeEnd || "";
        this.distanceKm = o.distanceKm || "";
        this.distanceH = o.distanceH || "";
    }
}