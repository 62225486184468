import React, { useState, useEffect, FC, useContext } from 'react';
import SettingsContainer from '../_elements/SettingsContainer';
import TopNav from '../../_common/_elements/TopNav';
import styled, { withTheme } from 'styled-components';
import UserDataForm from './UserDataForm';
import { User } from '../../../models/user';
import { Routes } from '../../../constants/Routes';
import { navigate } from '@reach/router';
import { toast } from 'react-toastify';
import { UserController } from '../../../controllers/userController';
import PaymentButton from './PaymentButton';
import { RootStoreContext } from 'src/store/RootStore';
import { observer } from 'mobx-react-lite';
import SettingsTopNav from '../_elements/SettingsTopNav';
import { Icon } from 'src/views/_common/_elements';
import { IconName, IconSize } from 'src/views/_common/_elements/Icon';
import AvatarImage from 'src/views/_common/_elements/AvatarImage';
import { userToInitials } from 'src/utils/stringUtils';
import DesktopSidebar from 'src/views/_common/sidebar/DesktopSidebar';
import useWindowSize from 'src/hooks/useWindowSize';
import { AppTheme } from 'src/theme';
import useIsDesktop from 'src/hooks/useIsDesktop';
import { Label } from '@material-ui/icons';
import useCloudinaryImage from 'src/hooks/useCloudinary';

interface Props {
  path: string;
  theme: AppTheme;
}
const Container = styled.div`
  width: 100%;
  max-width: 1300px;
  position: relative;
  margin: 0 auto;
  margin-top: 4rem;
  height: 100%;
`;

const UserSettingsContainer = styled.div`
  height: 100%;
  padding: 1.5rem;
`;

const ProfileImg = styled(AvatarImage)`
  &::before {
    width: 100%;
    height: 100%;
    background: ${props => props.theme.colors.darkGrey};
    opacity: 0.3;
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
  }
`;

const AvatarContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const UploadButton = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0.9;
  transform: translate3d(-50%, -50%, 0);
`;

const UserSettingsPage: FC<Props> = observer(({ theme }) => {
  const {
    userStore: { currentUser },
  } = useContext(RootStoreContext);
  const imageUrl = useCloudinaryImage(currentUser!.imageUrl, handleImageUploadSuccess, handleImageUploadError);
  const isDesktop = useIsDesktop();

  function handleImageUploadSuccess(newImageUrl: string) {
    new UserController().update(currentUser!.id!, {
      ...currentUser!,
      imageUrl: newImageUrl,
    });
    (window as any).gighub.CurrentUser.imageUrl = newImageUrl;
    toast.success('Successfully changed Image');
  }

  function handleImageUploadError(error: any) {
    toast.success('There was an error uploading your image, please try again');
  }


  return (
    <SettingsContainer>
      {isDesktop && <DesktopSidebar />}
      <Container>
        {!isDesktop && <SettingsTopNav text={'User Account'} clickHandler={() => navigate(Routes.HOME)} />}
        <UserSettingsContainer>
          <AvatarContainer>
            {currentUser && <ProfileImg size="medium" fallback={userToInitials(currentUser)} imgUrl={imageUrl} />}
            <UploadButton name={IconName.UPLOAD} size={IconSize.XL} />
            <div id="upload_widget_opener" />
          </AvatarContainer>
          <UserDataForm user={currentUser!} />
        </UserSettingsContainer>
      </Container>
    </SettingsContainer>
  );
});

export default withTheme(UserSettingsPage);
