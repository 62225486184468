import {MemberRoles} from "./MemberRoles";


export class Member{
    role: MemberRoles;
    fullName: string;
    imageUrl?: string;


    constructor(o:Partial<Member>){
        this.role = o.role || MemberRoles.SUB;
        this.fullName = o.fullName || "";
        this.imageUrl = o.imageUrl || "";
    }
}

