import {AvailabilityStati} from "./AvailabilityStati";

export class Availability{
    status?: AvailabilityStati;
    isFreeOfExpenses?: boolean;
    isInNeedOfAccommodation?: boolean;
    via?: string;
    comment?: string;
    route?: Route;


    constructor(o: Partial<Availability>){
        this.status = o.status || AvailabilityStati.INVITED;
        this.isFreeOfExpenses = o.isFreeOfExpenses || false;
        this.isInNeedOfAccommodation = o.isInNeedOfAccommodation || false;
        this.via  = o.via || "";
        this.comment  = o.comment || "";
        this.route  = o.route || undefined;
    }


}

export interface Route {
    startAddress: string;
    endAddress: string;
    distance: string;
    duration: string;
}

