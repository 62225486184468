import {Model} from "./model";
import {Collections} from "./basics/Collections";
import {NotificationTypes} from "./basics/NotificationTypes";
import {auth} from "../database/fbApp";

export class GighubNotification implements Model {
    collection: Collections;
    id: string;

    message: string;
    idRecipent: string;
    idSender: string;
    isSeen: boolean;
    isAnswered: boolean;
    dateCreated: Date;
    idReference: string;
    idBand: string;
    type: NotificationTypes;

    constructor(o: Partial<GighubNotification>) {
        this.collection = Collections.NOTIFICATIONS;
        this.id = o.id || "";
        this.idReference = o.idReference || "";
        this.idBand = o.idBand || "";
        this.idRecipent = o.idRecipent || (auth.currentUser ? auth.currentUser.uid : "");
        this.idSender = o.idSender || (auth.currentUser ? auth.currentUser.uid : "");
        this.type = o.type || NotificationTypes.SYSTEMNOTIFICATION;
        this.message = o.message || "";
        this.isSeen = o.isSeen || false;
        this.isAnswered = o.isAnswered || false;
        this.dateCreated = o.dateCreated || new Date();
    }
}