import * as React from 'react';
import { FC } from 'react';
import AvatarImageSmall from "../../../../_common/_elements/AvatarImage";
import Toggle from "../../../../_common/utilities/Toggle";
import { MemberRoles } from "../../../../../models/basics/MemberRoles";
import { Availability } from "../../../../../models/basics/availability";
import styled from "styled-components";
import AvailabilityForm from "./AvailabilityForm";
import { Member } from "../../../../../models/basics/member";
import AvailabilityInfo from "./AvailabilityInfo";
import { fullnameToInitials } from 'src/utils/stringUtils';
import { Dialog } from "@material-ui/core";
import AvailabilityColor from "../../../../_common/_elements/AvailabilityColor";
import { AvailabilityStati } from "../../../../../models/basics/AvailabilityStati";
import { DeleteForm } from "../../../../_common/utilities/DeleteForm";
import { DangerButton } from "../../../../_common/_elements";
import { Tooltip } from "@material-ui/core";


interface Props {
    availability: Availability
    member: Member;
    idUser: string;
    imageUrl: string;
    handleSubmit: (a: Availability) => any;
    handleDelete: (idUser: string) => any;
    authRole: MemberRoles;
    teaser?: boolean;
}

const AvailabilityContainer = styled.div`
`;

const AvailabilityWrapper: FC<Props> = ({ availability, member, idUser, imageUrl, handleDelete, handleSubmit, authRole, teaser }) => {

    const initials = fullnameToInitials(member.fullName);
    return (
        <AvailabilityContainer>
            <Tooltip title={member.fullName} enterDelay={20}>
                <Toggle>
                    {({ toggle, on }) => (
                        <>
                            <div onClick={toggle}>
                                <AvailabilityColor status={availability.status ? availability.status : AvailabilityStati.INVITED} >
                                    <AvatarImageSmall imgUrl={imageUrl} size="small" fallback={initials} />
                                </AvailabilityColor>

                            </div>
                            {!teaser && on && <Dialog open={on} onClose={toggle}>
                                {((window as any).gighub.CurrentUser.id === idUser) ? (
                                    <><AvailabilityForm
                                        member={member}
                                        availability={availability}
                                        handleSubmit={handleSubmit}
                                        handleDelete={handleDelete}
                                        idUser={idUser}
                                        toggle={toggle}
                                    />{idUser && handleDelete &&
                                        <Toggle>
                                            {({ on: onDelete, toggle: toggleDelete }) => (
                                                <>
                                                    <DeleteForm open={onDelete} handleClose={toggleDelete} modelName={"Availability"}
                                                        handleDelete={handleDelete} id={idUser} />
                                                    <DangerButton style={{ marginBottom: '1rem' }} type="button" color="secondary"
                                                        onClick={toggleDelete}>Delete this
                                                        availability</DangerButton>
                                                </>
                                            )}
                                        </Toggle>
                                        }</>) : (
                                    <AvailabilityInfo
                                        member={member}
                                        availability={availability}
                                        handleDelete={handleDelete}
                                        idUser={idUser}
                                        authRole={authRole}
                                    />
                                )}
                            </Dialog>
                            }
                        </>
                    )}
                </Toggle>
            </Tooltip >
        </AvailabilityContainer >
    )
}


export default AvailabilityWrapper;