import { observable, computed, action, toJS } from 'mobx';
import { Band } from 'src/models/band';
import { RootStore } from './RootStore';
import { Store } from './Store';
import { isWithinInterval } from 'date-fns';
import { MAX_YEARLY_EVENTS } from 'src/constants/FreePlan';

export class BandsStore extends Store<Band> {
  @observable selectedBand: Band | null;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.selectedBand = null;
  }

  @action
  setSelectedBandFromLocalStorage = () => {
    const selectedBandId = localStorage.getItem('gighub-selected-band');
    if (selectedBandId && this.observables.some(b => b.id === selectedBandId)) {
      this.setSelectedBand(selectedBandId);
    } else {
      if (this.observables.length > 0) {
        this.setSelectedBand(this.observables[0].id);
      }
    }
  };

  @action
  setSelectedBand = (idBand: string) => {
    localStorage.setItem('gighub-selected-band', idBand);
    const newSelectedBand = this.getById(idBand).get();
    if (newSelectedBand && newSelectedBand.members[(window as any).gighub.CurrentUser.id]) {
      this.selectedBand = newSelectedBand;
      (window as any).gighub.CurrentUser.memberRole =
        newSelectedBand.members[(window as any).gighub.CurrentUser.id].role;
    }
  };
}
