import { observable } from 'mobx';
import { RootStore } from './RootStore';
import { User } from 'src/models/user';
import {Store} from "./Store";

export class UserStore extends Store<User>{
  @observable currentUser: User | null;

  constructor(rootStore: RootStore) {
    super(rootStore)
    this.currentUser = null;
  }
}



