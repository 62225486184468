import {Component} from 'react';
import {createPortal} from 'react-dom';

const portalRoot = document.getElementById('portal');

export default class Portal extends Component {

    el = document.createElement('div');

    componentDidMount() {
        if (portalRoot) {
            portalRoot.appendChild(this.el);
        }
    }

    componentWillUnmount() {
        if (portalRoot) {
            portalRoot.removeChild(this.el);
        }
    }

    render() {
        // @ts-ignore
        return createPortal(this.props.children, this.el);
    }
}