import { InjectedFormikProps, withFormik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';
import { SecondaryButton } from '../../_common/_elements';
import firebase from "firebase/compat/app";
import AuthCredential = firebase.auth.AuthCredential;
import EmailAuthProvider = firebase.auth.EmailAuthProvider;
import { getAuth, updatePassword, reauthenticateWithCredential } from "firebase/auth";
const auth = getAuth();
import { toast } from 'react-toastify';
import { TextField } from '@material-ui/core';

interface FormValues {
  newPassword: string;
  confirmNewPassword: string;
  currentEmail: string;
  currentPassword: string;
}

const Form = styled.form`
padding: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  > .btn {
    margin-top: 4rem;
  }
`;

const InnerForm: React.FC<InjectedFormikProps<{}, FormValues>> = ({
  handleSubmit,
  isSubmitting,
  handleChange,
  values,
  errors,
  touched,
  setFieldValue,
}) => (
    <Form
      onSubmit={e => {
        e.stopPropagation();
        handleSubmit(e);
      }}
    >
      <TextField
        id="newPassword"
        name="newPassword"
        fullWidth={true}
        label={errors.newPassword && touched.newPassword ? errors.newPassword : 'Password'}
        error={Boolean(touched.newPassword && errors.newPassword)}
        type="password"
        onChange={handleChange}
        value={values.newPassword}
      />
      <br />
      <TextField
        id="confirmNewPassword"
        name="confirmNewPassword"
        fullWidth={true}
        label={errors.confirmNewPassword && touched.confirmNewPassword ? errors.confirmNewPassword : 'Confirm Password'}
        error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
        type="password"
        onChange={handleChange}
        value={values.confirmNewPassword}
      />
      <br />
      <h3>for security reason please provider your current email and password to perform this action</h3>
      <TextField
        id="currentEmail"
        name="currentEmail"
        required
        fullWidth={true}
        label={errors.currentEmail && touched.currentEmail ? errors.currentEmail : 'Current Email'}
        error={Boolean(touched.currentEmail && errors.currentEmail)}
        type="email"
        onChange={handleChange}
        value={values.currentEmail}
      />
      <br />
      <TextField
        id="currentPassword"
        name="currentPassword"
        required
        fullWidth={true}
        label={errors.currentPassword && touched.currentPassword ? errors.currentPassword : 'Current Password'}
        error={Boolean(touched.currentPassword && errors.currentPassword)}
        type="password"
        onChange={handleChange}
        value={values.currentPassword}
      />
      <SecondaryButton className="btn" type="submit" disabled={isSubmitting}>
        Submit
    </SecondaryButton>
    </Form>
  );

const ChangePasswordForm = withFormik<{}, FormValues>({
  mapPropsToValues: props => ({
    newPassword: '',
    confirmNewPassword: '',
    currentEmail: '',
    currentPassword: '',
  }),
  validationSchema: Yup.object().shape({
    currentEmail: Yup.string()
      .email()
      .required('This field is required'),
    currentPassword: Yup.string(),
    newPassword: Yup.string()
      .required('This field is required')
      .min(6, 'Password must be at least 6 Characters'),
    confirmNewPassword: Yup.string()
      .required('This is a required field')
      .min(6, 'Password must be at least 6 Characters')
      .oneOf([Yup.ref('newPassword'), 'Passwords do not match']),
  }),
  handleSubmit: async (values, { props, setSubmitting, setFieldError }) => {
    setSubmitting(true);
    const credential: AuthCredential = EmailAuthProvider.credential(values.currentEmail, values.currentPassword);
    try {
      if (auth.currentUser) {
        await reauthenticateWithCredential(auth.currentUser, credential).then(() => {
          // User re-authenticated.
        }).catch(() => {
          toast.error('Please provide the correct credentials')
        });
        await updatePassword(auth.currentUser, values.newPassword).then(() => {
              // Update successful.
            }).catch((error) => {
              // An error ocurred
              // ...
            });
        await auth.signOut();
        toast.success('Your password was changed successfully, please log in again');
      }
    } catch (e) {
      console.log(e);
      toast.error('There was an error, please try again');
      setSubmitting(false);
    }
    setSubmitting(false);
  },
})(InnerForm);

export default ChangePasswordForm;
