import styled from "styled-components";
import { InputLabel } from "@material-ui/core";

const Label = styled(InputLabel)`
  && {
      font-size: 1.6rem;
      color: ${props => props.error ? props.theme.colors.danger : props.theme.colors.secondary};
      &:not(:last-of-type) {
        margin-bottom: 0.5rem;
        }
  }
`;


export default Label;