import styled from "styled-components";
import * as React from "react";
import { FC } from "react";
import { AvailabilityStati } from "../../../models/basics/AvailabilityStati";
import { theme } from "../../../theme";
import { EventStati } from "../../../models/basics/EventStati";



interface Props {
  status: AvailabilityStati | EventStati | string
  children: any
}



const Color = styled('div') <Props>`
  width: 5.5rem;
  height: 5.5rem;
  position: relative;
  border-radius: 50%;
  display: inline-block;
  background-size: cover;
  display: grid;
  place-items: center;
  background: ${(props) => {
    switch (props.status) {
      case AvailabilityStati.INVITED:
        return theme.trafficLight.darkGrey;
      case AvailabilityStati.ACCEPTED:
      case EventStati.CONFIRMED:
        return theme.trafficLight.green;
      case AvailabilityStati.DECLINED:
      case EventStati.CANCELLED:
        return theme.trafficLight.red;
      default:
        return theme.trafficLight.yellow;
    }
  }};
  &:hover {
    .AvatarText{
      display: block;
    }
  }
`;

const AvailabilityColor: FC<Props> = (props) => (
  <Color {...props}>
    {props.children}
  </Color>
)


export default AvailabilityColor;