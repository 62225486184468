import * as React from 'react';
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import TextFieldLight from "../../_common/_elements/TextFieldLight";
import { SecondaryButton } from "../../_common/_elements";
import * as isemail from 'isemail';
import styled, { withTheme } from "styled-components";
import Br from "../../_common/_elements/Br";
import { Link, navigate } from "@reach/router";
import { Routes } from 'src/constants/Routes';
import { RingLoader } from "react-spinners";
import { AppTheme } from "../../../theme";
import { auth, googleAuthProvider } from "../../../database/fbApp";
import { toast } from "react-toastify";
import { signInWithPopup, signInWithEmailAndPassword } from "firebase/auth";


interface LoginFormProps {
    theme: AppTheme;
}

interface LoginFormState {
    email: string;
    loading: boolean;
    password: string;
    error: {
        email: string,
        password: string;
    }
    loginError: boolean;
}

const Container = styled.div`
  display: grid;
  grid-row-gap: 1.1rem;
  align-items: end;
  margin-top: 4rem;
`;

const Form = styled.form`
  margin-top: 2rem;
`;

const ForgotPassword = styled(Link)`
  cursor: pointer;
  text-align: right;
  color: ${props => props.theme.colors.secondary};
  font-size: 1.4rem;
  margin-top: .5rem;
  margin-bottom: 2.5rem;
  display: block;
    &:hover {
    color: ${props => props.theme.colors.black};
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const SubmitButton = styled(SecondaryButton)`
`;


class LoginForm extends React.Component<LoginFormProps, LoginFormState> {

    state: LoginFormState = {
        email: '',
        loading: false,
        password: '',
        error: {
            email: '',
            password: ''
        },
        loginError: false,
    };


    handleGoogleLogin = async () => {
        try {
            signInWithPopup(auth, googleAuthProvider,);
            console.log('known google user logged in')
        }
        catch (e) {
            console.log('error')
        }
    };

    handleChange = (e: any) => {
        const change = {};
        change[e.target.name] = e.target.value;
        this.setState(change)
    };

    isValid(): boolean {
        const { email, password } = this.state;
        let error = false;
        if (email.length === 0) {
            this.setState({ error: { ...this.state.error, email: 'Please enter a email' } })
            error = true;
        } else if (!isemail.validate(email)) {
            this.setState({ error: { ...this.state.error, email: 'Please enter a valid email address' } })
            error = true;
        }
        if (password.length === 0) {
            this.setState({ error: { ...this.state.error, password: 'Please enter a password' } })
            error = true;
        }
        return !error;
    }

    handleStandardLogin = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { email, password } = this.state;
        if (this.isValid()) {
            this.setState({ loading: true });
            signInWithEmailAndPassword(auth, email, password)
                .then(res => {
                    console.log('signInWithEmailAndPassword resolved');
                    if (res.user && !res.user.emailVerified) {
                        toast.info('Please validate your Email first');
                        this.setState({
                            loginError: false,
                            loading: false,
                        });
                    } else {
                        this.setState({
                            loginError: false,
                            loading: false,
                        });
                        navigate(Routes.HOME);
                    }
                })
                .catch(_ => {
                    console.log('signInWithEmailAndPassword error');
                    this.setState({
                        loginError: true,
                        loading: false,
                    });
                    toast.error('Wrong Email or Password');
                });
        }
    };

    render() {
        const { email, password, error, loading } = this.state;
        return (
            <Container>
                <Form onSubmit={this.handleStandardLogin}>
                    <TextFieldLight
                        name="email"
                        type="email"
                        label={error.email ? error.email : 'email'}
                        placeholder="you@email.com"
                        value={email}
                        error={Boolean(error.email)}
                        onChange={this.handleChange}
                        fullWidth
                        required
                    />
                    <TextFieldLight
                        name="password"
                        type="password"
                        label={error.password ? error.password : 'password'}
                        error={Boolean(error.password)}
                        value={password}
                        onChange={this.handleChange}
                        fullWidth
                        required
                    />
                    <ForgotPassword to={Routes.FORGOT_PASSWORD}>Forgot password?</ForgotPassword>
                    {loading &&
                        <LoaderContainer>
                            <RingLoader
                                size={40}
                                color={this.props.theme.colors.secondary}
                                loading={loading}
                            />
                        </LoaderContainer>}
                    {!loading && <SubmitButton type="submit">Login</SubmitButton>}
                </Form>
                <Br opacity={0.5} style={{ marginTop: '2rem' }} />
                <GoogleLoginButton style={{ fontSize: '1.5rem', marginTop: '2rem' }} onClick={this.handleGoogleLogin} />
            </Container>
        )
    }
}

export default withTheme(LoginForm);