import * as React from 'react';
import styled from "styled-components";
import {EventStati} from "../../../models/basics/EventStati";
import {StatusColor} from "./StatusIcon";
import {BandStati} from "../../../models/basics/BandStati";


const ColourPane = styled.div`
  background: ${props => props.color};
  display: flex;
  align-items: center;
  width: 100%;
  height: 150%;
`;


export const EventStatusPane = (props: {status: EventStati | string}) => (
    <ColourPane {...props}
                color={props.status === EventStati.CONFIRMED
        ? StatusColor.GREEN :
        props.status === EventStati.CANCELLED ?
            StatusColor.RED :
            StatusColor.YELLOW}
    />
);

export const BandStatusPane = (props: {bandStatus: BandStati}) => (
    <ColourPane {...props}
                color={props.bandStatus === BandStati.COMPLETE
                    ? StatusColor.GREEN :
                    props.bandStatus === BandStati.INCOMPLETE ?
                        StatusColor.RED :
                        StatusColor.YELLOW}
    />
);