import React, {FC} from 'react';
import styled, {withTheme} from "styled-components";
import Icon, {IconName, IconSize} from "../../../../_common/_elements/Icon";
import {AppTheme} from "../../../../../theme";
import {transparentize} from 'polished';
import {Formation as FormationModel} from "../../../../../models/formation";
import FormationForm from "./FormationForm";
import {Band} from "../../../../../models/band";
import {Dialog} from "@material-ui/core";
import Toggle from "../../../../_common/utilities/Toggle";
import {DeleteForm} from "../../../../_common/utilities/DeleteForm";
import SubTitleHeader from "../../../../pages/_elements/SubTitleHeader";

interface Props {
    theme: AppTheme;
    formation: FormationModel;
    band: Band;
    handleDelete: (f: FormationModel) => void
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 1rem;
  padding: 1.5rem;
  border-bottom: 1px solid ${props => transparentize(0.3, props.theme.colors.lightGrey)};
  color: ${props => props.theme.colors.white};
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  transition: all .25s;
  &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
        background: none;
  }
`;

const DeleteFormation = styled(SubTitleHeader)`
`;

const Formation: FC<Props> = ({band, formation, theme: {colors: {secondary}}, handleDelete}) => {


    return (
        <>
            <Container>
                <p>{formation.name}</p>
                <Toggle>
                    {({toggle, on}) => (
                        <>
                            <StyledIcon onClick={toggle} name={IconName.EDIT} color={secondary} size={IconSize.MEDIUM}/>
                            <Dialog open={on} onClose={toggle}>
                                <FormationForm band={band} newFormation={false} formation={formation} toggle={toggle}/>
                            </Dialog>
                        </>
                    )}
                </Toggle>

                <DeleteFormation>
                    <Toggle>
                        {({toggle, on}) => (
                            <>
                                <Icon onClick={toggle} name={IconName.DELETE} size={IconSize.MEDIUM}/>
                                {on && <DeleteForm open={on}
                                                   handleClose={toggle}
                                                   modelName={'Formation'}
                                                   handleDelete={() => handleDelete(formation)}/>
                                }
                            </>
                        )}
                    </Toggle>
                </DeleteFormation>
            </Container>
        </>
    )
};

export default withTheme(Formation);