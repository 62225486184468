import * as React from 'react';
import styled from 'styled-components';
import SignupForm from './SignupForm';
import { AuthContainer } from '../_elements/AuthContainer';
import TopNav from '../../_common/_elements/TopNav';
import { navigate, RouteComponentProps } from '@reach/router';
import { Routes } from 'src/constants/Routes';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { autorun } from 'mobx';

interface SignupPageProps {
  path: string;
  idBand?: string;
  email?: string;
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  grid-gap: 2rem;
`;

const SignupFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  justify-self: center;
  align-self: center;
  max-width: 100rem;
  height: 100%;
`;

const SignupPage: FC<SignupPageProps & RouteComponentProps<{ id: string }>> = observer(props => {

  return (
    <AuthContainer>
      <Wrapper>
        <TopNav
          testId="signup_backbutton"
          clickHandler={() => props.navigate!(Routes.HOME)}
          text="Signup"
        />
        <SignupFormWrapper>
          <SignupForm idBand={props.idBand} email={props.email}/>
        </SignupFormWrapper>
      </Wrapper>
    </AuthContainer>
  );
});

export default SignupPage;
