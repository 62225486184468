import { Model } from "./model";

export class Mail implements Model {
    id?: string;
    to: string;
    message: {
        "subject": string,
        "text": string,
        "html": string
    }


    constructor(to: string, message: {
        "subject": string,
        "text": string,
        "html": string
    }) {
        this.to = to;
        this.message = message;
    }
}

