import {Controller} from "./controller";
import {Collections} from "../models/basics/Collections";
import {Model} from "../models/model";
import {auth, database as db} from "../database/fbApp";
import {deepCopy} from "../utils/deepCopy";
import {User} from "../models/user";
import {UserArray} from "../models/basics/documentArray";
import {GighubEvent} from "../models/gighubEvent";
import {CalendarEvent} from "../models/calendarEvent";
import {EventStati} from "../models/basics/EventStati";
import {AvailabilityStati} from "../models/basics/AvailabilityStati";
import {Band} from "../models/band";
import {addDoc, collection as col, getDocs} from "firebase/firestore";
import {CalendarEventController} from "./calendarEventController";
import {SecretTokenController} from "./secretTokenController";
import { doc, setDoc } from "firebase/firestore";

export class UserController extends Controller {
    constructor() {
        super(Collections.USERS);
    }

    async createWithId(dbId: string, data: Model) {
        const {collection, id, ...model} = data;
        return await setDoc(doc(db , this.collection, dbId), deepCopy(model));
    }

    async handleFcmToken(token: string) {
        try {
            const user = await this.get<User>(auth.currentUser ? auth.currentUser.uid : "");
            if (user && user.id) {
                if (!user.fcmTokens) user.fcmTokens = []
                if (!user.fcmTokens.find(i => i === token)) {
                    console.log("fcmToken created");
                    user.fcmTokens.push(token);
                }
                await this.update(user.id, user);
            } else {
                console.log("fcmToken: user not found");
            }
        } catch (e) {
            console.log(e)
        }
    }

    async getAll() {
        const snapshot = await getDocs(this.subscribe())
        return snapshot.docs.map(change => {
            const user = change.data() as User
            user.id = change.id
            return user
        }) as UserArray;
    }

    createCalendarEventFromEvent(model: GighubEvent, band: Band) {
        return new CalendarEvent({
            idEvent: model.id || "",
            idBand: model.idBand,
            title: model.title,
            location: model.address.country ? (model.address.street
                + ' ' + model.address.streetnr + ', ' + model.address.zip + ' ' + model.address.city) : ''
            , dateStart: model.dateStart, dateEnd: model.dateEnd, status: model.status || EventStati.PENDING
            , bandShortHand: band.shorthand
        })
    }

    async handleAvailabilitySubmit(availabilityStatus: AvailabilityStati, idUser: string, idEvent: string, band: Band, event?: GighubEvent, newAvailability?: boolean) {
        if (event && (availabilityStatus === AvailabilityStati.ACCEPTED || availabilityStatus === AvailabilityStati.PENDING)) {
            const {
                id: emptyId,
                collection: emptyCollection,
                ...calendarEvent
            } = this.createCalendarEventFromEvent(event, band)
            if (newAvailability) {
                await new CalendarEventController(idUser).create(calendarEvent)
            } else {
                const querySnapshot = await new CalendarEventController(idUser).getWithWhere("idEvent" as any, idEvent)
                await Promise.all(
                    querySnapshot.docs.map(async (d) => {
                        await new CalendarEventController(idUser).update(d.id, calendarEvent)
                    })
                )
            }
        } else if (availabilityStatus === AvailabilityStati.DECLINED && !newAvailability) {
            const querySnapshot = await new CalendarEventController(idUser).getWithWhere("idEvent" as any, idEvent)
            await Promise.all(
                querySnapshot.docs.map(async (d) => {
                    await new CalendarEventController(idUser).delete(d.id);
                })
            )
        }
    }

    async updateCalendarEvent(idUser: string, event: GighubEvent, band: Band) {
        const {
            id: emptyId,
            collection: emptyCollection,
            ...calendarEvent
        } = this.createCalendarEventFromEvent(event, band)

        const querySnapshot = await new CalendarEventController(idUser).getWithWhere("idEvent" as any, event.id || "")
        await Promise.all(
            querySnapshot.docs.map(async (d) => {
                await new CalendarEventController(idUser).update(d.id, calendarEvent)
                console.log("updated CalendarEvent" + idUser)
            })
        )
    }

    async deleteCalendarEvent(idUser: string, idEvent: string) {
        const querySnapshot = await new CalendarEventController(idUser).getWithWhere("idEvent" as any, idEvent)
        await Promise.all(
            querySnapshot.docs.map(async (d) => {
                await new CalendarEventController(idUser).delete(d.id);
            })
        )
    }

    async createSecretToken(idUser: string) {
        const model = {idUser}
        try {
            return addDoc(col(db, Collections.SECRET_TOKENS), deepCopy(model));
        } catch (e) {
            console.log(e)
            return "";
        }
    }

    async getSecretToken(idUser: string) {
        let idToken = ""
        const querySnapshot = await new SecretTokenController().getWithWhere("idUser" as any, idUser)
        await Promise.all(
            querySnapshot.docs.map(async (d) => {
                idToken = await d.id
            })
        )
        return idToken
    }

}