import React, { FC, useContext, useEffect, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import UpcomingEvents from './UpcomingEvents/UpcomingEvents';
import SectionTitle from '../_elements/SectionTitle';
import Section from '../_elements/Section';
import Page from '../../_common/page/Page';
import YourBands from './YourBands/YourBands';
import { Link, RouteComponentProps } from '@reach/router';
import useIsDesktop from 'src/hooks/useIsDesktop';
import {
  Dialog,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  TextField
} from '@material-ui/core';
import { RootStoreContext } from 'src/store/RootStore';
import { Band } from 'src/models/band';
import { GighubEvent } from 'src/models/gighubEvent';
import {
  compareAsc,
  compareDesc,
  formatDistanceToNow,
  isFuture
} from 'date-fns';
import { Links } from 'src/constants/Routes';
import { AvatarImage, Icon } from 'src/views/_common/_elements';
import Highlighter from 'react-highlight-words';
import { ListManager } from 'react-beautiful-dnd-grid';
import { IconName, IconSize } from 'src/views/_common/_elements/Icon';
import { deepCopy } from 'src/utils/deepCopy';
import { AppTheme } from 'src/theme';
import { Formation } from 'src/models/formation';
import { Customer } from 'src/models/customer';
import {
  canWriteBand,
  isParticipating
} from '../../../models/basics/MemberRoles';
import AvailabilityColor from '../../_common/_elements/AvailabilityColor';
import { EventStati } from '../../../models/basics/EventStati';
import { BandStati } from '../../../models/basics/BandStati';
import { truncate } from '../../../utils/stringUtils';
import { AvailabilityStati } from 'src/models/basics/AvailabilityStati';

interface Props {
  path: string;
  theme: AppTheme;
}

const localStorageKey = 'gighub-hub-cards';

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: static;
  height: 100%;
  justify-content: space-between;
  overflow-y: hidden;
`;

const TopBar = styled.div`
  display: grid;
  grid-template-columns: 9fr 1fr;
  > svg {
    grid-row: 1;
    grid-column: 2;
    justify-self: end;
  }
`;

const SearchBar = styled(TextField)`
  width: 100%;
  grid-column: 1 / 3;
`;

const SwitchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Card = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: 10px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.colors.black};
  padding: 0.5rem 0.8rem;
  max-height: 27rem;
  overflow-y: auto;
  li {
    cursor: pointer;
  }
`;

const Grid = styled.div`
  margin-top: 2rem;
  && {
    > div {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2.5rem;
      margin-bottom: 2.5rem;
    }
  }
`;

const MyListItemAvatar = styled(ListItemAvatar)`
  padding-right: 10px;
`;

const HubPage: FC<Props & RouteComponentProps> = ({ navigate, theme }) => {
  const {
    bandsStore: { observables: bands, getById: getBandById, setSelectedBand, setSelectedBandFromLocalStorage, selectedBand },
    eventsStore: { observables: events },
    formationsStore: { observables: formations },
    customersStore: { observables: customers },
    userStore: { currentUser }
  } = useContext(RootStoreContext);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const initialCards = [
    {
      id: 0,
      order: 0,
      isVisible: true
    },
    {
      id: 1,
      order: 1,
      isVisible: true
    },
    {
      id: 2,
      order: 2,
      isVisible: true
    },
    {
      id: 3,
      order: 3,
      isVisible: true
    },
    {
      id: 4,
      order: 4,
      isVisible: true
    },
    {
      id: 5,
      order: 5,
      isVisible: true
    },
    {
      id: 6,
      order: 6,
      isVisible: true
    },
    {
      id: 7,
      order: 7,
      isVisible: true
    }
  ];
  const [sortedCards, setSortedCards] = useState<
    Array<{ id: number; order: number; isVisible: boolean }>
  >(initialCards);
  const isDesktop = useIsDesktop();
  const [query, setQuery] = useState('');
  const [showPastEvents, setShowPastEvents] = useState(false);

  useEffect(() => {
    if (!selectedBand) {
      setSelectedBandFromLocalStorage();
    }
    const cardString = localStorage.getItem(localStorageKey);
    if (cardString) {
      const newCards: any[] = JSON.parse(cardString);
      // forces to update the local storage in case the amount of item changes with an update
      if (newCards.length !== initialCards.length) {
        setSortedCards(initialCards);
      } else {
        setSortedCards(newCards);
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(sortedCards));
  }, [sortedCards]);

  useEffect(() => {
    const pathname = window.location.href;
    localStorage.setItem('pageUrl', JSON.stringify(pathname))
  }, []);

  const filterBand = (band: Band) => {
    const searchQuery = query.toLowerCase();
    return (
      band.name.toLowerCase().includes(searchQuery) ||
      band.shorthand.toLowerCase().includes(searchQuery)
    );
  };

  const filterFormations = (formation: Formation) => {
    const searchQuery = query.toLowerCase();
    return formation.name.toLowerCase().includes(searchQuery);
  };

  const filterCustomers = (customer: Customer) => {
    const searchQuery = query.toLowerCase();
    return (
      customer.name && customer.name.toLowerCase().includes(searchQuery) ||
      customer.surname && customer.surname.toLowerCase().includes(searchQuery) ||
      (customer.email && customer.email.toLowerCase().includes(searchQuery))
    );
  };

  const filterEvents = (event: GighubEvent) => {
    const searchQuery = query.toLowerCase();
    return (
      event.title.toLowerCase().includes(searchQuery) ||
      getBandById(event.idBand)
        .get()!
        .name.toLowerCase()
        .includes(searchQuery) ||
      getBandById(event.idBand)
        .get()!
        .shorthand.toLowerCase()
        .includes(searchQuery) ||
      (event.locationName &&
        event.locationName.toLowerCase().includes(searchQuery))
    );
  };

  const sortList = () => {
    const visibleItems = deepCopy(sortedCards)
      .filter(e => e.isVisible)
      .sort((first, second) => first.order - second.order);
    const invisibleItems = deepCopy(sortedCards).filter(e => !e.isVisible);
    // @ts-ignore
    setSortedCards([...visibleItems, ...invisibleItems]);
  };

  const reorderList = (sourceIndex: number, destinationIndex: number) => {
    if (destinationIndex === sourceIndex) {
      return;
    }
    const list = sortedCards;
    if (destinationIndex === 0) {
      list[sourceIndex].order = list[0].order - 1;
      sortList();
      return;
    }
    if (destinationIndex === list.length - 1) {
      list[sourceIndex].order = list[list.length - 1].order + 1;
      sortList();
      return;
    }
    if (destinationIndex < sourceIndex) {
      list[sourceIndex].order =
        (list[destinationIndex].order + list[destinationIndex - 1].order) / 2;
      sortList();
      return;
    }
    list[sourceIndex].order =
      (list[destinationIndex].order + list[destinationIndex + 1].order) / 2;
    sortList();
  };

  const renderBandAvatar = (idBand: string, status?: EventStati | string) => (
    <Link
      onClick={ev => {
        ev.stopPropagation();
        setSelectedBand(idBand);
      }}
      to={Links.EVENTS}
    >
      {status ? (
        <AvailabilityColor status={status}>
          <AvatarImage
            size="small"
            fallback={getBandById(idBand).get()!.shorthand}
            imgUrl={getBandById(idBand).get()!.imageUrl}
          />
        </AvailabilityColor>
      ) : (
        <AvatarImage
          size="small"
          fallback={getBandById(idBand).get()!.shorthand}
          imgUrl={getBandById(idBand).get()!.imageUrl}
        />
      )}
    </Link>
  );

  const returnListItem = (e: GighubEvent, invitation?: boolean) => {
    return (
      <>
        <ListItem
          onClick={() => {
            setSelectedBand(e.idBand);
            if (navigate) {
              navigate(`${Links.EVENT}${e.id}`)
            }
          }}
          divider
          key={e.id}
        >
          <MyListItemAvatar>
            {renderBandAvatar(e.idBand, e.status)}
          </MyListItemAvatar>
          <ListItemText
            primary={
              <Highlighter
                highlightClassName="highlighted"
                searchWords={[query]}
                autoEscape={true}
                textToHighlight={truncate(e.title, 40)}
              />
            }
            secondary={
              <Highlighter
                highlightClassName="highlighted"
                searchWords={[query]}
                autoEscape={true}
                textToHighlight={
                  (e.locationName && e.locationName.length > 0
                    ? e.locationName + ' - '
                    : '') + e.dateStart.toLocaleDateString()
                }
              />
            }
          />
        </ListItem>
      </>
    );
  };


  const renderEvents = () =>
    events
      .slice()
      .filter(e => {
        const band = getBandById(e.idBand).get();
        if (band && canWriteBand(band)) return true;
        else if (band && !canWriteBand(band) && isParticipating(e)) return true;
        else return false;
      })
      .filter(filterEvents)
      .filter(e => (showPastEvents ? true : isFuture(e.dateEnd)))
      .sort((a: GighubEvent, b: GighubEvent) =>
        compareAsc(a.dateStart, b.dateStart)
      )
      .map(e => returnListItem(e));

  const renderLastEditedEvents = () =>
    events
      .slice()
      .filter(e => {
        const band = getBandById(e.idBand).get();
        if (band && canWriteBand(band)) return true;
        else if (band && !canWriteBand(band) && isParticipating(e)) return true;
        else return false;
      })
      .filter(filterEvents)
      .sort((a: GighubEvent, b: GighubEvent) =>
        compareDesc(a.dateEdited || new Date(), b.dateEdited || new Date())
      )
      .map(e => returnListItem(e));

  const renderUpcomingEvents = () =>
    currentUser &&
    currentUser.id &&
    events
      .slice()
      .filter(e => isParticipating(e))
      .filter(filterEvents)
      .filter(e => isFuture(e.dateEnd))
      .sort((a: GighubEvent, b: GighubEvent) =>
        compareAsc(a.dateStart, b.dateStart)
      )
      .map(e => returnListItem(e));

  const renderBandStatusIsIncompleteEvents = () =>
    currentUser &&
    currentUser.id &&
    events
      .slice()
      .filter(filterEvents)
      .filter(e => isFuture(e.dateEnd))
      .filter(e => e.bandStatus === BandStati.INCOMPLETE)
      .sort((a: GighubEvent, b: GighubEvent) =>
        compareAsc(a.dateStart, b.dateStart)
      )
      .map(e => returnListItem(e));

  const renderMyInvitations = () =>
    currentUser &&
    currentUser.id &&
    events
      .slice()
      .filter(filterEvents)
      .filter(e => isFuture(e.dateEnd))
      .filter(e => e.availabilities && Object.entries(e.availabilities).some(([key, value]) => key === currentUser.id && (value.status === AvailabilityStati.INVITED || value.status === AvailabilityStati.PENDING)))
      .sort((a: GighubEvent, b: GighubEvent) =>
        compareAsc(a.dateStart, b.dateStart)
      )
      .map(e => returnListItem(e));

  const renderBands = () =>
    bands
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter(filterBand)
      .map(b => (
        <ListItem
          onClick={() => {
            setSelectedBand(b.id);
            if (navigate) {
              navigate(Links.EVENTS);
            }
          }}
          divider
          key={b.id}
        >
          <MyListItemAvatar>
            <AvatarImage
              imgUrl={b.imageUrl}
              fallback={b.shorthand}
              size="small"
            />
          </MyListItemAvatar>
          <ListItemText
            primary={
              <Highlighter
                highlightClassName="highlighted"
                searchWords={[query]}
                autoEscape={true}
                textToHighlight={b.name}
              />
            }
            secondary={
              <Highlighter
                highlightClassName="highlighted"
                searchWords={[query]}
                autoEscape={true}
                textToHighlight={b.shorthand}
              />
            }
          />
          {canWriteBand(b) && (
            <ListItemSecondaryAction>
              <Icon
                name={IconName.SETTINGS}
                onClick={() =>
                  navigate && navigate(`${Links.BAND_SETTINGS}${b.id}`)
                }
                color={theme.colors.darkGrey}
              />
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ));

  const renderFormations = () =>
    formations
      .slice()
      .filter(filterFormations)
      .map(f => (
        <ListItem
          onClick={() =>
            navigate && navigate(`${Links.BAND_SETTINGS}${f.idBand}/2`)
          }
          divider
          key={f.id}
        >
          <MyListItemAvatar>{renderBandAvatar(f.idBand!)}</MyListItemAvatar>
          <ListItemText
            primary={
              <Highlighter
                highlightClassName="highlighted"
                searchWords={[query]}
                autoEscape={true}
                textToHighlight={f.name}
              />
            }
          />
        </ListItem>
      ));

  const renderCustomers = () =>
    customers
      .slice()
      .filter(filterCustomers)
      .map(c => (
        <ListItem
          onClick={() =>
            navigate && navigate(`${Links.BAND_SETTINGS}${c.idBand}/1`)
          }
          divider
          key={c.id}
        >
          <MyListItemAvatar>{renderBandAvatar(c.idBand!)}</MyListItemAvatar>
          <ListItemText
            primary={
              <Highlighter
                highlightClassName="highlighted"
                searchWords={[query]}
                autoEscape={true}
                textToHighlight={c.name + ' ' + c.surname}
              />
            }
            secondary={
              <Highlighter
                highlightClassName="highlighted"
                searchWords={[query]}
                autoEscape={true}
                textToHighlight={c.email || ''}
              />
            }
          />
        </ListItem>
      ));

  const renderMobileView = () =>
    navigate && (
      <Page navigate={navigate}>
        <MobileWrapper>
          <div style={{ marginTop: '1.5rem', padding: '0 1rem' }}>
            <SectionTitle>Your Upcoming Events</SectionTitle>
            <Section>
              <UpcomingEvents />
            </Section>
          </div>
          <div>
            <SectionTitle>Your Bands </SectionTitle>
            <Section>
              <YourBands />
            </Section>
          </div>
        </MobileWrapper>
      </Page>
    );

  const renderDesktopView = () => {
    const cards = [
      {
        id: 0,
        name: 'Last edited events',
        component: (
          <Card>
            <List>
              <ListItemText>
                <h4 style={{ fontWeight: 'bold' }}>Last edited Events</h4>
              </ListItemText>
              {renderLastEditedEvents()}
            </List>
          </Card>
        )
      },
      {
        id: 1,
        name: 'Upcoming events',
        component: (
          <Card>
            <List>
              <ListItemText>
                <h4 style={{ fontWeight: 'bold' }}>My Upcoming Events</h4>
              </ListItemText>
              {renderUpcomingEvents()}
            </List>
          </Card>
        )
      },
      {
        id: 2,
        name: 'All Events',
        component: (
          <Card>
            <List>
              <ListItemText>
                <SwitchContainer>
                  <h4 style={{ fontWeight: 'bold' }}>All Events</h4>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showPastEvents}
                        onChange={e => setShowPastEvents(e.target.checked)}
                      />
                    }
                    label={
                      <small style={{ color: 'rgba(0,0,0,.7)' }}>
                        Show past events
                      </small>
                    }
                  />
                </SwitchContainer>
              </ListItemText>
              {renderEvents()}
            </List>
          </Card>
        )
      },
      {
        id: 3,
        name: 'Bands',
        component: (
          <Card>
            <List>
              <ListItemText>
                <h4 style={{ fontWeight: 'bold' }}>Bands</h4>
              </ListItemText>
              {renderBands()}
            </List>
          </Card>
        )
      },
      {
        id: 4,
        name: 'BandStatusIsIncomplete',
        component: (
          <Card>
            <List>
              <ListItemText>
                <h4 style={{ fontWeight: 'bold' }}>Bandstatus is incomplete</h4>
              </ListItemText>
              {renderBandStatusIsIncompleteEvents()}
            </List>
          </Card>
        )
      },
      {
        id: 5,
        name: 'Formations',
        component: (
          <Card>
            <List>
              <ListItemText>
                <h4 style={{ fontWeight: 'bold' }}>Formations</h4>
              </ListItemText>
              {renderFormations()}
            </List>
          </Card>
        )
      },
      {
        id: 6,
        name: 'Customers',
        component: (
          <Card>
            <List>
              <ListItemText>
                <h4 style={{ fontWeight: 'bold' }}>Customers</h4>
              </ListItemText>
              {renderCustomers()}
            </List>
          </Card>
        )
      }
      ,
      {
        id: 7,
        name: 'My Invitations',
        component: (
          <Card>
            <List>
              <ListItemText>
                <h4 style={{ fontWeight: 'bold', color: 'rgb(239 96 71)' }}>Open Invitations</h4>
              </ListItemText>
              {renderMyInvitations()}
            </List>
          </Card>
        )
      }
    ];

    return (
      navigate && (
        <Page navigate={navigate}>
          <TopBar>
            <SearchBar
              placeholder="Search for anything.."
              autoFocus
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
            <Icon
              color={theme.colors.darkGrey}
              name={IconName.SETTINGS}
              size={IconSize.LARGE}
              onClick={() => setIsSettingsModalOpen(true)}
            />
          </TopBar>
          <Grid>
            <ListManager
              items={sortedCards.filter(e => e.isVisible)}
              direction="horizontal"
              maxItems={2}
              onDragEnd={reorderList}
              render={item => cards[item.id].component}
            />
          </Grid>
          <Dialog
            open={isSettingsModalOpen}
            onClose={() => setIsSettingsModalOpen(false)}
          >
            <DialogTitle>Change visible items</DialogTitle>
            <List>
              {sortedCards.map(({ id, isVisible }) => (
                <ListItem divider key={id}>
                  <ListItemText primary={cards[id].name} />
                  <ListItemSecondaryAction>
                    <div>
                      <Switch
                        checked={isVisible}
                        onChange={e => {
                          const newSortedCards: typeof sortedCards = JSON.parse(
                            JSON.stringify(sortedCards)
                          );
                          newSortedCards.find(
                            element => element.id === id
                          )!.isVisible = e.target.checked;
                          setSortedCards(newSortedCards);
                        }}
                      />
                    </div>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Dialog>
        </Page>
      )
    );
  };
  return <>{isDesktop ? renderDesktopView() : renderMobileView()}</>;
};

export default withTheme(HubPage);
