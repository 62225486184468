import {action, computed, observable, toJS} from 'mobx';
import {RootStore} from './RootStore';
import {Model} from "../models/model";

export class Store<T extends Model> {
    @observable observables: T[];
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.observables = [];
    }

    @computed get count() {
        return this.observables.length;
    }

    @action
    getById = (id: string) => {
        return computed(() => this.observables.find(n => n.id === id));
    }

    @action
    getByBand = (idBand: string) => {
        return computed(() => this.observables.filter(f => f['idBand'] === idBand));
    }

    @action
    update = (model: T) => {
        if (model.id) {
            const objIndex = this.observables.findIndex(t => t.id === model.id);
            if (objIndex > -1) {
                this.observables[objIndex] = model;
            } else {
                this.observables.push(model);
            }
        }
        return this.observables;
    }

    @action
    remove = (id: string) => {
        const index = this.observables.findIndex(i => i.id === id);
        if (index !== -1){
            this.observables.splice(index, 1)
        }
        return this.observables;
    }
}


