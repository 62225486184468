import * as React from 'react';
import styled from "styled-components";

interface GeneralNoteProps {
    title: string;
    text: string;
}

const Container = styled.div`
  background: #FFE4B1;
  padding: 1rem;
  margin-bottom: 2rem;
`;

const Text = styled.p`
  padding-top: .6rem;
  strong{font-weight: bold}
`;

const GeneralNote: React.FC<GeneralNoteProps> = props => (
    <Container>
        <Text dangerouslySetInnerHTML={{__html: props.text}}/>
    </Container>
);


export default GeneralNote;