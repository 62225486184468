import * as React from 'react';
import styled from "styled-components";
import Title from "../../../../pages/_elements/Title";
import SubTitleHeader from "../../../../pages/_elements/SubTitleHeader";
import Icon, {IconName, IconSize} from "../../../../_common/_elements/Icon";
import AvatarImageSmall from "../../../../_common/_elements/AvatarImage";
import Toggle from "../../../../_common/utilities/Toggle";
import MemberForm from "./MemberForm";
import {MemberRoles} from "../../../../../models/basics/MemberRoles";
import {truncate, fullnameToInitials} from "../../../../../utils/stringUtils";
import Confirm from "../../../../_common/_elements/Confirm";
import {Member} from "../../../../../models/basics/member";
import {Dialog} from "@material-ui/core";
import {DeleteForm} from "../../../../_common/utilities/DeleteForm";


interface MemberWrapperProps {
    idUser: string;
    member: Member;
    handleSubmit: (role: MemberRoles, email?: string, idMember?: string) => any;
    handleDelete: (idUser: string) => any;
    authRole: MemberRoles;
    isTheOnlyAdmin?: boolean;
    invite?: boolean;
}

const MemberContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto auto;
  align-items: center;
  grid-gap: 1rem;
`;

const MemberName = styled(Title)`
  font-weight: bold;
  color: white;
`;

const MemberRole = styled(SubTitleHeader)`
  color: lightgray;
`;

const EditMember = styled(SubTitleHeader)`
`;

const DeleteMember = styled(SubTitleHeader)`
`;


class MemberWrapper extends React.Component<MemberWrapperProps, {}> {

    render() {
        const {authRole, invite, handleDelete, idUser, member, handleSubmit} = this.props;
        return (
            <MemberContainer>
                <AvatarImageSmall imgUrl={member.imageUrl} fallback={fullnameToInitials(member.fullName)} size="small"/>
                <MemberName>{member.fullName ? truncate(member.fullName, 18) : ""}</MemberName>
                <MemberRole>{member.role}</MemberRole>
                {!this.props.isTheOnlyAdmin && (authRole === MemberRoles.ADMIN || (authRole === MemberRoles.EDITOR
                    && member.role !== MemberRoles.EDITOR && member.role !== MemberRoles.ADMIN)) &&
                <>
                    {!invite &&
                    <EditMember>
                        <Toggle>
                            {({toggle, on}) => (
                                <>
                                    <Icon onClick={toggle} name={IconName.EDIT} size={IconSize.SMALL}/>
                                    {on && <Dialog open={on} onClose={toggle}>
                                        <MemberForm
                                            idUser={idUser}
                                            member={member}
                                            handleSubmit={handleSubmit}
                                            toggle={toggle}
                                            authRole={authRole}
                                        />
                                    </Dialog>
                                    }
                                </>
                            )}
                        </Toggle>
                    </EditMember>
                    }

                    <DeleteMember>
                        <Toggle>
                            {({toggle, on}) => (
                                <>
                                    <Icon onClick={toggle} name={IconName.DELETE} size={IconSize.SMALL}/>
                                    {on && <DeleteForm open={on}
                                                       handleClose={toggle}
                                                       modelName={'Member'}
                                                       handleDelete={() => handleDelete(idUser ? idUser : member.fullName)}/>
                                    }
                                </>
                            )}
                        </Toggle>
                    </DeleteMember>
                </>}
            </MemberContainer>
        )
    }
}


export default MemberWrapper;