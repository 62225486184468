import * as React from 'react';
import { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon, { IconName } from '../../_common/_elements/Icon';
import StatusIcon from '../../_common/_elements/StatusIcon';
import { GighubEvent } from '../../../models/gighubEvent';
import Tab from './Tab';
import TimeTable from './tabs/timetable/TimeTable';
import { Links, Routes } from '../../../constants/Routes';
import { Link, navigate } from '@reach/router';
import InformationPage from './tabs/InformationPage';
import { Band } from '../../../models/band';
import { Customer } from '../../../models/customer';
import { Formation } from '../../../models/formation';
import { auth } from '../../../database/fbApp';
import { canWrite, MemberRoles } from '../../../models/basics/MemberRoles';
import { RootStoreContext } from 'src/store/RootStore';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import MapView from './tabs/availabilities/MapView';
import Guestlist from './tabs/Guestlist';
import Fab from '../../_common/_elements/Fab';
import Page from '../../_common/page/Page';
import { truncate } from '../../../utils/stringUtils';
import {
  AvatarImage,
  LabelButton,
  PrimaryTextButton
} from '../../_common/_elements';
import AvailabilityColor from '../../_common/_elements/AvailabilityColor';
import useIsDesktop from 'src/hooks/useIsDesktop';
import { PrimaryButton } from '../../_common/_elements/Button';
import { theme } from 'src/theme';
import { BillingInfos } from "../../../models/billingInfos";
import { SignalCellularNull } from '@material-ui/icons';
import Availabilities from './tabs/availabilities/Availabilites';

interface Props {
  path: string;
  id?: string;
}

const Container = styled.div`
  width: 100%;
  // background: ${props => props.theme.colors.white};
  border-radius: 10px;
  // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin: 0 auto;
  position: relative;
`;

const TopBar = styled.div`
  height: ${props => props.theme.layout.headerHeight};
  display: flex;
  align-items: center;
  padding: 0 1rem;
  justify-content: space-between;
`;

const BackIcon = styled(Icon)`
  cursor: pointer;
`;

const EventTitle = styled.h3`
  color: ${props => props.theme.colors.primary};
`;

const Content = styled.div``;

const TabNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Status = styled(StatusIcon)`
  grid-row: 2;
  display: flex;
  align-items: center;
  padding-left: 4px;
`;

const DetailEventPage: FC<Props> = observer(({ id }) => {
  const {
    bandsStore: { getById: getBandById },
    eventsStore: { getById: getEventById },
    formationsStore: { getById: getFormationById },
    customersStore: { getById: getCustomerById },
    billingInfosStore: { getByBand },
    userStore: { currentUser }
  } = useContext(RootStoreContext);
  const [band, setBand] = useState<Band | null>(null);
  const [event, setEvent] = useState<GighubEvent | null>(null);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [formation, setFormation] = useState<Formation | null>(null);
  const [billingInfos, setBillingInfos] = useState<BillingInfos | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [authRole, setAuthRole] = useState(MemberRoles.NOT_AUTHORIZED);
  const isDesktop = useIsDesktop();


  useEffect(() => {
    autorun(() => {
      if (id) {
        const urlEvent = getEventById(id).get();
        if (urlEvent) {
          setEvent(urlEvent);
          const urlBand = getBandById(urlEvent.idBand).get();
          if (urlBand) {
            setBand(urlBand);
          }
          const urlCustomer = getCustomerById(urlEvent.idCustomer).get();
          if (urlCustomer) {
            setCustomer(urlCustomer);
          }
          if (urlEvent.idFormation) {
            const urlFormation = getFormationById(urlEvent.idFormation).get();
            if (urlFormation) {
              setFormation(urlFormation);
            }
          }
        }
      }
      if (event && event.idBand) {
        const urlBillingInfos = getByBand(event.idBand).get();
        if (urlBillingInfos && urlBillingInfos[0]) {
          setBillingInfos(urlBillingInfos[0]);
        }
      }
    })
  }
  );

  useEffect(() => {
    if (event && band && band.members) {
      setAuthRole(
        band.members[auth.currentUser ? auth.currentUser.uid : ''].role
      );
    }
    if (event && (!event.timeTable || Object.keys(event.timeTable).length === 0)) {
      setActiveTab(1)
    }
  }, [band, event]);

  function renderContent() {
    if (band && event) {
      switch (activeTab) {
        case 0:
          return event ? <TimeTable event={event} authRole={authRole} /> : null;
        case 1:
          return (
            <InformationPage
              formation={formation}
              event={event}
              band={band}
              customer={customer || null}
              billingInfos={billingInfos}
            />
          );
        case 2:
          return <>{<Guestlist customer={customer || null} guestlist={event.guestlist || []} event={event} />}</>;
        case 3:
          return (
            <>
              {currentUser && (
                <MapView band={band} event={event} user={currentUser} />
              )}
            </>
          );

      }
    }
    return null;
  }

  function renderTabs() {
    return (
      <TabNavigation>
        {(Object.keys(event!.timeTable!).length > 0 || canWrite(authRole)) && (
          <Tab onClick={() => setActiveTab(0)} active={activeTab === 0}>
            Timetable
          </Tab>
        )}
        <Tab onClick={() => setActiveTab(1)} active={activeTab === 1}>
          Info
        </Tab>
        {/* <Tab onClick={() => setActiveTab(3)} active={activeTab === 3}>
            Contact
          </Tab> */}
        <Tab onClick={() => setActiveTab(2)} active={activeTab === 2}>
          Guestlist
        </Tab>
        { band && band.showDrivingExpenses &&
          <Tab onClick={() => setActiveTab(3)} active={activeTab === 3}>
            Driving Expenses
          </Tab>
        }
      </TabNavigation>
    );
  }

  return (
    <>
      {navigate && (
        <Page navigate={navigate}>
          <Container>
            {event && event.id && (
              <>
                <TopBar>
                  <BackIcon
                    onClick={() => {
                      const previousPage = localStorage.getItem('pageUrl')
                      if (previousPage && previousPage.includes("event_form")) {
                        if (navigate) {
                          navigate(`${Links.EVENTS}`)
                        }
                      } else {
                        window.history.back()
                      }
                    }}
                    name={IconName.ARROW_LEFT}
                  />
                  <EventTitle>
                    {band && band.shorthand}: {truncate(event.title, 25)}
                  </EventTitle>
                  <AvailabilityColor status={event.status!}>
                    <AvatarImage
                      size="small"
                      fallback={getBandById(event.idBand).get()!.shorthand}
                      imgUrl={getBandById(event.idBand).get()!.imageUrl}
                    />
                  </AvailabilityColor>
                </TopBar>
                <Content>
                  {renderTabs()}
                  {band && <Availabilities event={event} band={band} />}
                  {renderContent()}
                </Content>
              </>
            )}
            {canWrite(authRole) && event && event.id && (
              <Link to={Links.EVENT_FORM_PAGE + event.idBand + '/' + event.id}>
                <Fab type={'EDIT'} footerMargin={true} />
              </Link>
            )}
          </Container>
        </Page>
      )}
    </>
  );
});

export default DetailEventPage;
