import { InjectedFormikProps, withFormik } from 'formik';
import React, { FC } from 'react';
import * as Yup from 'yup';
import styled from "styled-components";
import { AvatarImage, Icon, BlueButton } from "../../../_common/_elements/index";
import { toast } from 'react-toastify';
import { BandController } from 'src/controllers/bandController';
import { Band } from 'src/models/band';
import { IconName, IconSize } from 'src/views/_common/_elements/Icon';
import { Switch, TextField } from "@material-ui/core";
import TextFieldLight from 'src/views/_common/_elements/TextFieldLight';
import { TwitterPicker } from 'react-color';
import Label from "../../../_common/_elements/Label";
import useCloudinaryImage from 'src/hooks/useCloudinary';

interface FormValues {
    band: Band | null;
    name: string;
    shorthand: string;
    primaryColor: string;
    currency: string;
    costPerKilometer: number;
    showDrivingExpenses: boolean;
}

interface FormProps {
    band: Band | null;
    handleSubmit: (name: string, shorthand: string, primaryColor: string, currency: string, costPerKilometer: number, showDrivingExpenses: boolean) => any;
}

const Form = styled.form`
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: ${props => props.theme.layout.settingsContainerPadding};
  > .btn {
    margin-top: 4rem;
  }
`;

const ProfileImg = styled(AvatarImage)`
margin-bottom: 2rem;
  &::before {
    width: 100%;
    height: 100%;
    background: ${props => props.theme.colors.darkGrey};
    opacity: 0.3;
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
  }
`;

const UploadButton = styled(Icon)`
  position: absolute;
  top: 40%;
  left: 50%;
  opacity: 0.9;
  transform: translate3d(-50%, -50%, 0);
`;

const InnerForm: FC<InjectedFormikProps<FormProps, FormValues>> = ({ handleSubmit, isSubmitting, handleChange, values, errors, touched, band }) => {
    const imageUrl = useCloudinaryImage(band && band.imageUrl, handleImageUploadSuccess, handleImageUploadError);
    function handleImageUploadSuccess(newImageUrl: string) {
        new BandController().update(band!.id, {
            ...band!,
            imageUrl: newImageUrl
        });
        toast.success("Successfully changed Image");
    }

    function handleImageUploadError(error: any) {
        toast.success("There was an error uploading your image, please try again");
    }

    return (
        <Form onSubmit={handleSubmit}>
            <div style={{ position: 'relative', display: band ? 'inline-block' : 'none' }}>
                <ProfileImg fallback={band && band.shorthand || ''} size="medium" imgUrl={imageUrl} />
                <UploadButton name={IconName.UPLOAD} size={IconSize.XL} />
                <div id="upload_widget_opener" />
            </div>

            <TextFieldLight
                id="name"
                name="name"
                fullWidth={true}
                label={errors.name && touched.name ? errors.name : 'Name'}
                error={Boolean(touched.name && errors.name)}
                type="text"
                onChange={handleChange}
                value={values.name}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextFieldLight
                id="shorthand"
                name="shorthand"
                fullWidth={true}
                label={errors.shorthand && touched.shorthand ? errors.shorthand : "Shorthand (max. 4 letter version for calendar etc.)"}
                error={Boolean(touched.shorthand && errors.shorthand)}
                type="text"
                onChange={handleChange}
                value={values.shorthand}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <br /><br />
            <Label>Show Driving Expenses Tab</Label>
            <Switch
                id="showDrivingExpenses"
                name="showDrivingExpenses"
                checked={values.showDrivingExpenses}
                onChange={handleChange}
                value="showDrivingExpenses"
                color="secondary"
            />
            <TextFieldLight
                id="currency"
                name="currency"
                fullWidth={true}
                label={errors.currency && touched.currency ? errors.currency : "Currency (max. 4 letter)"}
                error={Boolean(touched.currency && errors.currency)}
                type="text"
                onChange={handleChange}
                value={values.currency}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextFieldLight
                id="costPerKilometer"
                name="costPerKilometer"
                type="decimal"
                fullWidth={true}
                label={errors.costPerKilometer && touched.costPerKilometer ? errors.costPerKilometer : "Cost Per Kilometer (Driving Expenses)"}
                error={Boolean(touched.costPerKilometer && errors.costPerKilometer)}
                onChange={handleChange}
                value={values.costPerKilometer}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <br /><br />
            <Label>Custom Color (used on the Calendar page)</Label>
            <br />
            <TwitterPicker
                color={values.primaryColor}
                onChangeComplete={(color) => {
                    values.primaryColor = color.hex
                }}
            />
            <BlueButton className='btn' type="submit" disabled={isSubmitting}>{band ? 'Submit' : 'Create Band'}</BlueButton>
        </Form>
    )
}

const BandSettingsForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: ({ band }) => ({
        band,
        name: band && band.name || "",
        shorthand: band && band.shorthand || "",
        primaryColor: band && band.primaryColor || "black",
        currency: band && band.currency || "CHF",
        costPerKilometer: band && band.costPerKilometer || 0.5,
        showDrivingExpenses: band && band.showDrivingExpenses || false
    }),
    validationSchema: Yup.object().shape({
        name: Yup.string().max(50, "Field shouldn't be longer than 50 characters").required("This field is required"),
        shorthand: Yup.string().max(4, "Field shouldn't be longer than 4 characters").required("This field is required"),
        currency: Yup.string().max(4, "Field shouldn't be longer than 4 characters").required("This field is required"),
        costPerKilometer: Yup.number().required("This field is required"),
    },
    ),
    handleSubmit: async (values, { props, setSubmitting, setFieldError }) => {
        setSubmitting(true)
        await props.handleSubmit(values.name, values.shorthand, values.primaryColor, values.currency, values.costPerKilometer, values.showDrivingExpenses);
        setSubmitting(false);
    },
})(InnerForm);

export default BandSettingsForm;