import React, { FC, useContext } from 'react';
import { Band } from 'src/models/band';
import styled from 'styled-components';
import { BlueButton, DangerButton } from './Button';
import PaymentButton from 'src/views/settings/userSettings/PaymentButton';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from 'src/store/RootStore';
import { MAX_MEMBERS, MAX_YEARLY_EVENTS, MAX_FORMATIONS, PREMIUM_PRICE } from 'src/constants/FreePlan';
import { database } from 'src/database/fbApp';
import { useToggle } from 'src/hooks/useToggle';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button } from '@material-ui/core';

interface Props {
  band: Band;
}

const Container = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: 10px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  text-align: center;
  margin: 0 2rem;
  padding: 2rem 4rem;
  > i {
    color: rgba(0, 0, 0, 0.7);
  }
  > h3 {
    text-transform: uppercase;
    color: ${props => props.theme.colors.blue};
    margin-bottom: 2.2rem;
    font-size: 3rem;
    margin-top: 0.5rem;
    font-weight: bold;
  }
  strong {
    font-weight: bold;
  }
`;

const Item = styled.p`
  margin-bottom: 1rem;
  color: rgba(0, 0, 0, 0.7);
`;

const PriceText = styled.p`
  color: ${props => props.theme.colors.blue};
  margin-top: 2rem;
  > strong {
    font-size: 2rem 3;
  }
`;

const PricingCard: FC<Props> = observer(({ band: { id, subscriptionType, canceledSubscription } }) => {
  const { on, toggle } = useToggle();

  const handleCancelSubscription = async () => {
    const res = await fetch('https://backend.gighub.ch/stripe', {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ idBand: id }),
    });
    toggle();
    window.location.reload();
  };

  return (
    <Container>
      <i>Current plan:</i>
      <h3>{subscriptionType}</h3>
      {subscriptionType === 'free' && (
        <>
          <Item>
            Up to <strong>{MAX_YEARLY_EVENTS} yearly events</strong>{' '}
          </Item>
          <Item>
            <strong>{MAX_FORMATIONS} Formation</strong>{' '}
          </Item>
          <Item>
            Up to <strong>{MAX_MEMBERS} members</strong>{' '}
          </Item>
        </>
      )}
      <PriceText>
        <strong>{subscriptionType === 'premium' ? 'You are premium!' : PREMIUM_PRICE + '/ Month' }</strong>
      </PriceText>
      {subscriptionType === 'free' ? (
        <PaymentButton bandId={id} />
      ) : (
        <DangerButton disabled={canceledSubscription} onClick={toggle} style={{ marginTop: '2rem' }}>
          Cancel subscription
        </DangerButton>
      )}
      <Dialog open={on} onClose={toggle}>
        <DialogTitle>Cancel Subscription</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to cancel your subscription to Gighub?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggle} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCancelSubscription} color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      {canceledSubscription && <Item style={{ marginTop: '2rem' }}>Will cancel at period end</Item>}
    </Container>
  );
});

export default PricingCard;
