import {action, computed} from 'mobx';
import {GighubEvent} from 'src/models/gighubEvent';
import {Store} from './Store';
import {compareAsc, compareDesc, isFuture, isPast} from 'date-fns';
import {Model} from '../models/model';
import {isParticipating} from "../models/basics/MemberRoles";

export class EventsStore extends Store<GighubEvent> {
    getAllEvents = () => {
        return this.observables
            .slice()
            .sort((a: GighubEvent, b: GighubEvent) => compareAsc(a.dateStart, b.dateStart))
    }

    getAllEventsByBand = (idBand: string) => {
        return this.getAllEvents()
            .filter(e => e.idBand === idBand)
    }

    getFuture = (arr: GighubEvent[]) => {
        return arr
            .filter(e => isFuture(e.dateEnd))
    }

    getPast = (arr: GighubEvent[]) => {
        return arr
            .filter(e => isPast(e.dateEnd))
    }

    getParticipating = (arr: GighubEvent[]) => {
        return arr
            .filter(e => isParticipating(e))
    }

    @action
    getAllEventsParticipating = () => {
        return computed(() =>
            this.getParticipating(
                this.getAllEvents())
        )
    }


    @action
    getUpcomingEvents = () => {
        return computed(() =>
            this.getParticipating(
                this.getFuture(
                    this.getAllEvents()))
        );
    };

    @action
    getEventsByBand = (idBand: string) => {
        return computed(() =>
            this.getAllEventsByBand(idBand)
        );
    };

    @action
    getEventsParticipatingByBand = (idBand: string) => {
        return computed(() =>
            this.getParticipating(
                this.getAllEventsByBand(idBand)
            )
        );
    };


    @action
    getFutureEventsByBand = (idBand: string) => {
        return computed(() =>
            this.getFuture(
                this.getAllEventsByBand(idBand)
            )
        );
    };

    @action
    getFutureEventsParticipatingByBand = (idBand: string) => {
        return computed(() =>
            this.getParticipating(
                this.getFuture(
                    this.getAllEventsByBand(idBand)
                ))
        );
    };

    @action
    getPastEventsByBand = (idBand: string) => {
        return computed(() =>
            this.getPast(
                this.getAllEventsByBand(idBand)
            )
        );
    };

    @action
    getPastEventsParticipatingByBand = (idBand: string) => {
        return computed(() =>
            this.getParticipating(
                this.getPast(
                    this.getAllEventsByBand(idBand)
                ))
        );
    };

    @action
    getFutureEvents = () => {
        return computed(() =>
            this.getFuture(
                this.getAllEvents()
            )
        );
    };

    @action
    getPastEvents = () => {
        return computed(() =>
            this.getPast(
                this.getAllEvents()
            )
        );
    };

    @action
    update = (model: Model) => {
        if (model.id) {
            model['dateStart'] = new Date(model['dateStart']);
            model['dateEnd'] = new Date(model['dateEnd']);
            model['dateCreated'] = new Date(model['dateCreated'] || '');
            model['dateEdited'] = new Date(model['dateEdited'] || '');
            const objIndex = this.observables.findIndex(t => t.id === model.id);
            if (objIndex > -1) {
                this.observables[objIndex] = model as GighubEvent;
            } else {
                this.observables.push(model as GighubEvent);
            }
        }
        return this.observables;
    };
}
