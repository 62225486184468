export type AppTheme = typeof theme;

export const theme = {
  colors: {
    background: 'linear-gradient(to right, rgba(240,240,240,1) 0%, rgba(254,254,254,1) 100%)',
    primary: '#090909',
    primaryLight: '#303030',
    primaryDark: '#1d4ec2',
    black: '#2D2D2D',
    white: 'rgba(254,254,254,1)',
    lightGrey: '#dddddd',
    grey: '#AEAEAE',
    darkGrey: '#5f5f5f',
    secondary: '#F3F3F3',
    danger: '#dc3545',
    orange: '#f77c00',
    success: '#28a745',
    blue: '#0862ef',
  },
  layout: {
    headerHeight: '6.5rem',
    footerHeight: '5.5rem',
    settingsContainerPadding: '2rem 2.5rem',
    sidebarDesktopWidth: '33rem',
  },
  trafficLight: {
    darkGrey: '#5f5f5f',
    red: '#fcb0a3',
    yellow: '#fcf197',
    green: '#baecc3',
    brownish: '#CCAEAD',
    greenish: '#A8AF93'
  },
  breakpoints: {
    desktop: 1000,
  },
};
