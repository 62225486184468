import * as React from 'react';
import styled from "styled-components";
import TextFieldLight from "../../_common/_elements/TextFieldLight";
import { SecondaryButton } from "../../_common/_elements";
import { auth } from "../../../database/fbApp";
import { navigate } from "@reach/router";
import { Routes } from "../../../constants/Routes";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import firebase from "firebase/compat";
import FirebaseError = firebase.FirebaseError;
import { NotificationController } from 'src/controllers/notificationController';
import { Band } from 'src/models/band';
import { NotificationTypes } from 'src/models/basics/NotificationTypes';
import { User } from 'src/models/user';
import { BandController } from 'src/controllers/bandController';

interface SignupFormProps {
    idBand?: string | undefined;
    email?: string | undefined;
}

interface SignupFormState {
    isEnteringLocation: boolean;
    signupFormData: {
        email: string,
        password: string,
        confirmPassword: string,
    };
    error: {
        email: string,
        password: string,
        confirmPassword: string,
    },
}


const Form = styled.form`
  display: grid;
  grid-row-gap: 1.1rem;
`;

const SubmitButton = styled(SecondaryButton)`
  margin-top: 2.4rem;
`;


class SignupForm extends React.Component<SignupFormProps, SignupFormState> {

    state: SignupFormState = {
        isEnteringLocation: false,
        signupFormData: {
            confirmPassword: "",
            email: (this.props.email && this.props.email !== ':email') ? this.props.email : "",
            password: "",
        },
        error: {
            email: (this.props.email && this.props.email !== ':email') ? this.props.email : "",
            password: "",
            confirmPassword: ""
        },
    };

    handleChange = (e: any) => {
        this.setState({ signupFormData: { ...this.state.signupFormData, [e.target.name]: e.target.value } })
    };

    setError(fieldName: string, message: string) {
        this.setState({ error: { ...this.state.error, [fieldName]: message } });
    }

    handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const { signupFormData: { email, password, confirmPassword } } = this.state;
        // todo reichen lat und lng??

        // todo more VALIDATION and show toast
        if (password !== confirmPassword) {
            this.setError("confirmPassword", "Passwords do not match");
        } else if (password.length < 6) {
            this.setError("password", "Your password should have at least 6 characters");
        } else {
            try {
                const res = await createUserWithEmailAndPassword(auth, email, password);
                if (res && res.user) {
                    // create band invitation notification:
                    // TODO: check if user is already in band
                    // TODO: tell the user which band it is
                    if (this.props.idBand && this.props.idBand !== ':idBand') {
                        await new NotificationController().notify(
                            { id: res.user.uid } as Partial<User>,
                            'You have been invited to join a band',
                            NotificationTypes.BANDINVITATION,
                            this.props.idBand
                        );
                    }
                    // todo activation mail error handling
                    await sendEmailVerification(res.user, { url: 'https://gighubch.firebaseapp.com/' })
                        .catch(err => alert('send activation email failure'));
                    navigate(Routes.HOME);
                }
            } catch (e) {
                const err: FirebaseError = e;
                switch (err.code) {
                    case "auth/email-already-in-use":
                        this.setError("email", err.message);
                        break;
                    case "auth/invalid-email":
                        this.setError("email", err.message);
                        break;
                    case "auth/weak-password":
                        this.setError("password", err.message);
                        break;
                    default:
                        console.log("SignupForm" + err.code);
                        break;
                }
                console.log(e);
            }
        }
    };


    render() {
        const { signupFormData: { confirmPassword, email, password }, error } = this.state;
        return (
            <Form onSubmit={this.handleSubmit}>
                {(this.props.email && this.props.email !== ':email') ?
                    <TextFieldLight
                        name="email"
                        type="email"
                        value={this.props.email}
                        fullWidth
                        data-testid="signup_email_input"
                    />
                    :
                    <TextFieldLight
                        name="email"
                        type="email"
                        label={error.email ? error.email : 'email'}
                        placeholder="you@email.com"
                        error={Boolean(error.email)}
                        onChange={this.handleChange}
                        value={email}
                        fullWidth
                        required
                        data-testid="signup_email_input"
                    />}
                <TextFieldLight
                    name="password"
                    type="password"
                    label={error.password ? error.password : 'password'}
                    error={Boolean(error.password)}
                    value={password}
                    onChange={this.handleChange}
                    fullWidth
                    required
                    data-testid="signup_password_input"
                />
                <TextFieldLight
                    name="confirmPassword"
                    type="password"
                    label={error.confirmPassword ? error.confirmPassword : 'confirm password'}
                    error={Boolean(error.confirmPassword)}
                    value={confirmPassword}
                    onChange={this.handleChange}
                    fullWidth
                    required
                    data-testid="signup_confirm_password_input"
                />
                <SubmitButton type="submit">Confirm</SubmitButton>
            </Form>
        )
    }
}


export default SignupForm;