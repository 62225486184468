import {InjectedFormikProps, withFormik} from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';
import {SecondaryButton, BlueButton} from '../../_common/_elements';
import {User} from '../../../models/user';
import {Address} from '../../../models/basics/address';
import {parseAddress} from '../../../utils/parseAddress';
import {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import LocationSearchInput from '../../_common/_elements/LocationSearchInput';
import {toast} from 'react-toastify';
import {UserController} from '../../../controllers/userController';
import {auth} from '../../../database/fbApp';
import Toggle from '../../_common/utilities/Toggle';
import ChangeEmailForm from './ChangeEmailForm';
import ChangePasswordForm from './ChangePasswordForm';
import Br from '../../_common/_elements/Br';
import {Dialog} from "@material-ui/core";
import TextFieldLight from 'src/views/_common/_elements/TextFieldLight';

interface FormValues {
    name: string;
    surname: string;
    tel: string;
    address: Address;
}

interface FormProps {
    user: User;
}

const Form = styled.form`
  margin-top: 2rem;
  margin-bottom: 2rem;
  > .btn {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = ({
                                                                              user,
                                                                              handleSubmit,
                                                                              isSubmitting,
                                                                              handleChange,
                                                                              values,
                                                                              errors,
                                                                              touched,
                                                                              setFieldValue,
                                                                          }) => (
    <>
        <Form onSubmit={handleSubmit}>
            <TextFieldLight
                id="surname"
                name="surname"
                fullWidth={true}
                label={errors.surname && touched.surname ? errors.surname : 'Surname'}
                error={Boolean(touched.surname && errors.surname)}
                type="text"
                onChange={handleChange}
                value={values.surname}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextFieldLight
                id="name"
                name="name"
                fullWidth={true}
                label={errors.name && touched.name ? errors.name : 'Name'}
                error={Boolean(touched.name && errors.name)}
                type="text"
                onChange={handleChange}
                value={values.name}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextFieldLight
                id="tel"
                name="tel"
                fullWidth={true}
                label={errors.tel && touched.tel ? errors.tel : 'Phone Number'}
                error={Boolean(touched.tel && errors.tel)}
                type="tel"
                onChange={handleChange}
                value={values.tel}
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <LocationSearchInput
                light={true}
                initialAddress={user.address}
                handleSelect={address => {
                    geocodeByAddress(address)
                        .then(results => getLatLng(results[0]))
                        .then(latLng => {
                            // todo hide key
                            fetch(
                                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${
                                    latLng.lng
                                }&key=AIzaSyCLaNc6hiUN1dwkwraI9JUb3FcAe1veqnY`
                            )
                                .then(res => res.json())
                                .then(data => {
                                    const {city, country, streetnr, street, lng, zip, lat} = parseAddress(data.results);
                                    setFieldValue(
                                        'address',
                                        new Address({
                                            lat,
                                            zip,
                                            lng,
                                            street,
                                            streetnr,
                                            country,
                                            city,
                                        })
                                    );
                                    setFieldValue('locationName', address);
                                });
                        })
                        .catch(error => console.error('Error', error));
                }}
            />
            <BlueButton className="btn" type="submit" disabled={isSubmitting}>
                Save
            </BlueButton>
            <Br/>
        </Form>
        {auth.currentUser &&
        auth.currentUser.providerData &&
        (auth.currentUser.providerData[0] as any).providerId !== 'google.com' && (
            <>
                <Toggle>
                    {({on, toggle}) => (
                        <>
                            <Dialog open={on} onClose={toggle}>
                                <ChangeEmailForm currentUser={user}/>
                            </Dialog>
                            <SecondaryButton type="button" onClick={toggle}>
                                Change Email
                            </SecondaryButton>
                        </>
                    )}
                </Toggle>
                <br/>

                <Toggle>
                    {({on, toggle}) => (
                        <>
                            <Dialog open={on} onClose={toggle}>
                                <ChangePasswordForm/>
                            </Dialog>
                            <SecondaryButton type="button" onClick={toggle}>
                                Change Password
                            </SecondaryButton>
                        </>
                    )}
                </Toggle>
            </>
        )}
    </>
);

const UserDataForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: props => ({
        name: props.user.name || '',
        surname: props.user.surname || '',
        tel: props.user.tel || '',
        address: (props.user && props.user.address) || new Address({}),
    }),
    validationSchema: Yup.object().shape({
        name: Yup.string()
            .max(25, "Field shouldn't be longer than 25 characters")
            .required('This field is required'),
        surname: Yup.string()
            .max(25, "Field shouldn't be longer than 25 characters")
            .required('This field is required'),
        phoneNumber: Yup.string().max(25, "Field shouldn't be longer than 25 characters"),
        address: Yup.object().required('This field is required'),
    }),
    handleSubmit: async (values, {props, setSubmitting, setFieldError}) => {
        setSubmitting(true);
        if (!values.address.lat || !values.address.lng) {
            toast.error("We couldn't find your location, please pick a valid location");
            setSubmitting(false);
            return;
        }
        try {
            if (auth.currentUser) {
                await new UserController().update(
                    auth.currentUser.uid,
                    new User({
                        ...props.user,
                        name: values.name,
                        surname: values.surname,
                        tel: values.tel || '',
                        address: values.address,
                    })
                );
                toast.success('Update successful');
            }
        } catch (e) {
            toast.error('Error updating your data, please try again');
            setSubmitting(false);
            return;
        }
        setSubmitting(false);
    },
})(InnerForm);

export default UserDataForm;
