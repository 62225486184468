import styled from "styled-components";
import transparentize from "polished/lib/color/transparentize";


const Button = styled.button`
     cursor: pointer;
     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
     border-radius: 50px;
     border: none;
     padding: 1rem 1.5rem;
     outline: none;
     font-size: 2rem;
     font-weight: normal;
     display: block;
     width: 100%;
     text-align: center;
     transition: all .2s;
     &:disabled {
        cursor: not-allowed;
        background: ${props => props.theme.colors.grey};
    }
    &:not(:disabled):hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    }
`;

export const PrimaryButton = styled(Button)`
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.secondary};
     &:disabled {
        background: ${props => transparentize(.3, props.theme.colors.primary)};
        color: ${props => transparentize(.3, props.theme.colors.secondary)};
     }
`;

export const SecondaryButton = styled(Button)`
  background: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.primary};
     &:disabled {
        background: ${props => transparentize(.3, props.theme.colors.secondary)};
        color: ${props => transparentize(.3, props.theme.colors.primary)};
     }
`;

export const BlueButton = styled(PrimaryButton)`
   background: ${props => props.theme.colors.blue};
   color: ${props => props.theme.colors.white};
`;

export const DangerButton = styled(PrimaryButton)`
   background: ${props => props.theme.colors.danger};
   color: ${props => props.theme.colors.white};
   &:disabled {
        background: ${props => transparentize(.3, props.theme.colors.secondary)};
        color: ${props => transparentize(.3, props.theme.colors.primary)};
     }
`;
