import React, {FC} from 'react';
import styled, {withTheme} from "styled-components";
import Icon, {IconName} from "../../../../_common/_elements/Icon";
import {AppTheme} from "../../../../../theme";
import {transparentize} from 'polished';
import {observer} from 'mobx-react-lite';
import {useToggle} from 'src/hooks/useToggle';
import {Dialog} from "@material-ui/core";
import {DeleteForm} from "../../../../_common/utilities/DeleteForm";
import { BandAddress as BandAddressModel } from 'src/models/bandAddress';
import BandAddressForm from './BandAddressForm';

interface Props {
    theme: AppTheme;
    bandAddress: BandAddressModel;
    idBand: string;
    handleDelete: (id: string) => void
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto auto auto;
  grid-gap: 1rem;
  padding: 1.5rem;
  margin-top: 2rem;
  border-bottom: 1px solid ${props => transparentize(0.3, props.theme.colors.lightGrey)};
  color: ${props => props.theme.colors.white};
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  transition: all .25s;
  &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
        background: none;
  }
`;

const BandAddress: FC<Props> = observer(({idBand, bandAddress, handleDelete, theme: {colors: {secondary}}}) => {

    const {on, toggle} = useToggle();
    const {on: onDelete, toggle: toggleDelete} = useToggle();

    return (
        <>
            <Container>
                <p>{bandAddress.name} ({bandAddress.address})</p>
                <StyledIcon onClick={toggle} name={IconName.EDIT} color={secondary}/>
                <StyledIcon onClick={toggleDelete} name={IconName.DELETE} color={secondary}/>
            </Container>
            <Dialog open={on} onClose={toggle}>
                <BandAddressForm toggle={toggle} isNew={false} idBand={idBand} bandAddress={bandAddress}/>
            </Dialog>
            <DeleteForm open={onDelete} handleClose={toggleDelete} model={bandAddress} modelName={"Customer"} handleDelete={handleDelete}/>
        </>
    )

});

export default withTheme(BandAddress);