import { Address } from "../models/basics/address";

export function parseAddress(results: any): Address {
    const address: Address = {};
    address.lat = results[0].geometry.location.lat;
    address.lng = results[0].geometry.location.lng;
    const arrAddress = results[0].address_components;
    for (const ac of arrAddress) {
        if (ac.types[0] === "street_number") {
            address.streetnr = ac.long_name;
        }
        if (ac.types[0] === "route") {
            address.street = ac.short_name;
        }
        if (ac.types[0] === "locality") {
            address.city = ac.long_name;
        }
        if (ac.types[0] === "postal_code") {
            address.zip = ac.long_name;
        }
        if (ac.types[0] === "country") {
            address.country = ac.long_name;
        }
    }
    return address;
}
