import { Model } from "./model";
import { Collections } from "./basics/Collections";
import { Address } from "./basics/address";
import { observable } from 'mobx';

export class UsersBands implements Model {
   collection?: Collections;
   id?: string;
   idUser?: string;
   idBand?: string;

    constructor(o: UsersBands) {
        this.collection = Collections.USERSBANDS;
        this.id = o.id || '';
        this.idUser = o.idUser || '';
        this.idBand = o.idBand || '';
    }
}