import styled from "styled-components";
import RichTextEditor from "react-rte";

interface StyleConfig {
    label: string;
    style: string;
    className?: string;
}

type StyleConfigList = StyleConfig[];
type GroupName =
    | "INLINE_STYLE_BUTTONS"
    | "BLOCK_TYPE_BUTTONS"
    | "LINK_BUTTONS"
    | "BLOCK_TYPE_DROPDOWN"
    | "HISTORY_BUTTONS"
    | "IMAGE_BUTTON";

interface ToolbarConfig {
    display: GroupName[];
    extraProps?: object;
    INLINE_STYLE_BUTTONS: StyleConfigList;
    BLOCK_TYPE_DROPDOWN: StyleConfigList;
    BLOCK_TYPE_BUTTONS: StyleConfigList;
}

const WYSIWYG = styled(RichTextEditor)`
  &&{
    margin-bottom: 1rem;
  }
`;

export const WYSIWYGtoolbarConfig: ToolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
        {label: 'Bold', style: 'BOLD'},
        {label: 'Italic', style: 'ITALIC'}
    ],
    BLOCK_TYPE_DROPDOWN: [
        {label: 'Normal', style: 'unstyled'},
        {label: 'Heading Small', style: 'header-three'}
    ],
    BLOCK_TYPE_BUTTONS: [
        {label: 'UL', style: 'unordered-list-item'},
        {label: 'OL', style: 'ordered-list-item'}
    ]
};

export const WYSIWYGtoolbarConfigSlim: ToolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [],
    INLINE_STYLE_BUTTONS: [],
    BLOCK_TYPE_DROPDOWN: [],
    BLOCK_TYPE_BUTTONS: []
};


export default WYSIWYG;