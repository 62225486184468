import * as React from 'react';
import styled from "styled-components";
import Icon, { IconName, IconSize } from "../../_common/_elements/Icon";

interface ShowAllButtonProps {
    active: boolean;
    clickHandler: () => any;
    text: string
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
`;
const ShowAllText = styled.p`
  color: #AEAEAE;
  font-size: 1.2rem;
`;


const ShowAllButton = (props: ShowAllButtonProps) => (
    <Container onClick={props.clickHandler}>
        <ShowAllText>{props.text}</ShowAllText>
        <Icon color="#AEAEAE" name={props.active ? IconName.ARROW_UP : IconName.ARROW_DOWN} size={IconSize.SMALL}/>
    </Container>
);


export default ShowAllButton;