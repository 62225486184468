import * as React from 'react';
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { parseAddress } from "../../../utils/parseAddress";
import { Address } from "../../../models/basics/address";
import { AuthContainer } from "../_elements/AuthContainer";
import LocationSearchInput from "../../_common/_elements/LocationSearchInput";
import TextFieldLight from "../../_common/_elements/TextFieldLight";
import styled from "styled-components";
import { BlueButton } from "../../_common/_elements";
import { UserController } from "../../../controllers/userController";
import { auth } from "../../../database/fbApp";
import { User } from "../../../models/user";
import { navigate } from "@reach/router";
import { Routes } from "../../../constants/Routes";

interface LocationPickerProps {
    path: string;
}

interface LocationPickerState {
    surname: string;
    name: string;
    error: {
        name: string;
        surname: string;
    };
    address: Address;
}

const Container = styled.div`
    padding: 2rem;
    color: ${props => props.theme.colors.white};
    display: grid;
    height: 100vh;
    align-content: center;
`;

const Form = styled.form`
    display: grid;
    grid-gap: 1.5rem;
    margin-top: 1rem;
`;


class AdditionalUserInformationPage extends React.Component<LocationPickerProps, LocationPickerState> {

    state: LocationPickerState = {
        surname: '',
        name: '',
        error: {
            surname: '',
            name: ''
        },
        address: {
            lng: '',
            lat: '',
            street: '',
            zip: '',
            country: '',
            streetnr: '',
            city: ''
        }
    };

    handleLocationSelect = (address: any) => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                // todo hide key
                fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=AIzaSyCLaNc6hiUN1dwkwraI9JUb3FcAe1veqnY`)
                    .then(res => res.json())
                    .then(data => {
                        this.setState({ address: parseAddress(data.results) }, () => console.log(this.state));
                    });
            })
            // todo error handling for location picker
            .catch(error => console.error('Error', error));
    };

    handleChange = (e: any) => {
        const change = {};
        change[e.target.name] = e.target.value;
        this.setState(change)
    };

    isValid() {
        const { address, name, surname } = this.state;
        // todo lat lng enough?
        if (!address.lat || !address.lng) {
            // todo show location missing toast
            return false;
        }
        if (!name || !surname) {
            if (!name) {
                this.setState({ error: { ...this.state.error, name: 'This is a required field' } })
            } else {
                this.setState({ error: { ...this.state.error, surname: 'This is a required field' } })
            }
            return false;
        }
        return true;
    }

    handleSubmit = async (e: any) => {
        e.preventDefault();
        const { name, surname, address: { lat, city, streetnr, country, zip, street, lng } } = this.state;
        if (!this.isValid()) {
            return
        }
        if (!auth.currentUser || !auth.currentUser.email) {
            navigate("/login");
            return;
        }
        await new UserController().createWithId(auth.currentUser.uid, new User({
            email: auth.currentUser.email,
            name,
            surname,
            address: new Address({ lat, city, street, streetnr, country, zip, lng })

        }));
        navigate(Routes.NOBANDSPAGE);
        window.location.reload();
    };

    render() {
        const { name, surname, error } = this.state;
        return (
            <AuthContainer>
                <Container>
                    <h2 style={{ fontWeight: 'bold', marginBottom: '1rem' }}>Almost there!</h2>
                    <p>We just need a few additional informations. </p>
                    <Form onSubmit={this.handleSubmit}>
                        <TextFieldLight
                            name="surname"
                            type="surname"
                            label={error.surname ? error.surname : 'Surname'}
                            value={surname}
                            error={Boolean(error.surname)}
                            onChange={this.handleChange}
                            fullWidth
                            required
                        />
                        <TextFieldLight
                            name="name"
                            type="name"
                            label={error.name ? error.name : 'Name'}
                            value={name}
                            error={Boolean(error.name)}
                            onChange={this.handleChange}
                            fullWidth
                            required
                        />
                        <LocationSearchInput handleSelect={this.handleLocationSelect} light={true} />
                        <BlueButton style={{ marginTop: '2rem' }} type="submit">Submit</BlueButton>
                    </Form>
                </Container>
            </AuthContainer>
        )
    }
}

export default AdditionalUserInformationPage;