import * as React from 'react';
import {AuthContainer} from "../_elements/AuthContainer";
import styled from "styled-components";
import BottomText from "../_elements/BottomText";
import AuthTitle from "../_elements/AuthTitle";
import LoginForm from "./LoginForm";
import {Link} from "@reach/router";
import {Routes} from "../../../constants/Routes";


interface LoginPageProps {
    path: string;
}

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
    padding: 2rem 0;
    grid-gap: 2rem;
`;


const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  margin-top: 4rem;
  justify-self: center;
  align-self: center;
  max-width: 100rem;
  height: 100%;
`;

const SignupLink = styled(Link)`
  align-self: end;
`;


class LoginPage extends React.Component<LoginPageProps, {}> {

    render() {
        return (
            <AuthContainer>
                <Wrapper>
                    <LoginContainer>
                        <AuthTitle>gighub</AuthTitle>
                        <LoginForm/>
                    </LoginContainer>
                    <SignupLink to={Routes.SIGNUP}>
                        <BottomText text={'Not a member yet?'} boldText="Sign up"/>
                    </SignupLink>
                </Wrapper>
            </AuthContainer>
        )
    }
}





export default LoginPage;