export enum Routes {
    HOME = '/',
    SIGNUP = '/signup/:idBand/:email',
    FORGOT_PASSWORD = '/forgot_password',
    CALENDAR = '/calendar',
    EVENTS = '/events',
    EVENT = '/events/:id',
    EVENT_FORM_PAGE = '/event_form/:idBand/:id',
    NOTIFICATIONS = '/notifications',
    USER_SETTINGS = '/user_settings/:id',
    BANDS = '/bands',
    BAND_SETTINGS = '/bands/:id',
    BAND_SETTINGS_WITH_TAB = '/bands/:id/:tab',
    MEMBER_SETTINGS = '/member_settings/:id',
    ANSWER_BAND_INVITE = '/answer_band_invite/:idBand',
    ANSWER_EVENT_INVITE = '/answer_event_invite/:idEvent',
    ICAL = '/ical',
    NOBANDSPAGE = '/pleasehelpmeihavenobands',
    SUCCESS = '/success/:idBand'
}

export enum Links{
    HOME = '/',
    EVENTS = '/events/',
    EVENT = '/events/',
    EVENT_FORM_PAGE = '/event_form/',
    NEW_EVENT_FORM_PAGE = '/event_form/:id/:id',
    BANDS = '/bands/',
    BAND_SETTINGS = '/bands/',
    MEMBER_SETTINGS = '/member_settings/',
    ANSWER_BAND_INVITATION = 'answer_band_invitation/',
    ICAL = '/ical',
    SUCCESS = '/success/'
}