import {Collections} from "../models/basics/Collections";
import "firebase/compat/auth";
import {Controller} from "./controller";



export class MailController extends Controller{
    constructor() {
        super(Collections.MAIL);
    }
}