import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
import FacebookAuthProvider = firebase.auth.FacebookAuthProvider;
import { UserController } from "../controllers/userController";
import { User } from "../models/user";
import { getAuth } from "firebase/auth";
import { initializeFirestore } from 'firebase/firestore'
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { enableIndexedDbPersistence } from "firebase/firestore";


const config = {
    apiKey: "AIzaSyABzLzLObC_4q4dI4GzwXYVkOF0dPEHODY",
    authDomain: "gighubch.firebaseapp.com",
    databaseURL: "https://gighubch.firebaseio.com",
    projectId: "gighubch",
    storageBucket: "gighubch.appspot.com",
    messagingSenderId: "433672888053",
    appId: "1:433672888053:web:1a00de13177cef042d6fbc"
};



export const fbApp = firebase.initializeApp(config);
export const googleAuthProvider = new GoogleAuthProvider();
export const facebookAuthProvider = new FacebookAuthProvider();
export const auth = getAuth(fbApp);
// export const storage = getStorage(fbApp);
/* fbApp.firestore().settings({
    cacheSizeBytes: 2048576
}); */
const database = initializeFirestore(fbApp, { useFetchStreams: false } as any)
export const currentUser = {} as User;
enableIndexedDbPersistence(database)
    .catch((err) => {
        if (err.code === 'failed-precondition') {
            console.log('failed-precondition persistence')
        } else if (err.code === 'unimplemented') {
            console.log('unimplemented persistence')
        }
    });

const initializePush = () => {
    let notificationsAllowed = true;
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') !== -1) {
        if (ua.indexOf('chrome') > -1) {
            notificationsAllowed = true;
        } else {
            notificationsAllowed = false;
        }
    }

    if (notificationsAllowed) {
        const messaging = getMessaging(fbApp);
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                console.log('Notification permission granted.');
                getToken(messaging, { vapidKey: 'BNTUWHE9y0JkU82U5S3BPzjHcfQGzwrPhOdX6-Im3d5E9z6u6iu-5pmCF0MpUJpUyb_8qW_dA43wJEEldS1Y9CY' }).then((currentToken) => {
                    if (currentToken) {
                        new UserController().handleFcmToken(currentToken);
                    } else {
                        // Show permission request.
                        console.log('No Instance ID token available. Request permission to generate one.');
                    }
                }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                    // ...
                });

            } else {
                console.log('Unable to get permission to notify.');
            }
        });

        onMessage(messaging, (payload) => {
            console.log("Notification Received", payload);
        });
    }
}

export { database, initializePush };
