import styled from 'styled-components';
import rgba from "polished/lib/color/rgba";
import { elevation } from '../../_common/utilities/index'

export const AuthContainer = styled.div`
    height: 100vh;
    width: 100%;
    max-width: 180rem;
    min-width: 30rem;
    ${elevation[ 5 ]};
    margin: 0 auto;
    background-image: linear-gradient(150.91deg, 
        ${props => rgba(props.theme.colors.primary, .9)} -1.26%, 
        ${props => rgba(props.theme.colors.primary, .9)} 45.89%, 
        ${props => rgba(props.theme.colors.primaryLight, .9)} 100.63%), 
        url("https://res.cloudinary.com/bassplayerch/image/upload/c_scale,f_auto,h_1545,q_auto/v1530704427/gighub/ron-smith-328524-unsplash.jpg");
    background-size: cover;
    overflow-y: auto;
    @media screen and (min-width: 50rem){
      background-position-y: 20%;
    }
     @media screen and (min-width: 62.5rem){
      background-position-y: 30%;
    }
     @media screen and (min-width: 70rem){
      background-position-y: 40%;
    }
         @media screen and (min-width: 80rem){
      background-position-y: 50%;
    }
`;