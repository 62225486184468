import {Model} from "./model";
import {Collections} from "./basics/Collections";

export class BillingInfos implements Model {
    collection?: string;
    id?: string;
    idBand?: string;
    logo?: string;
    bandAddress?: string;
    customTitle?: string;
    letterContent?: string;
    footerContent?: string;
    iban?: string;
    accountHolder?: string;
    bank?: string;
    bic?: string;
    website?: string;

    constructor(o:BillingInfos){
        this.collection = Collections.BILLINGINFOS + "/" + o.idBand;
        this.id = o.id || "";
        this.idBand = o.idBand || '';
        this.logo = o.logo || "";
        this.bandAddress = o.bandAddress || "";
        this.customTitle = o.customTitle || "";
        this.letterContent = o.letterContent || "";
        this.footerContent = o.footerContent || "";
        this.accountHolder = o.accountHolder || "";
        this.bank = o.bank || "";
        this.iban = o.iban || "";
        this.bic = o.bic || "";
        this.website = o.website || "";
    }
}