import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { Link, navigate } from '@reach/router';
import { Links, Routes } from 'src/constants/Routes';
import { Icon } from '../_elements';
import { IconSize, IconName } from '../_elements/Icon';
import { RootStoreContext } from '../../../store/RootStore';
import {auth} from "../../../database/fbApp";

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 5fr 1fr;
  align-items: center;
  padding-left: 4rem;
  width: ${props => props.theme.layout.sidebarDesktopWidth};
  background: ${props => props.theme.colors.black};
`;

const LinkContainer = styled.div`
  display: grid;
  grid-row-gap: 4rem;
`;

const MenuLink = styled('p')<{ isActive: boolean }>`
  text-decoration: none;
  color: ${props =>
    props.isActive ? props.theme.colors.white : props.theme.colors.grey};
  font-size: 2rem;
  &:visited {
    text-decoration: none;
  }
`;

const NotificationLabel = styled.div`
  position: absolute;
  top: -14px;
  left: calc(50% + (${IconSize.MEDIUM} / 2) + 5px);
  transform: translateX(-50%);
  font-size: 1.5rem;
  width: 2rem;
  height: 2rem;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.danger};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotificationLink = styled(Link)`
  position: relative;
`;

const DesktopSidebar: FC = () => {
  const {
    notificationsStore: { getUnseenNotifications },
    userStore: { currentUser }
  } = useContext(RootStoreContext);

  const notificationCount =
    (currentUser && getUnseenNotifications(currentUser.id!).length) || 0;

  const handleLogout = async () => {
    await auth.signOut();
  };

  return (
    <Container>
      <Link to={Routes.HOME}>
        <Icon testId="gighub_logo" size={IconSize.XXL} name={IconName.GIGHUB_LOGO} color={'white'} />
      </Link>
      <LinkContainer>
        <Link to={Routes.HOME}>
          <MenuLink isActive={location.pathname === Routes.HOME}>Hub</MenuLink>
        </Link>
        <Link to={Routes.CALENDAR}>
          <MenuLink isActive={location.pathname === Routes.CALENDAR}>
            Calendar
          </MenuLink>
        </Link>
        <Link to={Routes.EVENTS}>
          <MenuLink isActive={location.pathname === Routes.EVENTS}>
            Events
          </MenuLink>
        </Link>
        <NotificationLink to={Routes.NOTIFICATIONS}>
          <MenuLink isActive={location.pathname === Routes.NOTIFICATIONS}>
            Notifications
            {notificationCount && notificationCount > 0 ? (
              <NotificationLabel>{notificationCount}</NotificationLabel>
            ) : (
              <></>
            )}
          </MenuLink>
        </NotificationLink>
        <Link to={Routes.BANDS}>
          <MenuLink isActive={location.pathname === Routes.BANDS}>
            Your Bands
          </MenuLink>
        </Link>
        <Link to={Routes.USER_SETTINGS}>
          <MenuLink isActive={location.pathname === Routes.USER_SETTINGS}>
            My Account
          </MenuLink>
        </Link>
        <Link to={Routes.ICAL}>
          <MenuLink isActive={location.pathname === Routes.ICAL}>
            Link Your Calendar
          </MenuLink>
        </Link>
        <Link to={Routes.HOME}>
          <MenuLink onClick={handleLogout} isActive={false}>
            Logout
          </MenuLink>
        </Link>
      </LinkContainer>
    </Container>
  );
};

export default DesktopSidebar;
