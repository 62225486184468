import React, { FC } from 'react';
import { Customer as CustomerModel } from 'src/models/customer';
import CustomerForm from "./CustomerForm";
import Customer from './Customer';
import { useToggle } from 'src/hooks/useToggle';
import Fab from 'src/views/_common/_elements/Fab';
import styled from 'styled-components';
import {CustomerController} from "../../../../../controllers/customerController";
import {toast} from "react-toastify";
import { Dialog } from '@material-ui/core';


interface Props {
    idBand: string;
    customers: CustomerModel[]
}

const Container = styled.div`
`;

const CustomersSettings: FC<Props> = ({ customers, idBand }) => {
    async function handleDelete(id: string) {
        if (id && idBand) {
            try {
                await new CustomerController(idBand).delete(id);
                toast.info("deleted customer")
            } catch (e) {
                console.log(e)
            }
        }
    }

    const { on, toggle } = useToggle();
    return (
        <Container>
            {customers.map(customer => <Customer
                key={customer.id} idBand={idBand}
                customer={customer} handleDelete={handleDelete} />)}
            <Dialog open={on} onClose={toggle}>
                <CustomerForm toggle={toggle} newCustomer={true} idBand={idBand} />
            </Dialog>
            <Fab type={'ADD'} onClick={toggle} />
        </Container>
    )
}

export default CustomersSettings;