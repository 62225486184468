import {InjectedFormikProps, withFormik} from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';
import {SecondaryButton} from '../../../../_common/_elements';
import {MemberRoles} from '../../../../../models/basics/MemberRoles';
import Select from '@material-ui/core/Select/Select';
import AvatarImageSmall from '../../../../_common/_elements/AvatarImage';
import {truncate, fullnameToInitials} from '../../../../../utils/stringUtils';
import {FormControl, InputLabel, MenuItem} from '@material-ui/core';
import {Member} from "../../../../../models/basics/member";

interface FormValues {
    role: MemberRoles;
}

interface FormProps {
    member: Member;
    idUser: string;
    handleSubmit: (role: MemberRoles, idUser?: string) => any;
    toggle: () => any;
    authRole: MemberRoles;
}

const Form = styled.form`
  padding: ${props => props.theme.layout.settingsContainerPadding};
  > .btn {
    margin-top: 4rem;
  }
`;

const FormHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 1rem;
`;

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = ({
                                                                              authRole,
                                                                              member,
                                                                              handleSubmit,
                                                                              isSubmitting,
                                                                              handleChange,
                                                                              values,
                                                                          }) => (
    <Form onSubmit={handleSubmit}>
        <FormHeader>
            <AvatarImageSmall imgUrl={member.imageUrl} fallback={fullnameToInitials(member.fullName)} size="small"/>
            <strong>{truncate(member.fullName, 18)}</strong>
        </FormHeader>
        <FormControl>
            <InputLabel htmlFor="role">Role</InputLabel>
            <Select
                value={values.role}
                inputProps={{
                    name: 'role',
                    id: 'role',
                }}
                onChange={handleChange}
            >
                <MenuItem value={MemberRoles.SUB}>{MemberRoles.SUB}</MenuItem>
                <MenuItem value={MemberRoles.MUSICIAN}>{MemberRoles.MUSICIAN}</MenuItem>
                <MenuItem value={MemberRoles.CREW}>{MemberRoles.CREW}</MenuItem>
                <MenuItem value={MemberRoles.EDITOR}>{MemberRoles.EDITOR}</MenuItem>
                {authRole === MemberRoles.ADMIN && <MenuItem value={MemberRoles.ADMIN}>{MemberRoles.ADMIN}</MenuItem>}
            </Select>
        </FormControl>
        <SecondaryButton className="btn" type="submit" disabled={isSubmitting}>
            Submit
        </SecondaryButton>
    </Form>
);

const MemberForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: props => ({
        role: props.member.role,
    }),
    validationSchema: Yup.object().shape({}),
    handleSubmit: (values, {props, setSubmitting}) => {
        props.toggle();
        props.handleSubmit(values.role, props.idUser);
        setSubmitting(false);
    },
})(InnerForm);

export default MemberForm;
