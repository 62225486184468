import styled from 'styled-components';
import * as React from 'react';
import { FC } from 'react';

interface Props {
  size: 'small' | 'medium';
  imgUrl?: string | undefined | null;
  hoverText?: string;
  fallback: string;
}

const Avatar = styled('div')<Props>`
  width: ${props => (props.size === 'small' ? '4.5rem' : props.size === 'medium' ? '8.5rem' : '12.5rem')};
  height: ${props => (props.size === 'small' ? '4.5rem' : props.size === 'medium' ? '8.5rem' : '12.5rem')};
  position: relative;
  border-radius: 50%;
  display: inline-block;
  background-position: center;
  background: ${props => (props.imgUrl ? `url('${props.imgUrl}') center;` : 'lightgrey')};
  background-size: cover;
  &:hover {
    .AvatarText {
      display: block;
    }
  }
`;

const AvatarText = styled('div')`
  display: none;
  width: auto;
  height: 5rem;
  position: relative;
  border-radius: 50%;
  display: inline-block;
  background-size: cover;
  background: rgba(80, 80, 80, 0.44);
`;

const FallbackText = styled('div')<Props>`
  font-size: ${props => (props.size === 'medium' ? '2.5rem' : '1.5rem')};
  position: absolute;
  color: black;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

const AvatarImage: FC<Props> = props => (
  <Avatar {...props}>
    <AvatarText {...props}>{props.hoverText}</AvatarText>
    {!props.imgUrl && <FallbackText {...props}>{props.fallback}</FallbackText>}
  </Avatar>
);

export default AvatarImage;
