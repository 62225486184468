import * as React from 'react';
import styled from "styled-components";
import {EventStati} from "../../../models/basics/EventStati";
import {StatusColor} from "./StatusIcon";



const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 1rem auto 1rem auto;
  width: 100%;
  max-width: 1350px;
  overflow-y: auto;
`;


export const GighubMain = (props: {children: any}) => (
    <Main {...props}>
        {props.children}
    </Main>
);