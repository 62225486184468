import React, {FC} from 'react';
import styled, {withTheme} from "styled-components";
import {AppTheme} from "../../../../../theme";
import {transparentize} from 'polished';
import BillingForm from "./BillingForm";
import {BillingInfos} from "../../../../../models/billingInfos";
import {Band} from "../../../../../models/band";

interface Props {
    theme: AppTheme;
    billingInfos: BillingInfos | null;
    band: Band;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 1rem;
  padding: 1.5rem;
  border-bottom: 1px solid ${props => transparentize(0.3, props.theme.colors.lightGrey)};
  color: ${props => props.theme.colors.white};
`;


const Billing: FC<Props> = (props) => {
    return (
        <>
            <Container>
                <BillingForm billingInfos={props.billingInfos} band={props.band}/>
            </Container>
        </>
    )
};

export default withTheme(Billing);