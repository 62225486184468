import React, { FC, useRef, useLayoutEffect, useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { GighubEvent } from 'src/models/gighubEvent';
import { User } from 'src/models/user';
import { Address } from "../../../../../models/basics/address";
import { Band } from "../../../../../models/band";
import { Route } from "../../../../../models/basics/availability";
import { Member } from 'src/models/basics/member';
import { UserController } from 'src/controllers/userController';

interface Props {
    event: GighubEvent;
    user: User;
    band: Band;
}

const Container = styled.div`
    padding: 1.5rem;
`;

const Title = styled.h3`
    margin: 2rem 0;
    font-weight: bold;
`;

const StyledButton = styled.button`
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px 2px;
    cursor: pointer;
`;

// Modify the TextContainer style
const TextContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1.5rem;
  margin-top: 2rem;
  padding: 1rem;
  strong {
    font-weight: bold;
  }
  p {
  }
`;


const MapView: FC<Props> = observer(({ user: { address }, band, event: { dateStart, availabilities, address: { lat, lng, street, streetnr, city, country, zip }, locationName, } }) => {

    const [routes, setRoutes] = useState<Map<string, Route>>(new Map());
    const mapReference = useRef(null);
    const userController = new UserController();

    const calculateExpenses = async () => {
        const fetchData = async () => {
            const participatingMembers = availabilities && Object.entries(availabilities)
                .filter(([_, avail]) => avail.status === 'accepted')
                .map(([id, avail]) => ({ id, ...avail }));
            if (participatingMembers) {
                for (const member of participatingMembers) {
                    const user = await userController.get<User>(member.id);
                    if (user && user.address) {
                        await getRoute(mapReference, lat, lng, user.address, dateStart, member.id);
                    }

                }
            }
        };
        fetchData();
    };

    async function getRoute(mapRef: any, userLat: string | undefined, userLng: string | undefined, userAddress: Address | undefined, eventDateStart: Date, memberId: string) {
        // const directionsDisplay = new google.maps.DirectionsRenderer();
        if (mapRef && userLat && userLng && userAddress && userAddress.lat && userAddress.lng) {
            const userLocation = {
                lat: Number(userAddress.lat), lng: Number(userAddress.lng)
            }
            const eventLocation = {
                lat: Number(userLat), lng: Number(userLng)
            };
            /* const map = new google.maps.Map(mapRef.current, {
                center: eventLocation,
                zoom: 12
            }) */
            // directionsDisplay.setMap(map);

            const request = {
                origin: userLocation,
                destination: eventLocation,
                travelMode: google.maps.TravelMode.DRIVING,
                drivingOptions: {
                    departureTime: eventDateStart,
                    trafficModel: google.maps.TrafficModel.PESSIMISTIC
                }
            }

            await new google.maps.DirectionsService().route(request, (response, status) => {
                // @ts-ignore possible bug mit google maps typescript api. funktioniert aber
                if (status === 'OK' && response) {
                    // directionsDisplay.setDirections(response);
                    if (response.routes.length > 0 && response.routes[0].legs.length > 0) {
                        const newRoute = response.routes[0].legs[0];
                        if (newRoute && newRoute.distance && newRoute.duration) {
                            setRoutes(prevRoutes => {
                                const updatedRoutes = new Map(prevRoutes);
                                if (newRoute.distance && newRoute.duration) {
                                    updatedRoutes.set(memberId, {
                                        distance: newRoute.distance.text,
                                        duration: newRoute.duration.text,
                                        startAddress: newRoute.start_address,
                                        endAddress: newRoute.end_address
                                    });
                                }
                                return updatedRoutes;
                            });
                        }
                    }
                }
            });

        }
    }


    return (
        <Container>
            <StyledButton onClick={calculateExpenses}>Calculate Expenses</StyledButton>
            <TextContainer>
            <Title>Driving Expenses {locationName ? locationName : `${city}`}</Title>
                {Array.from(routes.entries()).map(([memberId, route]) => (
                    <div key={memberId}>
                        <h3>{band.members[memberId].fullName}</h3>
                        {route.distance && (
                            <>
                                <strong>Distance:</strong>
                                <p>{route.distance}</p>
                            </>
                        )}
                        {route.duration && (
                            <>
                                <strong>Duration:</strong>
                                <p>{route.duration} to arrive around {dateStart.toLocaleTimeString()} ({dateStart.toLocaleDateString()})</p>
                            </>
                        )}
                        {route.startAddress && (
                            <>
                                <strong>From:</strong>
                                <p>{route.startAddress}</p>
                            </>
                        )}
                        {route.endAddress && (
                            <>
                                <strong>To:</strong>
                                <p>{route.endAddress}</p>
                            </>
                        )}
                        {route.distance && (
                            <>
                                <strong>Cost:</strong>
                                <p>{(parseFloat(route.distance) * band.costPerKilometer).toFixed(2)} {band.currency}</p>
                            </>
                        )}
                    </div>
                ))}
            </TextContainer>
        </Container>
    )
})

export default MapView;