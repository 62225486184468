import styled from "styled-components";
import elevation from "../utilities/elevation";

const LabelButton = styled.button`
  border: none;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.secondary};
  font-size: 1.2rem;
  padding: .4rem 1.4rem;
  border-radius: 50px;
  transition: all .25s;
  cursor: pointer;
  &:hover{
     ${elevation[ 2 ]};
  }
`;

export const SecondaryLabelButton = styled(LabelButton)`
    background: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.primary};
    border: 1px solid ${props => props.theme.colors.primary};
    transition: all .25s;
    &:hover {
          background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
    }
`;

export default LabelButton;