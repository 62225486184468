import {Model} from "./model";
import {Collections} from "./basics/Collections";

export enum Salutations {
    NONE = "",
    HERR = "Herr",
    FRAU = "Frau",
    NONBINARY = "*"
}
export class Customer implements Model {
    collection?: string;
    id?: string;
    idBand: string;
    salutation: Salutations;
    company?: string;
    name?: string;
    surname?: string;
    email?: string;
    tel?: string;
    address?: string;

    constructor(o:Customer){
        this.collection = Collections.CUSTOMERS + "/" + o.idBand;
        this.id = o.id || ""
        this.idBand = o.idBand;
        this.company = o.company || "";
        this.surname = o.surname;
        this.salutation = o.salutation || Salutations.NONBINARY;
        this.name = o.name;
        this.email = o.email || "";
        this.tel = o.tel || "";
        this.address = o.address || "";
    }
}