import {Collections} from "../models/basics/Collections";
import "firebase/compat/auth";
import {Controller} from "./controller";



export class SecretTokenController extends Controller{
    constructor() {
        super(Collections.SECRET_TOKENS);
    }
}