import * as React from 'react';
import styled from "styled-components";

interface TabProps {
    active: boolean;
    onClick: () => any;
    children: React.ReactNode;
}

const TabLink = styled.a`
  padding: 1rem;
  display: block;
  width: 100%;
  text-align: center;
  border-bottom: ${props => (props as any).active ? `1px solid ${props.theme.colors.primary}` : 'none'};
  transition: all .25s;
  &:hover {
    background: #f5f5f5;
  }
`;

const Tab: React.FC<TabProps> = props => <TabLink {...props}/>;
export default Tab;