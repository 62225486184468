import {InjectedFormikProps, withFormik} from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import styled from "styled-components";
import {AvatarImage, BlueButton, Icon} from "../../../../_common/_elements";
import {toast} from "react-toastify";
import {TextField} from '@material-ui/core';
import {BillingInfos} from "../../../../../models/billingInfos";
import {BillingInfosController} from "../../../../../controllers/billingInfosController";
import {Band} from "../../../../../models/band";
import useCloudinaryImage from "../../../../../hooks/useFileUploader";

interface FormValues {
    logo: string;
    bandAddress: string;
    customTitle: string;
    letterContent: string;
    footerContent: string;
    iban: string;
    accountHolder: string;
    bank: string;
    bic: string;
    website: string;
}

interface FormProps {
    billingInfos: BillingInfos | null;
    band: Band;
}

const Form = styled.form`
    padding: 2.5rem 3.5rem;
    color: white;
`;

const Spacer = styled.div`
    height: 1.5rem;
`;

const WhiteTextField = styled(TextField)`
    color: white;
    background: white;
`;

const ProfileImg = styled(AvatarImage)`
margin-bottom: 2rem;
  &::before {
    width: 100%;
    height: 100%;
    background: ${props => props.theme.colors.darkGrey};
    opacity: 0.3;
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
  }
`;

const UploadButton = styled(Icon)`
  position: absolute;
  top: 40%;
  left: 50%;
  opacity: 0.9;
  transform: translate3d(-50%, -50%, 0);
`;


const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = ({
                                                                             band,
                                                                             billingInfos,
                                                                             errors,
                                                                             handleSubmit,
                                                                             isSubmitting,
                                                                             handleChange,
                                                                             values
                                                                         }) => {
    // const imageUrl = useCloudinary(billingInfos && billingInfos.logo, handleImageUploadSuccess, handleImageUploadError);

    /* function handleImageUploadSuccess(newImageUrl: string) {
        if (billingInfos && billingInfos.id) {
            new BillingInfosController().update(billingInfos.id, {
                ...billingInfos!,
                logo: newImageUrl
            });
        } else if (band && band.id) {
            new BillingInfosController().create({
                idBand: band.id,
                logo: newImageUrl
            });
        }
        toast.success("Successfully changed Image");
    }

    function handleImageUploadError(error: any) {
        toast.success("There was an error uploading your image, please try again");
    } */

    return (
        <Form onSubmit={handleSubmit}>
            { /*
            <div style={{position: 'relative', display: band ? 'inline-block' : 'none'}}>
                <ProfileImg fallback={''} size="medium" imgUrl={billingInfos && billingInfos.logo || ""}/>
                <UploadButton name={IconName.UPLOAD} size={IconSize.XL}/>
                <div id="upload_widget_opener"/>
            </div> */}
            <WhiteTextField
                name="bandAddress"
                type="text"
                label={errors.bandAddress ? errors.bandAddress : 'Band-Address'}
                error={Boolean(errors.bandAddress)}
                value={values.bandAddress}
                onChange={handleChange}
                fullWidth
                placeholder={"Samplestreet 11, 8181 Sampletown US"}
                required
            />
            <WhiteTextField
                name="customTitle"
                type="text"
                label={errors.customTitle ? errors.customTitle : 'Custom Title (leave blank for standard)'}
                error={Boolean(errors.customTitle)}
                value={values.customTitle}
                onChange={handleChange}
                fullWidth
            />
            <WhiteTextField
                name="letterContent"
                type="text"
                label={errors.letterContent ? errors.letterContent : 'Letter Text (leave blank for standard)'}
                error={Boolean(errors.letterContent)}
                value={values.letterContent}
                onChange={handleChange}
                fullWidth
                multiline
            />
            <WhiteTextField
                name="footerContent"
                type="text"
                label={errors.footerContent ? errors.footerContent : 'Footer Text (leave blank for standard)'}
                error={Boolean(errors.footerContent)}
                value={values.footerContent}
                onChange={handleChange}
                fullWidth
                multiline
            />
            <Spacer/>
            <WhiteTextField
                name="iban"
                type="text"
                label={errors.iban ? errors.iban : 'IBAN'}
                error={Boolean(errors.iban)}
                value={values.iban}
                placeholder={'IBAN'}
                onChange={handleChange}
                fullWidth
                required
            />
            <WhiteTextField
                name="accountHolder"
                type="text"
                label={errors.accountHolder ? errors.accountHolder : 'Account Holder'}
                error={Boolean(errors.accountHolder)}
                value={values.accountHolder}
                placeholder={'Account Holder'}
                onChange={handleChange}
                fullWidth
            />
            <WhiteTextField
                name="bank"
                type="text"
                label={errors.bank ? errors.bank : 'Bank'}
                error={Boolean(errors.bank)}
                value={values.bank}
                placeholder={'Bank'}
                onChange={handleChange}
                fullWidth
            />
            <WhiteTextField
                name="bic"
                type="text"
                label={errors.bic ? errors.bic : 'BIC'}
                error={Boolean(errors.bic)}
                value={values.bic}
                placeholder={'BIC'}
                onChange={handleChange}
                fullWidth
            />
            <Spacer/>
            <WhiteTextField
                name="website"
                type="text"
                label={errors.website ? errors.website : 'Band Website'}
                error={Boolean(errors.website)}
                value={values.website}
                onChange={handleChange}
                fullWidth
            />
            <BlueButton type="submit" disabled={isSubmitting} onClick={() => handleSubmit()}>Submit</BlueButton>
        </Form>)
};

const BillingForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: ({billingInfos}) => ({
        logo: billingInfos && billingInfos.logo || '',
        bandAddress: billingInfos && billingInfos.bandAddress || "",
        customTitle: billingInfos && billingInfos.customTitle || '',
        letterContent: billingInfos && billingInfos.letterContent || '',
        footerContent: billingInfos && billingInfos.footerContent || '',
        iban: billingInfos && billingInfos.iban || '',
        bank: billingInfos && billingInfos.bank || '',
        bic: billingInfos && billingInfos.bic || '',
        accountHolder: billingInfos && billingInfos.accountHolder || '',
        website: billingInfos && billingInfos.website || '',
    }),
    validationSchema: Yup.object().shape({
            bandAddress: Yup.string().required('This is a required field'),
            iban: Yup.string().required('This is a required field'),
            bank: Yup.string().required('This is a required field'),
            bic: Yup.string().required('This is a required field'),
            accountHolder: Yup.string().required('This is a required field')
        },
    ),
    handleSubmit: async (values, {props, setSubmitting}) => {
        setSubmitting(true);
        const billingInfosController = new BillingInfosController(props.band.id);
        if (!props.billingInfos && props.band) {
            const doc = await billingInfosController.create<BillingInfos>({
                idBand: props.band.id,
                logo: values.logo || "",
                bandAddress: values.bandAddress,
                customTitle: values.customTitle,
                letterContent: values.letterContent,
                footerContent: values.footerContent,
                iban: values.iban,
                accountHolder: values.accountHolder,
                bank: values.bank,
                bic: values.bic,
                website: values.website,
            });
        } else if (props.billingInfos && props.billingInfos.id && props.band && props.band.id) {
            await billingInfosController.update(props.billingInfos.id, new BillingInfos({
                id: props.billingInfos.id,
                idBand: props.band.id,
                logo: values.logo,
                bandAddress: values.bandAddress,
                customTitle: values.customTitle,
                letterContent: values.letterContent,
                footerContent: values.footerContent,
                iban: values.iban,
                accountHolder: values.accountHolder,
                bank: values.bank,
                bic: values.bic,
                website: values.website,
            }));
        }
        toast.success(`BillingInfos updated!`)
        setSubmitting(false);
    },
})(InnerForm);

export default BillingForm;