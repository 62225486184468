import {Collections} from "../models/basics/Collections";
import "firebase/compat/auth";
import {Controller} from "./controller";



export class CalendarEventController extends Controller{
    constructor(idParent: string) {
        super(Collections.CALENDAREVENTS, Collections.USERS, idParent);
    }
}