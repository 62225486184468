import { BandsStore } from './BandsStore';
import { createContext } from 'react';
import { EventsStore as EventsStore } from './EventsStore';
import { UserStore } from './UserStore';
import { NotificationsStore } from './NotificationsStore';
import {Store} from "./Store";
import {Customer} from "../models/customer";
import {Formation} from "../models/formation";
import {BillingInfos} from "../models/billingInfos";
import { BandAddress } from 'src/models/bandAddress';
import { BookingStatus } from 'src/models/bookingStatus';



export class RootStore {
    
    userStore = new UserStore(this);
    bandsStore = new BandsStore(this);
    eventsStore = new EventsStore(this);
    formationsStore = new Store<Formation>(this);
    billingInfosStore = new Store<BillingInfos>(this);
    customersStore = new Store<Customer>(this);
    bandAddressesStore = new Store<BandAddress>(this);
    notificationsStore = new NotificationsStore(this);
    bookingStatiStore = new Store<BookingStatus>(this);
}

export const RootStoreContext = createContext(new RootStore());