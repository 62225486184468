import * as React from 'react';
import { FC, useContext, useState } from 'react';
import styled from "styled-components";
import { Availability } from "../../../../../models/basics/availability";
import { toast } from "react-toastify";
import { NotificationController } from "../../../../../controllers/notificationController";
import { NotificationTypes } from "../../../../../models/basics/NotificationTypes";
import { GighubEvent } from "../../../../../models/gighubEvent";
import { EventController } from "../../../../../controllers/eventController";
import { Band } from "../../../../../models/band";
import { canWrite, MemberRoles } from "../../../../../models/basics/MemberRoles";
import { AvailabilityStati } from "../../../../../models/basics/AvailabilityStati";
import AvailabilityWrapper from "./AvailabilityWrapper";
import NewAvailabilityForm from "./NewAvailabilityForm";
import { gighubNotificationTexts } from "../../../../../models/basics/gighubNotificationTexts";
import AvailabilityPlaceholder from "./AvailabilityPlaceholder";
import { auth } from "../../../../../database/fbApp";
import { observer } from 'mobx-react-lite';
import { useToggle } from 'src/hooks/useToggle';
import { Dialog } from "@material-ui/core";
import AvailabilityColor from "../../../../_common/_elements/AvailabilityColor";
import Icon, { IconName } from "../../../../_common/_elements/Icon";
import { RootStoreContext } from "../../../../../store/RootStore";
import { UserController } from "../../../../../controllers/userController";
import { User } from 'src/models/user';

interface Props {
    event: GighubEvent;
    band: Band;
    teaser?: boolean | false;
    itinerary?: boolean | false;
}

const AvailabilitiesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  border-bottom: 1px solid ${props => props.theme.colors.secondary};
  margin-bottom: 1rem;
`;

const CircleContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
`;

const Availabilities: FC<Props> = observer((props) => {

    const { notificationsStore } = useContext(RootStoreContext);
    const [event, setEvent] = useState<GighubEvent>(props.event)
    const band = props.band
    const authRole = band.members[(auth.currentUser ? auth.currentUser.uid : "")].role
    const { on, toggle } = useToggle();
    const nonInvitedMembers: any = {};
    Object.keys(band.members).forEach(idUser => {
        if (!event.availabilities || !event.availabilities![idUser]) nonInvitedMembers[idUser] = band.members[idUser]
    })

    async function handleSubmitAvailabilityForm(a: Availability) {
        let notify = false;
        const currentUser = (window as any).gighub.CurrentUser;
        if (band && event && event.id && event.availabilities && currentUser.id && a) {
            try {
                if (event.availabilities[currentUser.id].status === AvailabilityStati.INVITED
                    && (a.status === AvailabilityStati.ACCEPTED
                        || a.status === AvailabilityStati.DECLINED) || (event.availabilities[currentUser.id].status === AvailabilityStati.DECLINED
                            && (a.status === AvailabilityStati.ACCEPTED
                                || a.status === AvailabilityStati.PENDING))) notify = true;
                event.availabilities[currentUser.id] = a;
                await new EventController(event.idBand).update(event.id, await new EventController(event.idBand).updateBandStatus(event));
                if (notify) {
                    const notification = notificationsStore.getEventInvite(currentUser.id, event.id);
                    if (notification && notification[0]) {
                        await new NotificationController().notify({ id: notification[0].idSender } as Partial<User>,
                            currentUser.fullName
                            + gighubNotificationTexts.USERACCEPTEDEVENTINVITE
                            + event.title,
                            NotificationTypes.OTHER, event.id, band);
                    }
                }

                await new UserController().handleAvailabilitySubmit(a.status || AvailabilityStati.INVITED, currentUser.id, event.id, band, event, notify)

                setEvent(event)
                toast.success("updated availability");
            } catch (e) {
                console.log(e)
            }
        }
    }

    async function handleSubmitInvites(users: string[], message: string) {
        if (band && users && event) {
            try {
                await Promise.all(users.map(async (idUser) => {
                    if (event && event.id && auth.currentUser && idUser === auth.currentUser.uid) {
                        await new EventController(band.id).updateAvailability(event, idUser, band)
                    } else {
                        await new EventController(event.idBand).sendInvitation(band, event, idUser, message)
                    }
                })
                );
                toast.success("Invitations have been sent");
            } catch (e) {
                toast.error("An Error occurred while sending invitations");
                console.log(e)
            }
        }
    }

    async function handleDelete(idUser: string) {
        if (event && event.availabilities && event.id && idUser) {
            try {
                if (event.availabilities[idUser].status === AvailabilityStati.INVITED) {
                    const notification = await new NotificationController().getEventInvite(idUser, event.id)
                    if (notification) await new NotificationController().delete(notification.id)
                }
                delete event.availabilities[idUser];
                await new EventController(event.idBand).update(event.id, await new EventController(event.idBand).updateBandStatus(event));
                await new UserController().handleAvailabilitySubmit(AvailabilityStati.DECLINED, idUser, event.id, band)
                setEvent(event)
                toast.success("Removed user from this event");
            } catch (e) {
                console.log(e)
            }
        }
    }


    function renderAvailabilities(crewOnly?: boolean) {
        const { availabilities } = event;
        const { members } = band;
        if (availabilities && Object.keys(availabilities).length > 0) {
            return Object.keys(availabilities).map((idUser) => {
                if (crewOnly) {
                    if (members && members[idUser] && members[idUser].role !== MemberRoles.CREW) return null;
                } else {
                    if (members && members[idUser] && members[idUser].role === MemberRoles.CREW) return null;
                }
                return (
                    members[idUser] ?
                        <AvailabilityWrapper
                            teaser={props.teaser}
                            key={idUser}
                            member={band.members[idUser]}
                            idUser={idUser}
                            authRole={auth.currentUser && auth.currentUser.uid ? members[auth.currentUser.uid].role : MemberRoles.NOT_AUTHORIZED}
                            imageUrl={members[idUser].imageUrl || ""}
                            availability={availabilities[idUser]}
                            handleSubmit={handleSubmitAvailabilityForm}
                            handleDelete={handleDelete}
                        /> :
                        <></>
                )
            })
        }
        return ""
    }

    function renderAvailabilitiesItinerary() {
        const { availabilities } = event;
        const { members } = band;
        let availabilitiesString = ""
        if (availabilities && Object.keys(availabilities).length > 0) {
            Object.keys(availabilities).map((idUser) => {
                if (members[idUser] && availabilities[idUser].status === AvailabilityStati.ACCEPTED) {
                    availabilitiesString += (members[idUser].fullName + ", ")
                }
            })
        }
        return availabilitiesString;
    }

    function hasCrew() {
        let withCrew = false;
        if (event && event.availabilities) {
            Object.keys(event.availabilities).forEach(idUser => {
                if (band && band.members && band.members[idUser] && band.members[idUser].role === MemberRoles.CREW) withCrew = true
            })
        }
        return withCrew
    }
    if (!props.itinerary) {
        return (
            <AvailabilitiesContainer>
                <CircleContainer>
                    {renderAvailabilities()}
                    {!props.teaser && canWrite(authRole) && Object.keys(nonInvitedMembers).length > 0 &&
                        <>
                            <div onClick={toggle}>
                                <AvailabilityColor status={AvailabilityStati.INVITED}>
                                    <Icon name={IconName.ADD} />
                                </AvailabilityColor>
                            </div>
                            {on &&
                                <Dialog open={on} onClose={toggle}>
                                    <NewAvailabilityForm
                                        handleSubmit={handleSubmitInvites}
                                        members={nonInvitedMembers}
                                        toggle={toggle} />
                                </Dialog>
                            }
                        </>
                    }
                </CircleContainer>
                {hasCrew() &&
                    <>
                        <h3>Crew:</h3>
                        <CircleContainer>
                            {renderAvailabilities(true)}
                        </CircleContainer>
                    </>
                }
            </AvailabilitiesContainer>
        )
    } else {
        return (
            <>
                <br />
                <h3>Travel Party:</h3>
                <AvailabilitiesContainer>
                    {renderAvailabilitiesItinerary()}
                </AvailabilitiesContainer>
                <br />
            </>
        )
    }
})


export default Availabilities;