import React, { FC } from 'react';
import { useToggle } from 'src/hooks/useToggle';
import Fab from 'src/views/_common/_elements/Fab';
import styled from 'styled-components';
import {toast} from "react-toastify";
import { Dialog } from '@material-ui/core';
import { BookingStatus } from 'src/models/bookingStatus';
import BookingStatusWrapper from './BookingStatusWrapper';
import BookingStatusForm from './BookingStatusForm';
import { BookingStatusController } from 'src/controllers/bookingStatusController';


interface Props {
    idBand: string;
    bookingStati: BookingStatus[]
}

const Container = styled.div`
`;

const BookingStatusSettings: FC<Props> = ({ bookingStati, idBand }) => {
    async function handleDelete(id: string) {
        if (id && idBand) {
            try {
                await new BookingStatusController(idBand).delete(id);
                toast.info("Deleted Booking Status")
            } catch (e) {
                console.log(e)
            }
        }
    }

    const { on, toggle } = useToggle();
    return (
        <Container>
            {bookingStati.map(bookingStatus => <BookingStatusWrapper
                key={bookingStatus.id} idBand={idBand}
                bookingStatus={bookingStatus} handleDelete={handleDelete} />)}
            <Dialog open={on} onClose={toggle}>
                <BookingStatusForm toggle={toggle} isNew={true} idBand={idBand} />
            </Dialog>
            <Fab type={'ADD'} onClick={toggle} />
        </Container>
    )
}

export default BookingStatusSettings;