import * as React from 'react';
import { FC, useContext, useEffect, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { PacmanLoader } from 'react-spinners';
import { AppTheme } from '../../../theme';
import NotificationComponent from './NotificationComponent';
import { NotificationTypes } from '../../../models/basics/NotificationTypes';
import { GighubNotification } from '../../../models/gighubNotification';
import { EventController } from '../../../controllers/eventController';
import { toast } from 'react-toastify';
import Page from '../../_common/page/Page';
import { AvailabilityStati } from '../../../models/basics/AvailabilityStati';
import { BandController } from '../../../controllers/bandController';
import { RootStoreContext } from 'src/store/RootStore';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from '@reach/router';
import { NotificationController } from "../../../controllers/notificationController";
import { compareAsc } from "date-fns";
import { gighubNotificationTexts } from 'src/models/basics/gighubNotificationTexts';

interface Props {
    theme: AppTheme;
    path: string;
}

const Container = styled.div`
  overflow-y: auto;
  margin-top: 1rem;
  max-height: 80vh;
  padding: 0 1rem;
`;

const NoNotifications = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: 10px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  display: grid;
  place-items: center;
  color: ${props => props.theme.colors.black};
  padding: 2rem 0.8rem;
`;

const Notifications: FC<Props & RouteComponentProps> = observer(({ theme, navigate }) => {
    const {
        notificationsStore: { observables: notifications },
        eventsStore: { observables: events },
        bandsStore: { getById },
        userStore: { getById: getUserById },
    } = useContext(RootStoreContext);
    const [idBand, setIdBand] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const pathname = window.location.href;
        localStorage.setItem('pageUrl', JSON.stringify(pathname))
    }, []);


    async function handleInviteAccept(notification: GighubNotification) {
        if (notification && notification.id && notification.idReference) {
            if (notification.type === NotificationTypes.BANDINVITATION) {
                await new BandController().swapInviteToMember(notification.idReference, notification.id).then(async () => {
                    await new NotificationController().markAsSeen(notification)
                    toast.success('Hooray! You have joined a new Band');
                }).catch(async (error) => { console.log(error) });
            } else if (notification.type === NotificationTypes.EVENTINVITATION) {
                await new EventController(notification.idBand).handleInvite(notification, AvailabilityStati.ACCEPTED);
                toast.success('Yeah! A new gig');
            }
        }
    }

    async function handleInviteDecline(notification: GighubNotification) {
        if (notification && notification.id && notification.idReference) {
            if (notification.type === NotificationTypes.EVENTINVITATION) {
                await new EventController(notification.idBand).handleInvite(notification, AvailabilityStati.DECLINED);
                await new NotificationController().markAsSeen(notification)
            }
        }
        toast.success("Ok! :'(");
    }

    function renderNotifications() {
        if (loading) {
            return <PacmanLoader size={40} color={theme.colors.primary} loading={loading} />;
        } else {
            if (notifications.length > 0) {
                let imageUrl
                const notificationsNew = notifications.filter((notification) => {
                    return !notification.message.includes(gighubNotificationTexts.USERACCEPTEDEVENTINVITE);
                });
                return notificationsNew
                    .sort((a, b) => compareAsc(a.dateCreated, b.dateCreated))
                    .sort((a, b) => (a.type === NotificationTypes.EVENTINVITATION && b.type !== NotificationTypes.EVENTINVITATION) ? -1 : ((a.type !== NotificationTypes.EVENTINVITATION && b.type === NotificationTypes.EVENTINVITATION) ? 1 : 0))
                    .map(notification => {
                        imageUrl = ""
                        const band = getById(notification.idBand).get();
                        if (notification.type === NotificationTypes.YOUJOINEDBAND || notification.type === NotificationTypes.BANDRELATED) {
                            if (band && band.imageUrl) {
                                imageUrl = band.imageUrl
                            }
                        } else {
                            if (band && band.members && band.members[notification.idSender]) {
                                imageUrl = band.members[notification.idSender].imageUrl
                            }
                        }
                        return <NotificationComponent
                            key={notification.id}
                            notification={notification}
                            bandInviteModalOpen={
                                idBand === notification.idReference && notification.type === NotificationTypes.BANDINVITATION
                            }
                            handleInviteAccept={handleInviteAccept}
                            handleInviteDecline={handleInviteDecline}
                            imageUrl={imageUrl || ""}
                            event={
                                notification.type === NotificationTypes.EVENTINVITATION
                                    ? events[events.findIndex(e => e.id === notification.idReference)]
                                    : null
                            }
                        />
                    });
            } else {
                return <NoNotifications>You have no new notifications</NoNotifications>;
            }
        }
    }

    return (
        <>
            {navigate && (
                <Page navigate={navigate}>
                    <Container>{renderNotifications()}</Container>
                </Page>
            )}
        </>
    );
});

export default withTheme(Notifications);
