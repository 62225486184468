import { useEffect, useState, useContext } from "react";
import { RootStoreContext } from 'src/store/RootStore';


const useCloudinaryImage = (currentImageUrl: string | undefined | null, succesHandler?: ( newImageUrl: string) => void, errorHandler?: (error: any) => void) => {
    const [imageUrl, setImageUrl] = useState('');
    const { userStore: { currentUser }} = useContext(RootStoreContext);
    useEffect(() => {
        if (currentUser && currentUser.imageUrl) {
            setImageUrl(currentImageUrl || '');
        }
        (window as any).cloudinary.applyUploadWidget(
            '#upload_widget_opener',
            {
                cloudName: 'bassplayerch',
                uploadPreset: 'vi80oj9l',
                cropping: true,
                multiple: false,
                maxFileSize: 3500000,
                autoMinimize: true,
                folder: 'gighub',
            },
            (error: any, result: any) => {
                if (error) {
                    if (errorHandler){
                        errorHandler(error);
                    }
                    return;
                }
                if (result.event === 'success') {
                    const imageId = result.info.public_id;
                    const newImageUrl =
                        'https://res.cloudinary.com/bassplayerch/image/upload/c_crop,c_fill,f_auto,g_face,h_100,q_auto,w_100/' + imageId;
                    setImageUrl(newImageUrl);
                    if (succesHandler){
                        succesHandler(newImageUrl);
                    }
                }
            }
        );
    }, []);

    return imageUrl;
}

export default useCloudinaryImage;