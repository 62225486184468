import React, { FC, useContext, useEffect, useState } from 'react';
import EventWrapper from '../../events/EventWrapper';
import { GighubEvent } from 'src/models/gighubEvent';
import { compareAsc, isFuture } from 'date-fns';
import styled from 'styled-components';
import { RootStoreContext } from 'src/store/RootStore';
import { observer } from 'mobx-react-lite';

const Container = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - ${props => props.theme.layout.footerHeight} - ${props => props.theme.layout.headerHeight} - 22rem );
`;

const NoEvents = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: 10px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  display: grid;
  place-items: center;
  color: ${props => props.theme.colors.black};
  padding: 2rem 0.8rem;
`;

const UpcomingEvents: FC = observer(() => {
  const {
    userStore: { currentUser },
    eventsStore: { observables: events, getFutureEvents },
    bandsStore: { getById: getBandById },
    formationsStore: { getById: getFormationById },
  } = useContext(RootStoreContext);

  return (
    <Container>
      {getFutureEvents().get().length === 0 && <NoEvents>No upcoming events</NoEvents>}
      {currentUser &&
        events
          .slice()
          .filter(e => isFuture(e.dateStart))
          .filter(e => e.availabilities && e.availabilities[currentUser.id!])
          .sort((a: GighubEvent, b: GighubEvent) => compareAsc(a.dateEnd, b.dateEnd))
          .map((e: GighubEvent) => (
            <EventWrapper
              key={e.id}
              event={e}
              band={getBandById(e.idBand).get()!}
              formation={(e.idFormation && getFormationById(e.idFormation).get()) || null}
            />
          ))}
    </Container>
  );
});

export default UpcomingEvents;
