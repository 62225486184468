import * as React from 'react';
import {FC, useContext, useEffect, useState} from 'react';
import EventForm from './EventForm';
import {toast} from 'react-toastify';
import {navigate, RouteComponentProps} from '@reach/router';
import {EventController} from '../../../../controllers/eventController';
import {GighubEvent} from '../../../../models/gighubEvent';
import {auth} from '../../../../database/fbApp';
import {Links, Routes} from '../../../../constants/Routes';
import {Customer} from '../../../../models/customer';
import {RootStoreContext} from 'src/store/RootStore';
import {observer} from 'mobx-react-lite';
import Icon, {IconName} from '../../../_common/_elements/Icon';
import styled from 'styled-components';
import {autorun} from 'mobx';
import {Formation} from '../../../../models/formation';
import {Band} from 'src/models/band';
import {NotificationController} from '../../../../controllers/notificationController';
import {MAX_FORMATIONS} from 'src/constants/FreePlan';
import {RingLoader} from 'react-spinners';
import {theme} from 'src/theme';
import {truncate} from "../../../../utils/stringUtils";
import Page from "../../../_common/page/Page";
import useIsDesktop from "../../../../hooks/useIsDesktop";

interface Props {
    path: string;
}

const FormPage = styled(Page)`
  background: ${props => props.theme.colors.secondary};
`;

const TopBar = styled.div`
  height: ${props => props.theme.layout.headerHeight};
  display: flex;
  align-items: center;
  padding: 0 1rem;
  justify-content: space-between;
  max-width: ${props => props.theme.breakpoints.desktop}px;
  margin: 0 auto;
`;

const Container = styled.div`
  background: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.settingsWhite};
  height: 100%;
  width: 100%;
`;

const BackIcon = styled(Icon)`
  cursor: pointer;
`;

const TopBarTitle = styled.h3`
  color: ${props => props.theme.colors.primary};
`;

const EventFormPage: FC<Props & RouteComponentProps<{ id: string; idBand: string }>> = observer(props => {
    const {
        bandsStore: {
            observables: bands,
            getById: getBandById,
            selectedBand,
            setSelectedBandFromLocalStorage,
        },
        formationsStore: {getById: getFormationById, getByBand: getFormationsByBand},
        customersStore: {getById: getCustomerById, getByBand: getCustomersByBand},
        eventsStore: {getById: getEventById}
    } = useContext(RootStoreContext);
    const [state, setState] = useState<{
        event: GighubEvent | null;
        customer: Customer | null;
        formation: Formation | null;
        band: Band | null;
    }>({
        event: null,
        customer: null,
        formation: null,
        band: null
    });


    useEffect(() => {
        autorun(() => {
            checkUrl();
        });
    }, []);

    function checkUrl() {
        const idEvent = props.id;
        const idBand = props.idBand;
        if (idEvent && idBand) {
            const urlEvent = getEventById(idEvent).get();
            const urlBand = getBandById(idBand).get();
            let urlCustomer;
            let urlFormation;
            if (urlEvent && urlEvent.id) {
                if (urlEvent.idCustomer) {
                    urlCustomer = getCustomerById(urlEvent.idCustomer).get();
                }
                if (urlEvent.idFormation) {
                    urlFormation = getFormationById(urlEvent.idFormation).get();
                }
            } else {
                console.log("no idEvent and idBand")
            }
            setState({
                event: urlEvent || null,
                customer: urlCustomer || null,
                formation: urlFormation || null,
                band: urlBand || null
            });
        }
    }

    async function handleSubmit(e: GighubEvent, autoInviteFormation: boolean) {
        try {
            if (state.event) {
                const id = state.event && state.event.id;
                if (id && state.event.idBand) {
                    e = new EventController(state.event.idBand).updateBandStatus(e)
                    e.idEditor = auth.currentUser ? auth.currentUser.uid : '';
                    e.dateEdited = new Date();
                    await new EventController(state.event.idBand).update(id, e);
                    if (state.band && state.event && e.availabilities) {
                        e.id = state.event.id
                        await new EventController(state.event.idBand).updateCalendarEvents(e, state.band);
                    }
                    toast.success('updated event');
                }
            } else {
                const {id: newId} = await new EventController(e.idBand).create(e);
                toast.success('You have created a new event!');
                if (autoInviteFormation) {
                    e.id = newId;
                    await new EventController(e.idBand).autoInviteFormation(e);
                }
            }
            navigate(Links.EVENT + e.id);
        } catch (e) {
            console.log(e);
        }
    }

    async function deleteEvent() {
        const event = state.event
        if (event && event.id && event.idBand) {
            await new EventController(event.idBand).delete(event.id);
            // delete calendar events:
            if (state.band) await new EventController(event.idBand).updateCalendarEvents(event, state.band, true);
            await new NotificationController().deleteByIdReference(event.id);
            navigate(Links.EVENTS);
            toast.info("Aaaaand it's gone");
        }
    }

    function canAddFormation() {
        if (!selectedBand) {
            return false;
        }
        return (
            selectedBand.subscriptionType === 'premium' || getFormationsByBand(selectedBand.id).get().length < MAX_FORMATIONS
        );
    }

    function renderForm() {
        if (!state.event && bands.length > 0 && selectedBand) {
            return (
                <EventForm
                    formations={getFormationsByBand(selectedBand.id).get()}
                    customers={getCustomersByBand(selectedBand.id).get()}
                    handleSubmit={handleSubmit}
                    band={selectedBand}
                    canAddFormation={canAddFormation()}
                />
            );
        } else if (state.band && state.event && selectedBand) {
            return (
                <EventForm
                    formations={getFormationsByBand(selectedBand.id).get()}
                    customers={getCustomersByBand(selectedBand.id).get()}
                    handleSubmit={handleSubmit}
                    band={state.band}
                    event={state.event}
                    handleDelete={deleteEvent}
                    canAddFormation={canAddFormation()}
                    customerName={state.customer ? state.customer.name : ''}
                />
            );
        } else {
            setSelectedBandFromLocalStorage();
            return (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh'}}>
                    <RingLoader size={30} color={theme.colors.black}/>
                </div>
            );
        }
    }

    const isDesktop = useIsDesktop();
    if (isDesktop) {
        return (
            <>
                {navigate && (
                    <FormPage navigate={navigate}>
                        <Container>
                            <TopBar>
                                <BackIcon onClick={() => navigate(Routes.EVENTS)} name={IconName.ARROW_LEFT}/>
                                {state.event ? (
                                    <TopBarTitle>
                                        {state.band && truncate(state.band.shorthand + ": " + state.event.title, 30)}
                                    </TopBarTitle>
                                ) : (
                                    <TopBarTitle>{selectedBand && selectedBand.shorthand}: New Event</TopBarTitle>
                                )}
                            </TopBar>
                            {renderForm()}
                        </Container>
                    </FormPage>)}
            </>
        )
    } else {
        return (
            <Container>
                <TopBar>
                    <BackIcon onClick={() => navigate(Routes.EVENTS)} name={IconName.ARROW_LEFT}/>
                    {state.event ? (
                        <TopBarTitle>
                            {state.band && truncate(state.band.shorthand + ": " + state.event.title, 30)}
                        </TopBarTitle>
                    ) : (
                        <TopBarTitle>{selectedBand && selectedBand.shorthand}: New Event</TopBarTitle>
                    )}
                </TopBar>
                {renderForm()}
            </Container>
        )
    }
});

export default EventFormPage;
