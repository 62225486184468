import { Model } from './model';
import { Collections } from './basics/Collections';
import { auth } from '../database/fbApp';
import { MemberRoles } from './basics/MemberRoles';
import { Member } from './basics/member';

export type SubscriptionType = 'free' | 'premium'

interface MemberArray {
  [index: string]: Member;
}

export class Band implements Model {
  collection?: Collections;
  id: string;
  name: string;
  shorthand: string;
  members: MemberArray;
  costPerKilometer: number;
  currency: string;
  showDrivingExpenses: boolean;
  invites?: any;
  imageUrl?: string;
  stripeId?: string;
  canceledSubscription: boolean;
  subscriptionType: SubscriptionType;
  primaryColor?: string;
  secondaryColor?: string;
  customStati?: string[];


  constructor(o: Band , newBand: boolean = false) {
    this.collection = Collections.BANDS;
    this.id = o.id || '';
    this.name = o.name;
    this.shorthand = o.shorthand;
    this.imageUrl = o.imageUrl;
    this.stripeId = o.stripeId;
    this.subscriptionType = o.subscriptionType;
    this.canceledSubscription = o.canceledSubscription;
    this.primaryColor = o.primaryColor || 'white';
    this.secondaryColor = o.secondaryColor || 'black';
    this.customStati = o.customStati || [];
    this.costPerKilometer = o.costPerKilometer || 0.6;
    this.showDrivingExpenses = o.showDrivingExpenses || false;
    this.currency = o.currency || "CHF"
    if (newBand) {
      this.members = { [auth.currentUser ? auth.currentUser.uid : '']: new Member({ role: MemberRoles.ADMIN }) };
    } else if (Object.keys(o.members).length) {
      this.members = o.members;
      this.invites = o.invites;
    }
    if (!newBand) {
      this.invites = o.invites;
    }
  }
}
