import { Controller } from "./controller";
import { Collections } from "../models/basics/Collections";
import { auth, database as db } from "../database/fbApp";
import { GighubNotification } from "../models/gighubNotification";
import { UserController } from "./userController";
import { User } from "../models/user";
import { NotificationTypes } from "../models/basics/NotificationTypes";
import { Band } from "../models/band";
import { Links } from "../constants/Routes";
import { Model } from "../models/model";
import { toast } from "react-toastify";
import { query, where, orderBy, getDocs } from "firebase/firestore";


export class NotificationController extends Controller {
    collection = Collections.NOTIFICATIONS;

    constructor() {
        super(Collections.NOTIFICATIONS)
    }

    async subscribeByAuthUser() {
        return await this.getWithWhereQ("idRecipent" as keyof Model, auth.currentUser ? auth.currentUser.uid : "", orderBy("dateCreated", "desc"))
    }

    async subscribeByAuthUserEmail() {
        return await this.getWithWhereQ("idRecipent" as keyof Model, auth.currentUser && auth.currentUser.email ? auth.currentUser.email : "", orderBy("dateCreated", "desc"))
    }

    async sendPushNotification(notification: GighubNotification, fcmTokens: string[], band: Band) {
        let clickAction = "https://app.gighub.ch/";
        // let title;
        switch (notification.type) {
            case NotificationTypes.BANDINVITATION:
                clickAction += Links.ANSWER_BAND_INVITATION + band
        }
        const body = {
            registration_ids: fcmTokens,
            notification: {
                body: notification.message,
                title: band.name,
                lang: 'en',
                icon: 'favicon-512x512.png',
                vibrate: [500, 100, 500],
                click_action: "https://gighub.ch/notifications"
            }
        }
        await (await fetch("https://fcm.googleapis.com/fcm/send", {
            body: JSON.stringify(body),
            headers: {
                "Authorization": "key=AAAAZPjrHvU:APA91bFY3355J-kt_fBLDpgOB6gLEH9zN24cIXQraGTbBufrsUOlIEq-oIuF0RNKtOsj3DMR1uMQX-tTmu5iXW9MoDvjkLkrkTO9_nV16EOFGbybeq-l0DDsEo7H5gXwtGEMFSXDENux",
                "Content-Type": "application/json"
            },
            method: "POST"
        })).json().catch(e => console.log(e));
    };

    async notifyUserByEmail(email: string, message: string, type: NotificationTypes, modelId: string, band: Band) {
        // TODO: Wenn user nicht registriert dann Email senden
        const user = await new UserController().getFirstByField<User>("email", email);
        if (user) this.notify(user, message, type, modelId, band);
    }

    async notify(user: Partial<User>, message: string, type: NotificationTypes, modelId: string, band?: Band) {
        if (user && user.id) {
            this.create(new GighubNotification({
                idRecipent: user.id
                , message, type, idReference: modelId, idBand: band && band.id || modelId
            }));
            if (user.fcmTokens && band) {
                this.sendPushNotification(new GighubNotification({
                    idRecipent: user.id,
                    message,
                    type
                }), user.fcmTokens, band);
            } else {
                // toast.info("User will see no notification outside of Gighub because he/she has not allowed notifications");
            }
        } else {
            toast.error("Notification could not be sent");
            console.log("Notification could not be sent");
        }
    }

    async getEventInvite(idRecipent: string, idEvent: string) {
        try {
            let q = query(this.subscribe())

            // filters selected by users
            q = query(q, where("idRecipent", "==", idRecipent))
            q = query(q, where("idReference", "==", idEvent))
            q = query(q, where("type", "==", NotificationTypes.EVENTINVITATION))

            const res = await getDocs(q);
            if (res.docs.length > 0) {
                const d = res.docs[0].data() as GighubNotification;
                d.id = res.docs[0].id;
                return d;
            }
        } catch (e) {
            console.log(e);
        }
        return null;
    }

    async deleteByIdReference(idReference: string) {
        const querySnapshot = await this.getWithWhere("idReference" as any, idReference);
        await querySnapshot.forEach(async (doc) => {
            await new NotificationController().delete(doc.id)
            console.log("deleted Notification")
        });
    }


    async markAsSeen(notification: GighubNotification) {
        notification.isSeen = true;
        return this.update<GighubNotification>(notification.id, notification)
    }

}
