import styled from "styled-components";
import {Dialog} from "@material-ui/core";


export const GigHubDialog = styled(Dialog)`
  .rbc-event {
    background: ${props => props.theme.colors.primary};
    padding: 4rem;
  }
`;

