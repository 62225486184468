import React, { FC, useContext, useState, useEffect } from 'react';
import { RootStoreContext } from 'src/store/RootStore';
import { Band } from 'src/models/band';
import Select from 'react-select';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';

interface OptionType {
  label: string;
  value: string;
}

const BandSelect: FC = observer(() => {
  const {
    bandsStore: { observables: bands, selectedBand, setSelectedBand, setSelectedBandFromLocalStorage },
  } = useContext(RootStoreContext);

  const [selectedBandInDropdown, setSelectedBandInDropdown] = useState<OptionType | null>(null);
  useEffect(() => {
    if (selectedBand) {
      setSelectedBandInDropdown(mapBandToOption(selectedBand));
    }
  }, [selectedBand]);

  function mapBandToOption(band: Band): OptionType {
    return { value: band.id, label: band.name };
  }

  function handleBandSelect({ value }: OptionType) {
    if (setSelectedBand) {
      setSelectedBandInDropdown(mapBandToOption(bands.find(b => b.id === value)!));
      setSelectedBand(bands.find(b => b.id === value)!.id);
    }
  }
  const customStyles = {
    container: (provided: any, state: any) => {
      const display = 'block';

      return { ...provided, display };
    },
  };

  return (
    <Select
      styles={customStyles}
      placeholder="Select a band"
      options={bands.map(mapBandToOption)}
      defaultValue={selectedBand ? mapBandToOption(selectedBand) : null}
      value={selectedBandInDropdown}
      onChange={handleBandSelect}
    />
  );
});

export default BandSelect;
