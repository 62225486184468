import * as React from 'react';
import { AuthContainer } from '../_elements/AuthContainer';
import styled from 'styled-components';
import { SecondaryButton, TopNav, TextInput } from '../../_common/_elements';
import { auth } from '../../../database/fbApp';
import * as isemail from 'isemail';
import { toast } from 'react-toastify';
import { RouteComponentProps, NavigateFn } from '@reach/router';
import { Routes } from 'src/constants/Routes';
import { sendPasswordResetEmail } from "firebase/auth";

interface ForgotPasswordPageProps {
  path: string;
}

interface ForgotPasswordPageState {
  email: string;
  isValid: boolean;
  touched: boolean;
}

const FormContainer = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Form = styled.form`
  width: 80%;
  height: 100%;
  margin: 5rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > .btn {
    margin-top: 2rem;
  }
`;

class ForgotPasswordPage extends React.Component<
  ForgotPasswordPageProps & RouteComponentProps,
  ForgotPasswordPageState
> {
  state = {
    email: '',
    isValid: false,
    touched: false
  };

  handleBlur = (): void => {
    this.setState({ touched: true });
  };

  handleBackClick = () => {
   this.props.navigate!(Routes.HOME);
  };

  handleSubmit = (e: any): void => {
    e.preventDefault();
    const { email } = this.state;
    // todo handle error and success
    if (isemail.validate(email)) {
      sendPasswordResetEmail(auth, this.state.email).then(() => {
        // Update successful.
      }).catch(() => {
        toast.error("Please try again")
      });
    }
  };

  handleChange = (e: any) => {
    const newValue = {};
    newValue[e.target.name] = e.target.value;
    this.setState(newValue);
    if (isemail.validate(e.target.value)) {
      this.setState({ isValid: true });
    } else {
      this.setState({ isValid: false });
    }
  };

  render() {
    const { touched, email, isValid } = this.state;
    return (
      <AuthContainer>
        <TopNav
          testId="forgot_password_backbutton"
          clickHandler={this.handleBackClick}
          text={'Forgot Password'}
        />
        <FormContainer>
          <Form onSubmit={this.handleSubmit}>
            <TextInput
              label={
                touched && !isValid ? 'This is not a valid Email' : 'email'
              }
              type="email"
              name="email"
              onInput={this.handleChange} // fixes autocomplete bug
              onBlur={this.handleBlur}
              error={Boolean(touched && !isValid)}
              required={true}
              value={email}
              fullWidth={true}
            />
            <SecondaryButton className="btn" type="submit" disabled={!isValid}>
              {'Reset Password'}
            </SecondaryButton>
          </Form>
        </FormContainer>
      </AuthContainer>
    );
  }
}

export default ForgotPasswordPage;
