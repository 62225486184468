import React, { FC, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from 'src/store/RootStore';
import { GighubEvent } from "../../../models/gighubEvent";
import styled from "styled-components";
import { Links } from "../../../constants/Routes";
import { navigate } from "@reach/router";
import rgba from "polished/lib/color/rgba";
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import InformationPage from "../detail_event/tabs/InformationPage";
import AvatarImage from "../../_common/_elements/AvatarImage";
import { canWrite } from "../../../models/basics/MemberRoles";
import { currentUser } from "../../../database/fbApp";

interface Props {
    event?: GighubEvent
    idEvent?: string;
    notification?: boolean;
}

const EventInfoContainer = styled.div`
  width: 95%;
  `;

const TopInfoContainer = styled.div`
  width: 100%;
  height: 110px;
  background-image: linear-gradient(150.91deg, 
        ${props => rgba(props.theme.colors.primary, .9)} -1.26%, 
        ${props => rgba(props.theme.colors.primary, .9)} 45.89%, 
        ${props => rgba(props.theme.colors.primaryLight, .9)} 100.63%), 
        url("https://res.cloudinary.com/bassplayerch/image/upload/c_scale,f_auto,h_1545,q_auto/v1530704427/gighub/ron-smith-328524-unsplash.jpg");
    background-size: cover;  
    background-position-y: 53%;
    color: ${props => props.theme.colors.white};
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    padding: 10px;
`;

const EventTitle = styled.div`
  font-size: 1.8rem;
`;

const EventInfoComponent: FC<Props> = observer(props => {

    const { eventsStore: { getById }, bandsStore } = useContext(RootStoreContext);
    let event = null;
    let band = null
    if (!props.event && props.idEvent) {
        event = getById(props.idEvent).get()
    } else {
        event = props.event
    }
    if (event) {
        band = bandsStore.getById(event.idBand).get()
    }
    if (event && band) {
        const { id, idBand, title } = event
        return (
            <>
                <TopInfoContainer>
                    <AvatarImage imgUrl={band.imageUrl} fallback={band.shorthand} size="medium" />
                    <EventTitle>{title}</EventTitle>
                </TopInfoContainer>
                <EventInfoContainer>
                    <DialogContent>
                        <InformationPage teaser={true} event={event} band={band} formation={null} customer={null} billingInfos={null} />
                    </DialogContent>
                </EventInfoContainer>
                <DialogActions>
                    <Button onClick={() => navigate(Links.EVENTS + id)} color="primary">
                        details
                    </Button>
                    {!props.notification && canWrite(band.members[(window as any).gighub.CurrentUser.id].role) &&
                        <Button onClick={() => navigate(Links.EVENT_FORM_PAGE + idBand + '/' + id)} color="primary">
                            edit
                        </Button>
                    }
                </DialogActions>
            </>

        )
    } else {
        return <></>
    }
});


export default EventInfoComponent;