import { useEffect, useState, useContext } from "react";
import { RootStoreContext } from 'src/store/RootStore';

interface UploadOptions {
  widgetOpenerSelector: string;
  uploadSettings: {
    sources?: string[];
    cropping?: boolean;
    multiple?: boolean;
    maxFileSize?: number;
    allowedFormats?: string[];
    folder?: string;
  };
}

const useFileUploader = (
  options: UploadOptions,
  successHandler?: (newFile: { url: string; public_id: string }) => void,
  errorHandler?: (error: any) => void
) => {
  const { userStore: { currentUser } } = useContext(RootStoreContext);
  const [uploadedFiles, setUploadedFiles] = useState<Array<{ url: string; public_id: string }>>([]);

  useEffect(() => {
    if (currentUser) {
      const widget = (window as any).cloudinary.createUploadWidget({
        cloudName: 'bassplayerch',
        uploadPreset: 'vi80oj9l',
        ...options.uploadSettings,
      }, (error: any, result: any) => {
        if (error) {
          if (errorHandler) {
            errorHandler(error);
          }
          return;
        }
        if (result.event === 'success') {
          const newFile = {
            name: result.info.original_filename,
            url: result.info.secure_url,
            public_id: result.info.public_id,
          };
          setUploadedFiles(prevUploadedFiles => [...prevUploadedFiles, newFile]);
          if (successHandler) {
            successHandler(newFile);
          }
        }
      });
// @ts-ignore
      document.querySelector(options.widgetOpenerSelector).addEventListener('click', () => {
        widget.open();
      });
    }
  }, [currentUser, errorHandler, options.widgetOpenerSelector, options.uploadSettings, successHandler]);

  return [uploadedFiles, setUploadedFiles];
};

export default useFileUploader;