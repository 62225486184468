import { InjectedFormikProps, withFormik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';
import { BlueButton } from '../../../../_common/_elements';
import { toast } from 'react-toastify';
import { BandAddressController } from 'src/controllers/bandAddressController';
import { BandAddress } from 'src/models/bandAddress';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import LocationSearchInput from 'src/views/_common/_elements/LocationSearchInput';
import { parseAddress } from 'src/utils/parseAddress';
import { TextField } from '@material-ui/core';
import { BookingStatus } from 'src/models/bookingStatus';
import { Label } from '@material-ui/icons';
import { TwitterPicker } from 'react-color';
import { BookingStatusController } from 'src/controllers/bookingStatusController';

interface FormValues {
    name: string;
    color: string;
}

interface FormProps {
    toggle?: () => any;
    bookingStatus?: BookingStatus;
    isNew: boolean;
    idBand: string;
    submitHandler?: (idBookingStatus: string) => any;
}

const Form = styled.form`
  && {
    padding: 2.5rem 3.5rem;
    > label {
      display: none;
    }
  }
`;

const Spacer = styled.div`
  height: 1.5rem;
`;

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = ({
    errors,
    toggle,
    handleSubmit,
    isSubmitting,
    handleChange,
    values,
    setFieldValue,
    bookingStatus
}) => {
    return (
        <Form
            onSubmit={e => {
                e.stopPropagation();
                handleSubmit(e);
            }}
        >
            <TextField
                name="name"
                type="text"
                label={errors.name ? errors.name : 'Name'}
                error={Boolean(errors.name)}
                value={values.name}
                onChange={handleChange}
                fullWidth
            />
            <Spacer />
            <Label>Custom Color (used on the Calendar page)</Label>
            <br />
            <TwitterPicker
                color={values.color}
                onChangeComplete={(color) => {
                    values.color = color.hex
                }}
            />
            <BlueButton style={{ marginTop: '2rem' }} type="submit" disabled={isSubmitting}>
                Submit
            </BlueButton>
        </Form>
    )
};

const BandAddressForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: ({ bookingStatus }) => ({
        name: (bookingStatus && bookingStatus.name) || '',
        color: (bookingStatus && bookingStatus.color) || '',
    }),
    validationSchema: Yup.object().shape({}),
    handleSubmit: async (values, { props, setSubmitting }) => {
        setSubmitting(true);
        const controller = new BookingStatusController(props.idBand);
        if (props.submitHandler) {
            const doc = await controller.create<BookingStatus>({
                idBand: props.idBand,
                name: values.name,
                color: values.color,
            });
            if (props.toggle) {
                props.toggle();
            }
            if (doc && props.submitHandler) {
                await props.submitHandler(doc.id);
            }
            toast.success(`New Booking Status created!`);
            return;
        } else {
            if (props.toggle) {
                props.toggle();
            }
            if (props.isNew) {
                await controller
                    .create({
                        idBand: props.idBand,
                        name: values.name,
                        address: values.color,
                    });
                toast.success(`New Booking Status created!`);
            } else if (props.bookingStatus && props.bookingStatus.id) {
                await controller
                    .update(
                        props.bookingStatus.id,
                        new BandAddress({
                            id: props.bookingStatus.id,
                            idBand: props.bookingStatus.idBand,
                            name: values.name,
                            address: values.color,
                        })
                    )
                    .catch(() => toast.error("Couldn't update Booking Status"));
                toast.success(`Booking Status updated!`);
            }
        }
        setSubmitting(false);
    },
})(InnerForm);

export default BandAddressForm;
