import React, { Component } from 'react';
import { toast } from 'react-toastify';

class ErrorBoundary extends Component<{}, {error: any}> {
  constructor(props: any) {
    super(props);
    this.state = { error: null };
  }
  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error });
  }
  render() {
    if (this.state.error) {
      toast.error('Please reload the page and try again')
      return (
        <div>
          <p>We're sorry — something's gone wrong.</p>
        </div>
      );
    } else {
      // @ts-ignore
      return this.props.children;
    }
  }
}

export default ErrorBoundary;