import {Model} from "./model";
import {Collections} from "./basics/Collections";
import {EventStati} from "./basics/EventStati";

export class CalendarEvent implements Model {
    collection?: string;
    id?: string;

    idEvent: string;
    idBand: string;
    title: string;
    status: EventStati | string;
    location: string;
    dateStart: Date;
    dateEnd: Date;
    bandShortHand: string


    constructor(o: CalendarEvent) {
        this.collection = Collections.BANDS + "/" + o.idBand;
        this.id = o.id || "";
        this.idEvent = o.idEvent;
        this.idBand = o.idBand;
        this.title = o.title;
        this.location = o.location;
        this.dateStart = new Date(o.dateStart);
        this.dateEnd = new Date(o.dateEnd) || new Date(o.dateStart);
        this.bandShortHand = o.bandShortHand || "";
        this.status = o.status;
    }


}