import React, { FC, useContext, useEffect, useState } from 'react';
import Page from '../../../_common/page/Page';
import styled from 'styled-components';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import * as moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { lighten } from 'polished';
import { Routes } from '../../../../constants/Routes';
import { GighubEvent } from 'src/models/gighubEvent';
import { RootStoreContext } from 'src/store/RootStore';
import { observer } from 'mobx-react-lite';
import Toggle from "../../../_common/utilities/Toggle";
import EventInfoComponent from "../../notifications/EventInfoContainer";
import { GigHubDialog } from "../../../_common/_elements/GigHubDialog";
import { RouteComponentProps } from '@reach/router';
import StatusIcon, { StatusSizes } from 'src/views/_common/_elements/StatusIcon';
import { EventStatusPane } from "../../../_common/_elements/StatusPane";
import { AppTheme, theme } from "../../../../theme";
import useIsDesktop from "../../../../hooks/useIsDesktop";

const localizer = momentLocalizer(moment);


interface Props {
  path: Routes;
  theme: AppTheme;
}

const Container = styled.div`
  margin-top: 1rem;
  padding: 0 1rem;
`;

const Status = styled(StatusIcon)`
  grid-row: 2;
  display: flex;
  align-items: center;
  padding-left: 4px;
`;

moment.locale('ko', {
  week: {
    dow: 1,
    doy: 1,
  },
});

const EventCalendar = styled(Calendar as any)`
  .rbc-event {
    background: #2D2D2D;
    overflow: hidden;
    padding: 0;
  }
   .rbc-toolbar {
      flex-direction: row-reverse;
      }
  @media screen and (max-width: 500px) {
    .rbc-month-view {
      font-size: 1.3rem;
      text-align: center;
    }
    .rbc-event-content {
      font-size: 1.2rem;
    }
    .rbc-date-cell {
      text-align: center;
    }
    .rbc-header {
      text-transform: uppercase;
      font-size: 1rem;
      padding: 3px 0;
    }
    .rbc-day-bg.rbc-off-range-bg {
      background: ${props => lighten(0.4, props.theme.colors.primaryLight)};
      border-color: ${props => lighten(0.4, props.theme.colors.primaryLight)};
    }
    > .rbc-toolbar {
      > .rbc-toolbar-label {
        order: 1;
        margin-bottom: 0.7rem;
      }
      > .rbc-btn-group {
        margin-bottom: 0.7rem;
      }
    }
  }
`;

const CalendarContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  display: grid;
  grid-template-columns: 0.7rem auto auto;
  grid-template-rows: 3fr;
  align-items: center;
  overflow: visible;
  padding: 2px 5px 2px 0px;
`

const CalStatusPane = styled(EventStatusPane)`
  grid-row: 1;
  grid-column: 1;
  width: 0.5rem;
`
const BandShortcut = styled.div`
  grid-row: 1;
  grid-column: 2;
`


const EventTitle = styled.div`
  grid-row: 1;
  grid-column: 3;
`

export interface TEvent {
  allDay?: boolean;
  title?: string;
  start?: Date;
  end?: Date;
  resource?: any;
}

const CalendarPage: FC<Props & RouteComponentProps> = observer(({ navigate }) => {

  const { eventsStore: { observables: events }, bandsStore: { observables: bands, getById } } = useContext(RootStoreContext);
  const [currentEvent, setCurrentEvent] = useState<GighubEvent | null>(null)


  useEffect(() => {
    const pathname = window.location.href;
    localStorage.setItem('pageUrl', JSON.stringify(pathname))
  }, []);

  const CustomEvent = (event: any) => {
    const band = getById(event.event.resource.idBand).get()
    if (band) {
      return (
        <CalendarContent>
          <CalStatusPane status={event.event.resource.status} />
          <BandShortcut style={{
            color: band.primaryColor
          }}>{band.shorthand}:&nbsp;</BandShortcut>
          <EventTitle>{event.title}</EventTitle>
        </CalendarContent>
      )
    } else {
      return (
        <div><strong>{event.title}</strong></div>
      )
    }
  }
  const isDesktop = useIsDesktop();
  return (
    <>
      {navigate && <Page navigate={navigate}>
        <Container style={{ height: 'calc(95vh' + (!isDesktop ? (' - ' + theme.layout.headerHeight + ' - ' + theme.layout.footerHeight) : '') + ')' }}>
          <Toggle>
            {({ on, toggle }) => (
              <>
                <EventCalendar
                  localizer={localizer}
                  views={['month', 'week']}
                  defaultDate={new Date()}
                  defaultView="month"
                  events={events.map((event: GighubEvent) => ({
                    start: event.dateStart,
                    end: event.dateEnd,
                    title: event.title,
                    resource: event
                  }))}
                  components={{
                    event: CustomEvent
                  }}
                  onSelectEvent={(event: TEvent) => {
                    setCurrentEvent(event.resource)
                    toggle()
                  }}
                />
                {currentEvent && (<GigHubDialog open={on} onClose={toggle}>
                  <EventInfoComponent event={currentEvent} />
                </GigHubDialog>)}
              </>
            )}
          </Toggle>
        </Container>
      </Page>}
    </>
  );
})

export default CalendarPage;
