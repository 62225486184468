import { Collections } from "../models/basics/Collections";
import { GighubEvent } from "../models/gighubEvent";
import { auth, database as db } from "../database/fbApp";
import { Band } from "../models/band";
import { NotificationController } from "./notificationController";
import { GighubNotification } from "../models/gighubNotification";
import { NotificationTypes } from "../models/basics/NotificationTypes";
import { BandController } from "./bandController";
import { AvailabilityStati } from "../models/basics/AvailabilityStati";
import { toast } from "react-toastify";
import { gighubNotificationTexts } from "../models/basics/gighubNotificationTexts";
import { FormationController } from "./formationController";
import { Formation } from "../models/formation";
import { UserController } from "./userController";
import { Availability } from "../models/basics/availability";
import { BandStati } from "../models/basics/BandStati";
import "firebase/compat/auth";
import { Controller } from "./controller";
import { Mail } from "src/models/mail";
import { MailController } from "./mailController";
import { User } from "src/models/user";


export class EventController extends Controller {
    constructor(idParent: string) {
        super(Collections.EVENTS, Collections.BANDS, idParent);
    }


    async handleInvite(notification: GighubNotification, availabilityType: AvailabilityStati) {
        const event = await new EventController(notification.idBand).get<GighubEvent>(notification.idReference);
        const band = await new BandController().get<Band>(notification.idBand);
        const currentUser = (window as any).gighub.CurrentUser;
        if (event && event.id && event.availabilities && band && currentUser && currentUser.id) {
            event.availabilities[currentUser.id].status = availabilityType;
            await this.update(event.id, this.updateBandStatus(event));
            await new UserController().handleAvailabilitySubmit(AvailabilityStati.ACCEPTED, currentUser.id, event.id, band, event, true)
            await new NotificationController().delete(notification.id);
            // TODO: Bei Decline kann Nachricht gesendet werden
            // Notify Sender
            await new NotificationController().notify({ id: notification.idSender } as Partial<User>,
                currentUser.fullName
                + (availabilityType === AvailabilityStati.ACCEPTED ?
                    gighubNotificationTexts.USERACCEPTEDEVENTINVITE
                    : gighubNotificationTexts.USERDECLINEDEVENTINVITE)
                + event.title,
                NotificationTypes.INVITEANSWER, event.id, band);
        } else {
            toast.error("Error")
        }
    }

    async updateAvailability(event: GighubEvent, idUser: string, band: Band) {
        if (auth.currentUser && auth.currentUser.uid && event.id) {
            if (!event.availabilities) event.availabilities = {}
            event.availabilities[auth.currentUser.uid] = new Availability({ status: AvailabilityStati.ACCEPTED });
            await new EventController(event.idBand).update(event.id, await new EventController(event.idBand).updateBandStatus(event));
            await new UserController().handleAvailabilitySubmit(AvailabilityStati.ACCEPTED, idUser, event.id, band, event, true)
        }
    }

    async autoInviteFormation(event: GighubEvent) {
        if (event && event.idFormation) {
            try {
                const band = await new BandController().get<Band>(event.idBand)
                const formation = await new FormationController(event.idBand).get<Formation>(event.idFormation)
                if (band && formation && formation.members) {
                    await Promise.all(formation.members.map(async (idUser: string) => {
                        if (event && event.id && auth.currentUser && idUser === auth.currentUser.uid) {
                            await this.updateAvailability(event, idUser, band)
                        } else {
                            await this.sendInvitation(band, event, idUser)
                        }
                    }));
                    toast.success("All Invitations have been sent");
                }
            } catch (e) {
                console.log(e)
            }
        } else {
            toast.success("Oops! Something went wrong");
        }
    }

    async sendInvitation(band: Band, event: GighubEvent, idUser: string, message?: string) {
        if (event.id) {
            try {
                event.availabilities ?
                    event.availabilities[idUser] = { status: AvailabilityStati.INVITED }
                    : event.availabilities = { [idUser]: { status: AvailabilityStati.INVITED } };
                await new EventController(event.idBand).update(event.id, this.updateBandStatus(event));
                await new NotificationController().notify({ id: idUser } as Partial<User>,
                    band.name + " has invited you to participate in '" + event.title + "'. " + (message ? message : ""),
                    NotificationTypes.EVENTINVITATION, event.id, band)
                const user = await new UserController().get(idUser) as User
                if (user.email) {
                    await this.sendEmail(user.email
                        , band.shorthand + ": New Gighub Invitation",
                        ""
                        , "You have a new invitation on Gighub for '" + band.name + "'"
                        + "<br><br>date: " + event.dateStart.toLocaleDateString()
                        + "<br> event: " + event.title
                        + "<br><br>" + (message ? message : "")
                        + "<br><br><a href='https://gighub.ch/notifications'>click here to answer</a><br><br><br> love<br>gighub"
                    )
                }
            } catch (e) {
                console.log(e)
            }
        }
    }

    async sendEmail(to: string, subject: string, text: string, html: string) {
        const mail = new Mail(to, { subject, text, html })
        await new MailController().create(mail)
    }

    async updateCalendarEvents(event: GighubEvent, band: Band, deleteCalendarEvents?: boolean) {
        if (event.availabilities && event.id) {
            await Promise.all(
                Object.keys(event.availabilities).map(async (idUser) => {
                    deleteCalendarEvents
                        ? await new UserController().deleteCalendarEvent(idUser, event.id!)
                        : await new UserController().updateCalendarEvent(idUser, event, band)
                })
            )
        }
    }

    updateBandStatus(event: GighubEvent): GighubEvent {
        let memberCount = 0;
        let bandStatus = BandStati.COMPLETE
        if (event.availabilities && Object.keys(event.availabilities).length > 0) {
            Object.keys(event.availabilities).forEach(key => {
                if (event.availabilities) {
                    const status = event.availabilities[key].status;
                    if (status !== AvailabilityStati.ACCEPTED) {
                        if (status === AvailabilityStati.PENDING && bandStatus !== BandStati.INCOMPLETE) {
                            bandStatus = BandStati.PENDING
                        } else {
                            bandStatus = BandStati.INCOMPLETE
                        }
                    } else {
                        memberCount++;
                    }
                }
            })
        } else {
            bandStatus = BandStati.INCOMPLETE
        }

        /* if (BandStati.COMPLETE && event.idFormation) {
            const formation = await new FormationController().get<Formation>(event.idFormation, event.idBand)
            if (formation && formation.members && memberCount < formation.members.length){
                bandStatus = BandStati.INCOMPLETE
            }
        }*/

        event.bandStatus = bandStatus;
        return event
    }

    async updateUploadedDocs(eventId: string, uploadedDocs: Array<{ name: string; url: string; public_id: string }>, deleteDocPublicId?: string) {
        if (eventId) {
            try {
                // If a deleteDocPublicId is provided, filter out the document with that public_id
                if (deleteDocPublicId) {
                    uploadedDocs = uploadedDocs.filter(doc => doc.public_id !== deleteDocPublicId);
                }

                const partialEvent: Partial<GighubEvent> = { uploadedDocs };
                await this.update<GighubEvent>(eventId, partialEvent as GighubEvent);
            } catch (e) {
                console.log(e);
            }
        }
    }

}