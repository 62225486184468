import * as React from 'react';
import {FC} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {Model} from "../../../models/model";
import {Member} from "../../../models/basics/member";


interface Props {
    open: any;
    handleClose: any;
    handleDelete: (id: string) => void
    model?: Model
    id?: string
    modelName: string
}

export const DeleteForm: FC<Props> = ({id, open, handleClose, handleDelete, model, modelName}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Delete {modelName}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Do you really want to delete this {modelName}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Disagree
                </Button>
                <Button onClick={() => {
                    handleDelete((model && model.id) ? model.id : id!)
                    handleClose()
                }} color="secondary" autoFocus>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    )
}