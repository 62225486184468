import {InjectedFormikProps, withFormik} from 'formik';
import * as React from 'react';
import styled from 'styled-components';
import {SecondaryButton} from '../../../../_common/_elements';
import {MemberRoles} from '../../../../../models/basics/MemberRoles';
import * as Yup from 'yup';
import {MenuItem, TextField} from '@material-ui/core';
import {UserController} from "../../../../../controllers/userController";
import {useEffect} from "react";
import {useState} from "react";
import {UserArray} from "../../../../../models/basics/documentArray";
import {User} from "../../../../../models/user";
import Select from "react-select";
import {toast} from "react-toastify";

interface FormValues {
    role: MemberRoles;
    idUser: string;
    email: string;
}

interface FormProps {
    handleSubmit: (role: MemberRoles, idMember?: string, email?: string) => any;
    toggle: () => any;
    authRole: MemberRoles;
}

const Form = styled.form`
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: ${props => props.theme.layout.settingsContainerPadding};
  > .btn {
    margin-top: 4rem;
  }
`;

interface OptionType {
    label: string;
    value: string;
}

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = ({
                                                                              handleSubmit,
                                                                              authRole,
                                                                              isSubmitting,
                                                                              handleChange,
                                                                              values,
                                                                              errors,
                                                                              touched,
                                                                          }) => {

    // function mapUserToOption(user: User): OptionType {
    //     return { value: user.id || "error", label: user.surname + " " + user.name };
    // }

    // function handleUserSelect({ value }: OptionType) {
    //     setSelectedUserInDropdown(mapUserToOption(allUsers.find((u: User) => u.id === value)!))
    //     values.idUser = value
    //     console.log(mapUserToOption(allUsers.find((u: User) => u.id === value)!))
    //     console.log(values.idUser)
    // }

    /* function renderUserSelectionMenu() {
        return (
            <Select
                id="idUser"
                name={"idUser"}
                fullWidth
                placeholder="Select a user"
                options={allUsers.map(mapUserToOption)}
                defaultValue={null}
                value={selectedUserInDropdown}
                onChange={handleUserSelect}
                label={"User"}
            />
        )
    }*/


    return (
        <Form onSubmit={handleSubmit}>
            <i>Beta: Please make shure to choose a correct email-adress. Addresses that don't belong to a registered GigHub Member won't work</i>
            <br />
            <br />
            <TextField
                id="email"
                name="email"
                fullWidth={true}
                label={errors.email && touched.email ? errors.email : 'email'}
                error={Boolean(touched.email && errors.email)}
                type="email"
                onChange={handleChange}
                value={values.email ? values.email.toLowerCase() : ""}
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <TextField
                select
                value={values.role || MemberRoles.SUB}
                inputProps={{
                    name: 'role',
                    id: 'role',
                }}
                onChange={handleChange}
                label={"Role"}
            >
                <MenuItem value={MemberRoles.SUB}>{MemberRoles.SUB}</MenuItem>
                <MenuItem value={MemberRoles.MUSICIAN}>{MemberRoles.MUSICIAN}</MenuItem>
                <MenuItem value={MemberRoles.CREW}>{MemberRoles.CREW}</MenuItem>
                {authRole === MemberRoles.ADMIN &&
                <MenuItem value={MemberRoles.EDITOR}>{MemberRoles.EDITOR}</MenuItem>}
                {authRole === MemberRoles.ADMIN &&
                <MenuItem value={MemberRoles.ADMIN}>{MemberRoles.ADMIN}</MenuItem>}
            </TextField>
            <SecondaryButton className="btn" type="submit" disabled={isSubmitting}>
                Add to band
            </SecondaryButton>
        </Form>)
};

const NewMemberForm = withFormik<FormProps, FormValues>({
    validationSchema: Yup.object().shape({
        // /* email: Yup.string()
        //     .email('This is not a valid email') */
    }),
    handleSubmit: async (values, {props, setSubmitting}) => {
        const allUsers = await new UserController().getAll();
        const emailUser = allUsers.find(u => u.email === values.email)
        if (emailUser && emailUser.id) {
            setSubmitting(true);
            props.toggle();
            props.handleSubmit(values.role, emailUser.id);
            setSubmitting(false);
        } else {
            setSubmitting(true);
            props.toggle();
            props.handleSubmit(values.role, undefined, values.email);
            setSubmitting(false);
        }
    },
})(InnerForm);

export default NewMemberForm;
