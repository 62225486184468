import useWindowSize from './useWindowSize';

import { theme } from 'src/theme';

const useIsDesktop = () => {
    const { width } = useWindowSize();
    const isDesktop = () : boolean => (width && width > theme.breakpoints.desktop) || false;
    return isDesktop();
}

export default useIsDesktop;