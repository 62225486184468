import React, {FC, useState, ChangeEvent, useContext, useRef, useEffect} from 'react';
import styled from 'styled-components';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    TextField,
    List,
    ListSubheader,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Switch,
    FormControlLabel,
    WithStyles,
    withStyles,
} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import {RootStoreContext} from 'src/store/RootStore';
import {Band} from 'src/models/band';
import {AvatarImage} from './_elements';
import useFocus from 'src/hooks/useFocus';
import {GighubEvent} from 'src/models/gighubEvent';
import {formatDistanceToNow, compareAsc, isFuture} from 'date-fns';
import {Link, RouteComponentProps, NavigateFn} from '@reach/router';
import {Routes, Links} from 'src/constants/Routes';
import {Customer} from 'src/models/customer';
import Highlighter from 'react-highlight-words';
import {canWriteBand, isParticipating} from "../../models/basics/MemberRoles";

interface Props {
    open: boolean;
    toggle: () => void;
    navigate: NavigateFn;
}

const SwitchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SearchDialog: FC<Props & WithStyles> = observer(({open, toggle, navigate, classes}) => {
    const [query, setQuery] = useState('');
    const [showPastEvents, setShowPastEvents] = useState(false);
    const {
        bandsStore: {observables: bands, getById},
        eventsStore: {observables: events},
        formationsStore: {observables: formations},
        customersStore: {observables: customers},
    } = useContext(RootStoreContext);

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        setQuery(e.target.value);
    }

    function filterBand(band: Band) {
        const searchQuery = query.toLowerCase();
        return band.name.toLowerCase().includes(searchQuery) || band.shorthand.toLowerCase().includes(searchQuery);
    }

    function filterEvents(event: GighubEvent) {
        const searchQuery = query.toLowerCase();
        return (
            event.title.toLowerCase().includes(searchQuery) ||
            (event.locationName && event.locationName.toLowerCase().includes(searchQuery))
        );
    }

    function renderEvents() {
        return events
            .slice()
            .filter(e => {
                const band = getById(e.idBand).get()
                if (band && canWriteBand(band)) return true
                else if (band && !canWriteBand(band) && isParticipating(e)) return true
                else return false
            })
            .filter(filterEvents)
            .filter(e => (showPastEvents ? true : isFuture(e.dateStart)))
            .sort((a: GighubEvent, b: GighubEvent) => compareAsc(a.dateStart, b.dateStart))
            .map(e => (
                <ListItem onClick={() => navigate && navigate(`${Links.EVENT}${e.id}`)} divider key={e.id}>
                    <ListItemText
                        primary={
                            <Highlighter
                                highlightClassName="highlighted"
                                searchWords={[query]}
                                autoEscape={true}
                                textToHighlight={e.title}
                            />
                        }
                        secondary={
                            <Highlighter
                                highlightClassName="highlighted"
                                searchWords={[query]}
                                autoEscape={true}
                                textToHighlight={
                                    (e.locationName && e.locationName.length > 0 ? e.locationName + ' - ' : '') +
                                    formatDistanceToNow(e.dateStart, {addSuffix: true})
                                }
                            />
                        }
                    />
                </ListItem>
            ));
    }

    const renderBands = () =>
        bands.filter(filterBand).map(b => (
            <ListItem onClick={() => navigate && navigate(`${Links.BAND_SETTINGS}${b.id}`)} divider key={b.id}>
                <ListItemAvatar>
                    <AvatarImage imgUrl={b.imageUrl} fallback={b.shorthand} size="small"/>
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Highlighter
                            highlightClassName="highlighted"
                            searchWords={[query]}
                            autoEscape={true}
                            textToHighlight={b.name}
                        />
                    }
                    secondary={
                        <Highlighter
                            highlightClassName="highlighted"
                            searchWords={[query]}
                            autoEscape={true}
                            textToHighlight={b.shorthand}
                        />
                    }
                />
            </ListItem>
        ));

    return (
        <Dialog classes={{paper: classes.dialogPaper}} open={open} onClose={toggle} fullWidth maxWidth="xl"
                scroll="body">
            <DialogContent>
                <TextField
                    placeholder="Search for anything.."
                    autoFocus
                    variant="outlined"
                    value={query}
                    onChange={handleChange}
                />
                <List>
                    <ListItemText>
                        <SwitchContainer>
                            <h4 style={{fontWeight: 'bold'}}>Events</h4>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showPastEvents}
                                        onChange={e => setShowPastEvents(e.target.checked)}
                                        value="checkedB"
                                    />
                                }
                                label={<small style={{color: 'rgba(0,0,0,.7)'}}>Show past events</small>}
                            />
                        </SwitchContainer>
                    </ListItemText>
                    {renderEvents()}
                    <ListItemText>
                        <h4 style={{fontWeight: 'bold'}}>Bands</h4>
                    </ListItemText>
                    {renderBands()}
                </List>
            </DialogContent>
        </Dialog>
    );
});

const styles = {
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
};

export default withStyles(styles)(SearchDialog);
