export class Address {
    city?: string;
    zip?: string;
    street?: string;
    streetnr?: string;
    country?: string;
    lat?: string;
    lng?: string;

    constructor(o:Address) {
        this.city = o.city || "";
        this.zip = o.zip || "";
        this.street = o.street || "";
        this.streetnr = o.streetnr || "";
        this.country = o.country || "";
        this.lat = o.lat || "";
        this.lng = o.lng || "";
    }

    toString(){
        return this.street + " " + this.streetnr + " " + this.zip + " " + this.city + " " + this.country
    }
}