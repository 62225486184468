import styled from "styled-components";
import * as React from 'react';

interface BrProps {
    opacity?: number;
    style?: any;
}

const Line = styled.div`
  border : 1px solid ${props => props.theme.colors.secondary};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  opacity: ${(props: BrProps) => props.opacity || props.opacity === 0 ? props.opacity : 1};
`;

const Br: React.FC<BrProps> = props => <Line {...props}/>;

export default Br;