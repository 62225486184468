import React, {FC, useEffect} from 'react';
import {AppTheme} from "../../../theme";
import styled, {withTheme} from "styled-components";
import {GighubEvent} from "../../../models/gighubEvent";
import {Link} from "@reach/router";
import {Links} from "../../../constants/Routes";
import {truncate} from 'src/utils/stringUtils';
import SubTitle from '../_elements/SubTitle';
import {Band} from "../../../models/band";
import {Formation} from "../../../models/formation";
import {formatDistanceToNow} from 'date-fns';
import useIsDesktop from "../../../hooks/useIsDesktop";
import {BandStatusPane, EventStatusPane} from "../../_common/_elements/StatusPane";
import {EventStati} from "../../../models/basics/EventStati";
import {BandStati} from "../../../models/basics/BandStati";

interface Props {
    ref?: any;
    theme: AppTheme;
    band: Band | null;
    formation: Formation | null;
    event: GighubEvent;
    dateEnd?: Date;
    dateStart?: Date;
    fullyDressed?: boolean;
}

const Container = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: 10px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  display: grid;
  grid-template-columns: 2.7rem auto auto;
  grid-template-rows: 3fr;
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.colors.black};
  margin-bottom: 1rem;
  padding: 0.8rem 0rem 0.8rem 0rem;
  overflow: hidden;
`;

const EventStatus = styled(EventStatusPane)`
  grid-row: 1;
  grid-column: 1;
  width: 80%;
`;

const BandStatus = styled(BandStatusPane)`
  grid-row: 1;
  grid-column: 2;
`;


const TitleContainer = styled.div`
  grid-row: 1;
  grid-column: 2;
  > h4 {
    color: black;
    margin-bottom: 0.5rem;
  }
`;

const Town = styled.p`
  grid-row: 1;
  grid-column: 3;
  text-align: right;
  font-size: 1.3rem;
  margin-top: -10%;
  margin-right: 0.5rem;
`;


const EventWrapper: FC<Props> = ({theme, band, formation, fullyDressed, event: {title, status, dateStart, dateEnd, id, address, bandStatus, accommodation, locationName}}) => {
    let dateText: string = ""
    let subTitle: string = ""
    if (fullyDressed) {
        subTitle = dateStart.toLocaleDateString() !== dateEnd.toLocaleDateString()
            ? dateStart.toLocaleDateString() + ' - ' + dateEnd.toLocaleDateString()
            : dateStart.toLocaleDateString()
        if (formation) {
            subTitle += ' | ' + formation.name
        }
        if (locationName) {
            subTitle += ' | ' + locationName
        }
        if (accommodation) {
            subTitle += ' | 🏨 '
        }
        if (address.city) {
            dateText = address.city
        }
    } else {
        if (band) {
            subTitle = band.name
        }
        if (address.city) {
            subTitle += ' | ' + address.city
        }
        dateText = dateStart.toLocaleDateString()
        // dateText = formatDistanceToNow(dateStart, {addSuffix: true})
    } 
    return (<Link to={Links.EVENTS + id}>
        <Container>
            <EventStatus status={status || EventStati.CANCELLED} />
            <TitleContainer>
                <h4>{
                    !fullyDressed || !useIsDesktop() ? truncate(title, 17)
                        : truncate(title, 50)}</h4>
                <SubTitle>{subTitle}</SubTitle>
            </TitleContainer>
            <Town>* {dateText}</Town>
        </Container>
    </Link>)
};


export default withTheme(EventWrapper);