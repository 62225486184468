import React, {FC} from 'react';
import styled from 'styled-components';
import {Availability} from '../../../../../models/basics/availability';
import AvatarImageSmall from '../../../../_common/_elements/AvatarImage';
import {fullnameToInitials, truncate} from '../../../../../utils/stringUtils';
import {Member} from "../../../../../models/basics/member";
import {observer} from 'mobx-react-lite';
import Toggle from "../../../../_common/utilities/Toggle";
import {DeleteForm} from "../../../../_common/utilities/DeleteForm";
import {DangerButton} from "../../../../_common/_elements";
import {canWrite, MemberRoles} from "../../../../../models/basics/MemberRoles";
import AvailabilityColor from "../../../../_common/_elements/AvailabilityColor";


interface Props {
    availability: Availability;
    member: Member;
    idUser: string;
    handleDelete: (idUser: string) => any;
    authRole: MemberRoles;
}


const InfoHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 1rem;
`;

const InfoContent = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 1rem;
`;
const AvailabilityInfo: FC<Props> = observer(({availability, member, handleDelete, idUser, authRole}) => {


    return (
        <>
            <InfoHeader>
                {member && <AvailabilityColor status={availability.status!}><AvatarImageSmall imgUrl={member.imageUrl} fallback={fullnameToInitials(member.fullName)}
                                             size="small"/></AvailabilityColor>}
                <strong>{truncate(member.fullName, 18)}</strong>
            </InfoHeader>
            <InfoContent>
                <label>status</label><p>{availability.status}</p>
                {availability.comment && <><label>comment</label><p>{availability.comment}</p></>}
                {availability.isFreeOfExpenses && <><label>isFreeOfExpenses</label>
                    <p>{availability.isFreeOfExpenses}</p></>}
                {availability.isInNeedOfAccommodation && <><label>isInNeedOfAccommodation</label>
                    <p>{availability.isInNeedOfAccommodation}</p></>}
                {availability.via && <><label>Drove via</label><p>{availability.via}</p></>}
                {canWrite(authRole) && availability && handleDelete &&
                <Toggle>
                    {({on, toggle}) => (
                        <>
                            <DeleteForm open={on} handleClose={toggle} modelName={"Availability"}
                                        handleDelete={handleDelete} id={idUser}/>
                            <DangerButton style={{marginBottom: '1rem'}} type="button" color="secondary"
                                          onClick={toggle}>Delete this
                                availability</DangerButton>
                        </>
                    )}
                </Toggle>
                }
            </InfoContent>
        </>
    )
});


export default AvailabilityInfo;
