import {action} from 'mobx';
import {GighubNotification} from 'src/models/gighubNotification';
import {Store} from "./Store";
import {database as db} from "../database/fbApp";
import {NotificationTypes} from "../models/basics/NotificationTypes";

export class NotificationsStore extends Store<GighubNotification> {


    @action
    getUnseenNotifications = (idRecipent: string) => {
        return this.observables.filter(n => !n.isSeen) || [];
    }

    @action
    getEventInvite = (idRecipent: string, idEvent: string) => {
        return this.observables.filter(n => n.idRecipent === idRecipent && n.idRecipent === idEvent && n.type === NotificationTypes.EVENTINVITATION);
    }
}



