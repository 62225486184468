import { InjectedFormikProps, withFormik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';
import { BlueButton } from '../../../../_common/_elements';
import { toast } from 'react-toastify';
import { BandAddressController } from 'src/controllers/bandAddressController';
import { BandAddress } from 'src/models/bandAddress';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import LocationSearchInput from 'src/views/_common/_elements/LocationSearchInput';
import { parseAddress } from 'src/utils/parseAddress';
import { TextField } from '@material-ui/core';

interface FormValues {
    name: string;
    address: string;
}

interface FormProps {
    toggle?: () => any;
    bandAddress?: BandAddress;
    isNew: boolean;
    idBand: string;
    submitHandler?: (idBandAddress: string) => any;
}

const Form = styled.form`
  && {
    padding: 2.5rem 3.5rem;
    > label {
      display: none;
    }
  }
`;

const Spacer = styled.div`
  height: 1.5rem;
`;

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = ({
    errors,
    toggle,
    handleSubmit,
    isSubmitting,
    handleChange,
    values,
    setFieldValue,
    bandAddress
}) => {
    return (
        <Form
            onSubmit={e => {
                e.stopPropagation();
                handleSubmit(e);
            }}
        >
            <TextField
                name="name"
                type="text"
                label={errors.name ? errors.name : 'Name'}
                error={Boolean(errors.name)}
                value={values.name}
                onChange={handleChange}
                fullWidth
            />
            <Spacer />
            <LocationSearchInput
                initialAddressString={bandAddress && bandAddress.address ? bandAddress.address : undefined}
                handleSelect={address => {
                    geocodeByAddress(address)
                        .then(results => getLatLng(results[0]))
                        .then(latLng => {
                            // todo hide key
                            fetch(
                                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng
                                }&key=AIzaSyCLaNc6hiUN1dwkwraI9JUb3FcAe1veqnY`
                            )
                                .then(res => res.json())
                                .then(data => {
                                    const { city, country, streetnr, street } = parseAddress(data.results);
                                    setFieldValue(
                                        'address',
                                        street + " " + streetnr + " " + city + " " + country
                                    );
                                });
                        })
                        .catch(error => console.error('Error', error));
                }}
            />
            <BlueButton style={{ marginTop: '2rem' }} type="submit" disabled={isSubmitting}>
                Submit
            </BlueButton>
        </Form>
    )
};

const BandAddressForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: ({ bandAddress }) => ({
        name: (bandAddress && bandAddress.name) || '',
        address: (bandAddress && bandAddress.address) || '',
    }),
    validationSchema: Yup.object().shape({}),
    handleSubmit: async (values, { props, setSubmitting }) => {
        setSubmitting(true);
        const bandAddressController = new BandAddressController(props.idBand);
        if (props.submitHandler) {
            const doc = await bandAddressController.create<BandAddress>({
                idBand: props.idBand,
                name: values.name,
                address: values.address,
            });
            if (props.toggle) {
                props.toggle();
            }
            if (doc && props.submitHandler) {
                await props.submitHandler(doc.id);
            }
            toast.success(`New Address created!`);
            return;
        } else {
            if (props.toggle) {
                props.toggle();
            }
            if (props.isNew) {
                await bandAddressController
                    .create({
                        idBand: props.idBand,
                        name: values.name,
                        address: values.address,
                    });
                toast.success(`New Address created!`);
            } else if (props.bandAddress && props.bandAddress.id) {
                await bandAddressController
                    .update(
                        props.bandAddress.id,
                        new BandAddress({
                            id: props.bandAddress.id,
                            idBand: props.bandAddress.idBand,
                            name: values.name,
                            address: values.address,
                        })
                    )
                    .catch(() => toast.error("Couldn't update customer"));
                toast.success(`Address updated!`);
            }
        }
        setSubmitting(false);
    },
})(InnerForm);

export default BandAddressForm;
