import * as React from 'react';

interface ChildrenProps {
    on: boolean;
    toggle: () => any;
}

interface ToggleProps {
    children: ({}: ChildrenProps) => any
}

interface ToggleState {
    on: boolean;
}

export default class Toggle extends React.Component<ToggleProps, ToggleState> {
    state = {
        on: false
    };

    toggle = () => {
        this.setState({on: !this.state.on})
    };

    render() {
        const {children} = this.props;
        return children({
            on: this.state.on,
            toggle: this.toggle
        });

    }
}