import { InjectedFormikProps, withFormik } from 'formik';
import * as React from 'react';
import styled from 'styled-components';
import Select from '@material-ui/core/Select/Select';
import Input from '@material-ui/core/Input/Input';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import { Member } from '../../../../../models/basics/member';
import { SecondaryButton } from '../../../../_common/_elements';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";

interface FormValues {
  users: string[];
  message: string;
}

interface FormProps {
  members: Member[];
  handleSubmit: (users: string[], message: string) => any;
  toggle: () => any;
}

const Form = styled.form`
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: ${props => props.theme.layout.settingsContainerPadding};
  > .btn {
    margin-top: 4rem;
  }
`;

const MySelect = styled(Select)`
  width: 100%
`;

const MyFormControl = styled(FormControl)`
  width: 100%
`;

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = ({
  members,
  handleSubmit,
  isSubmitting,
  handleChange,
  values,
}) => (
  <Form onSubmit={handleSubmit}>
      <h3>Invite User</h3>
    <MyFormControl>
      <InputLabel htmlFor="select-multiple-checkbox">Users</InputLabel>
      <MySelect
        multiple
        name="users"
        value={values.users}
        onChange={handleChange}
        input={<Input id="select-multiple-checkbox" />}
        renderValue={(selected: string[]) => selected.map(idUser => members[idUser].fullName).join(', ')}
      >
        {Object.keys(members).map((idUser: string) => (
          <MenuItem key={idUser} value={idUser}>
            <Checkbox checked={values.users.indexOf(idUser) > -1} />
            <ListItemText primary={members[idUser].fullName} />
          </MenuItem>
        ))}
      </MySelect>
    </MyFormControl>
    <TextField
      id="message"
      name="message"
      fullWidth={true}
      label="Message (optional)"
      type="textarea"
      onChange={handleChange}
      InputLabelProps={{
          shrink: true,
      }}
    />
    <SecondaryButton className="btn" type="submit" disabled={isSubmitting}>
      Invite
    </SecondaryButton>
  </Form>
);

const NewAvailabilityForm = withFormik<FormProps, FormValues>({
  mapPropsToValues: props => ({
    users: [],
    message: '',
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.toggle();
    props.handleSubmit(values.users, values.message);
    setSubmitting(false);
  },
})(InnerForm);

export default NewAvailabilityForm;
