import {User} from 'src/models/user';

export function truncate(input: string, limit = 12) {
    if (!input) return "";
    return input.length > limit ? `${input.substring(0, limit)}...` : input;
}

export function fullnameToInitials(fullname: string) {
    if (fullname) {
        const splitted = fullname.split(' ');
        if (splitted[0] && splitted[1] && splitted[0][0] && splitted[1][0]) {
            const initials = splitted[0][0].toUpperCase() + splitted[1][0].toUpperCase();
            if(initials.length === 2) return initials
        }
    }
    return ''
}

export function userToInitials({name, surname}: User) {
    if (name && surname && name.length > 0 && surname.length > 0) {
        return name[0].toUpperCase() + surname[0].toUpperCase();
    }
    return '';
}