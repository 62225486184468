import React from 'react';
import styled from "styled-components";
import rgba from "polished/lib/color/rgba";
import Icon, { IconName, IconSize } from "../_elements/Icon";
import Br from "../_elements/Br";
import {auth} from "../../../database/fbApp";
import Portal from "../utilities/Portal";
import { Links, Routes } from "../../../constants/Routes";
import { navigate, RouteComponentProps, Link } from "@reach/router";
import { FC, useContext } from 'react';
import { RootStoreContext } from 'src/store/RootStore';
import { observer } from 'mobx-react-lite';
import AvatarImage from '../_elements/AvatarImage';
import { userToInitials } from 'src/utils/stringUtils';

interface Props {
  open: boolean;
  toggle: () => any;
}


const Break = styled(Br)`
  margin-bottom : 2rem;
  margin-top: 2rem;
`;

const Blocker = styled.div`
  background: rgba(0,0,0,0.6);
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: ${(props: any) => props.open ? '0' : '-100vw'};
  opacity: ${(props: any) => props.open ? '1' : '0'};
  top: 0;
  z-index: 1001;
  transition: opacity .25s ease-out;
`;

const SidebarContainer = styled.div`
  width: 80vw;
  height: 100vh;
  background: ${props => props.theme.colors.white};
  position: absolute;
  top: 0;
  left: ${(props: any) => props.open ? '0' : '-100vw'};
  transition: left .25s ease-out;
  z-index: 1100;
  display: grid;
  grid-template-rows: 3fr 7fr;
`;

const TopContainer = styled.div`
 background-image: linear-gradient(150.91deg, 
        ${props => rgba(props.theme.colors.primary, .9)} -1.26%, 
        ${props => rgba(props.theme.colors.primary, .9)} 45.89%, 
        ${props => rgba(props.theme.colors.primaryLight, .9)} 100.63%), 
        url("https://res.cloudinary.com/bassplayerch/image/upload/c_scale,f_auto,h_1545,q_auto/v1530704427/gighub/ron-smith-328524-unsplash.jpg");
    background-size: cover;  
    background-position-y: 53%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 3fr 1fr;
    align-items: center;
    padding: .2rem 1rem;
`;

const CloseIcon = styled(Icon)`
  margin-top: 1rem;
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  padding-top: .3rem;
`;

const Name = styled.p`
  color: ${props => props.theme.colors.white};
  font-size: 1.8rem;
`;

const BottomContainer = styled.div`
  background: white;
  height: 100%;
  width: 100%;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1rem .7rem;
  cursor: pointer;
  transition: all .25s;
  &:hover {
    background-color: ${props => props.theme.colors.lightGrey};
  }
  &:not(:first-of-type){
    padding-top: 0.7rem;
  }
  &:first-of-type {
    margin-top: 1rem;
  }
`;

const MenuItemText = styled.p`
  margin-left: 1.2rem;
  font-size: 1.4rem;
`;

const LogoutLink = styled(Link)`
  color: currentColor;
  &:active, &:hover, &:visited {
    color: currentColor;
  }
`;



const Sidebar: FC<Props & RouteComponentProps> = observer((props) => {

  const { userStore: { currentUser } } = useContext(RootStoreContext);
  const handleLogout = async () => {
    await auth.signOut()
}
  return (
    <Portal>
      <>
        <SidebarContainer {...props}>
          <TopContainer>
            <CloseIcon name={IconName.CLOSE} size={IconSize.SMALL} onClick={props.toggle} />
            {currentUser && <AvatarImage size="medium" imgUrl={currentUser.imageUrl} fallback={userToInitials(currentUser)} />}
            <NameContainer>
              <Name>{currentUser && `${currentUser.surname} ${currentUser.name}`}</Name>
            </NameContainer>
          </TopContainer>
          <BottomContainer>
            <div>
              <MenuItem onClick={() => navigate(Routes.USER_SETTINGS)}>
                <Icon name={IconName.USER_ACCOUNT} size={IconSize.MEDIUM}
                  color={'#686868'} />
                <MenuItemText>{'User Account'}</MenuItemText>
              </MenuItem>
              <MenuItem onClick={() => navigate(Links.ICAL)}>
                <Icon name={IconName.CALENDAR} size={IconSize.MEDIUM} color={'#686868'} />
                <MenuItemText>{'Link Your Calendar'}</MenuItemText>
              </MenuItem>
            </div>
            <MenuItem onClick={() => navigate(Links.BANDS)}>
              <Icon name={IconName.PEOPLE} size={IconSize.MEDIUM} color={'#686868'} />
              <MenuItemText>{'Your Bands'}</MenuItemText>
            </MenuItem>
            <Break />
            <LogoutLink to={Routes.HOME}>
              <MenuItem onClick={handleLogout}>
                <Icon name={IconName.POWER} size={IconSize.MEDIUM} color={'#686868'} />
                <MenuItemText>{'Sign Out'}</MenuItemText>
              </MenuItem>
              </LogoutLink>
              <Break />
          </BottomContainer>
        </SidebarContainer>
        <Blocker {...props} onClick={props.toggle} />
      </>
    </Portal>
  )

})


export default Sidebar;