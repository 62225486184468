import React, { FC, useContext } from 'react';
import Sidebar from 'src/views/_common/sidebar/MobileSidebar';
import { useToggle } from 'src/hooks/useToggle';
import MobileHeader from 'src/views/_common/page/MobileHeader';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from 'src/store/RootStore';
import { Icon, BlueButton } from 'src/views/_common/_elements';
import { IconName, IconSize } from 'src/views/_common/_elements/Icon';
import { Link, RouteComponentProps } from '@reach/router';
import { Routes } from 'src/constants/Routes';
import { rgba } from 'polished';

const Container = styled.div`
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  background: ${props => props.theme.colors.background};
  overflow-x: hidden;
  padding: 1rem 1rem;
`;

const Header = styled.header``;

const MenuIcon = styled(Icon)`
  display: ${(props: any) => (props.sidebarOpen ? 'none' : 'block')};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;

  h2 {
    margin-top: 2rem;
    font-weight: bold;
  }
  p {
    margin-top: 2rem;
  }
  button {
    margin-top: 2rem;
  }
`;

const Image = styled.div`
  background-image: linear-gradient(
      150.91deg,
      ${props => rgba(props.theme.colors.primary, 0.9)} -1.26%,
      ${props => rgba(props.theme.colors.primary, 0.9)} 45.89%,
      ${props => rgba(props.theme.colors.primaryLight, 0.9)} 100.63%
    ),
    url('https://res.cloudinary.com/bassplayerch/image/upload/c_scale,f_auto,h_1545,q_auto/v1530704427/gighub/ron-smith-328524-unsplash.jpg');
  background-size: cover;
  background-position-y: 53%;
  height: 30vh;
  width: 100%;
`;

const NoBandsPage: FC<RouteComponentProps> = observer(() => {
  const { on, toggle } = useToggle();
  const {
    userStore: { currentUser },
  } = useContext(RootStoreContext);

  console.log(currentUser);
  return (
    <>
      <Sidebar open={on} toggle={toggle} />
      <Container>
        <Header>
          <MenuIcon testId="gighub_logo"  name={IconName.GIGHUB_LOGO} size={IconSize.XL} onClick={toggle} color="#090909" />
        </Header>
        {currentUser && (
          <Wrapper>
            <Image />
            <h2>Welcome to Gighub {currentUser.surname}!</h2>
            <p>
              Add your first band to get started or skip this step if you have been invited to join another band
            </p>
            <Link style={{ width: '100%' }} to={Routes.BAND_SETTINGS}>
              <BlueButton>Create a Band</BlueButton>
            </Link>
              <Link style={{ width: '100%' }} to={Routes.HOME}>
                  <BlueButton>No thanks, would like to wait / join another band</BlueButton>
              </Link>
          </Wrapper>
        )}
      </Container>
    </>
  );
});

export default NoBandsPage;
