import React from 'react';
import styled from 'styled-components';
import { TopNav } from 'src/views/_common/_elements';

const SettingsTopNav = styled(TopNav)`
  background: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.settingsWhite};
`;

export default SettingsTopNav;
