import { Model } from "./model";
import { Collections } from "./basics/Collections";
import { Address } from "./basics/address";
import { observable } from 'mobx';

export class User implements Model {
    collection?: Collections;
    id?: string;
    name?: string;
    surname?: string;
    address?: Address;
    email: string;
    tel?: string;
    fcmTokens?: string[];
    imageUrl?: string;

    constructor(o: User, newUser: boolean = false) {
        this.collection = Collections.USERS;
        this.id = o.id || '';
        this.name = o.name || '';
        this.surname = o.surname || '';
        this.address = o.address || {};
        this.email = o.email || '';
        this.tel = o.tel || '';
        this.fcmTokens = newUser ? [] : o.fcmTokens;
        this.imageUrl = o.imageUrl || '';
    }
}