import {InjectedFormikProps, withFormik} from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';
import {BlueButton} from '../../../../_common/_elements';
import {Customer, Salutations} from '../../../../../models/customer';
import {CustomerController} from '../../../../../controllers/customerController';
import {Address} from '../../../../../models/basics/address';
import {toast} from 'react-toastify';
import {FormControl, InputLabel, MenuItem, Select, TextField} from '@material-ui/core';

interface FormValues {
    salutation: Salutations;
    company: string;
    name: string;
    surname: string;
    email: string;
    tel: string;
    address: string;
}

interface FormProps {
    toggle?: () => any;
    customer?: Customer;
    newCustomer: boolean;
    idBand: string;
    submitHandler?: (idCustomer: string) => any;
}

const Form = styled.form`
  && {
    padding: 2.5rem 3.5rem;
    > label {
      display: none;
    }
  }
`;

const Spacer = styled.div`
  height: 1.5rem;
`;

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = ({
                                                                              errors,
                                                                              toggle,
                                                                              handleSubmit,
                                                                              isSubmitting,
                                                                              handleChange,
                                                                              values,
                                                                              setFieldValue,
                                                                          }) => {
    return (
        <Form
            onSubmit={e => {
                e.stopPropagation();
                handleSubmit(e);
            }}
        >
            <FormControl>
                <InputLabel htmlFor="status">Salutation</InputLabel>
                <Select
                    fullWidth={true}
                    value={values.salutation}
                    inputProps={{
                        name: 'salutation',
                        id: 'salutation',
                    }}
                    onChange={handleChange}
                >
                    <MenuItem value={Salutations.HERR}>{Salutations.HERR}</MenuItem>
                    <MenuItem value={Salutations.FRAU}>{Salutations.FRAU}</MenuItem>
                    <MenuItem value={Salutations.NONBINARY}>{Salutations.NONBINARY}</MenuItem>
                </Select>
            </FormControl>
            <TextField
                name="company"
                type="text"
                label={errors.name ? errors.surname : 'Company'}
                error={Boolean(errors.company)}
                value={values.company}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                name="surname"
                type="text"
                label={errors.name ? errors.surname : 'Surname'}
                error={Boolean(errors.surname)}
                value={values.surname}
                onChange={handleChange}
                fullWidth
            />
            <Spacer/>
            <TextField
                name="name"
                type="text"
                label={errors.name ? errors.name : 'Name'}
                error={Boolean(errors.name)}
                value={values.name}
                onChange={handleChange}
                fullWidth
            />
            <Spacer/>
            <TextField
                name="address"
                type="address"
                label={errors.address ? errors.address : 'Address'}
                error={Boolean(errors.address)}
                value={values.address}
                onChange={handleChange}
                fullWidth
                multiline
            />
            <TextField
                name="email"
                type="email"
                label={errors.email ? errors.email : 'Email'}
                error={Boolean(errors.email)}
                value={values.email}
                onChange={handleChange}
                fullWidth
            />
            <Spacer/>
            <TextField
                name="tel"
                type="phone"
                label={errors.tel ? errors.tel : 'Phone'}
                error={Boolean(errors.tel)}
                value={values.tel}
                onChange={handleChange}
                fullWidth
            />
            <Spacer/>
            <BlueButton style={{marginTop: '2rem'}} type="submit" disabled={isSubmitting}>
                Submit
            </BlueButton>
        </Form>
    )
};

const CustomerForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: ({customer}) => ({
        salutation: (customer && customer.salutation) || Salutations.HERR,
        company: (customer && customer.company) || '',
        name: (customer && customer.name) || '',
        surname: (customer && customer.surname) || '',
        address: (customer && customer.address) || '',
        email: (customer && customer.email) || '',
        tel: (customer && customer.tel) || '',
    }),
    validationSchema: Yup.object().shape({}),
    handleSubmit: async (values, {props, setSubmitting}) => {
        setSubmitting(true);
        const customerController = new CustomerController(props.idBand);
        if (props.submitHandler) {
            const doc = await customerController.create<Customer>({
                salutation: values.salutation,
                tel: values.tel,
                email: values.email,
                idBand: props.idBand,
                address: values.address,
                name: values.name,
                surname: values.surname,
                company: values.company
            });
            if (props.toggle) {
                props.toggle();
            }
            if (doc && props.submitHandler) {
                await props.submitHandler(doc.id);
            }
            toast.success(`New Customer ${values.name} ${values.surname} created!`);
            return;
        } else {
            if (props.toggle) {
                props.toggle();
            }
            if (props.newCustomer) {
                await customerController
                    .create({
                        salutation: values.salutation,
                        company: values.company,
                        tel: values.tel,
                        email: values.email,
                        idBand: props.idBand,
                        address: values.address,
                        name: values.name,
                        surname: values.surname,
                    });
                toast.success(`New Customer ${values.name} ${values.surname} created!`);
            } else if (props.customer && props.customer.id) {
                await customerController
                    .update(
                        props.customer.id,
                        new Customer({
                            id: props.customer.id,
                            idBand: props.customer.idBand,
                            salutation: values.salutation,
                            company: values.company,
                            surname: values.surname,
                            name: values.name,
                            address: values.address,
                            email: values.email,
                            tel: values.tel,
                        })
                    )
                    .catch(() => toast.error("Couldn't update customer"));
                toast.success(`Customer ${values.name} ${values.surname} updated!`);
            }
        }
        setSubmitting(false);
    },
})(InnerForm);

export default CustomerForm;
