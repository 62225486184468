import { InjectedFormikProps, withFormik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';
import { BlueButton } from '../../../../_common/_elements/index';
import { MenuItem, Select, Switch, TextField } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { TimeTableItem } from "../../../../../models/basics/TimeTableItem";
import LocationSearchInput from 'src/views/_common/_elements/LocationSearchInput';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { parseAddress } from 'src/utils/parseAddress';
import { RootStoreContext } from 'src/store/RootStore';
import { GigHubDialog } from 'src/views/_common/_elements/GigHubDialog';
import { Toggle } from 'src/views/_common/utilities';
import { GighubEvent } from 'src/models/gighubEvent';
import { BandAddress } from 'src/models/bandAddress';

interface FormValues {
    timeTableItem: TimeTableItem;
    title: string;
    timeStart: Date;
    timeEnd: Date;
    isRoute: boolean;
    routeStart: string;
    routeEnd: string;
}

interface FormProps {
    id?: string;
    event: GighubEvent;
    timeTableItem: TimeTableItem;
    handleSubmit: (timeTableItem: TimeTableItem, id?: string) => any;
    toggle: () => any;
    idBand: string;
}


const Form = styled.form`
  padding: ${props => props.theme.layout.settingsContainerPadding};
  > .btn {
    margin-top: 4rem;
  }
  color: black;
`;

const FormHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 1rem;
`;

const Spacer = styled.div`
  height: 1rem;
`;

const MyTextField = styled(TextField)`
  width: 100%;
`;


const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = ({
    handleSubmit,
    isSubmitting,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    event,
    timeTableItem,
    idBand
}) => {
    const {
        bandAddressesStore,
    } = React.useContext(RootStoreContext);
    const bandAdresses : BandAddress[] = bandAddressesStore.getByBand(idBand).get()
        .filter(
            bandAddress =>
                bandAddress &&
                bandAddress.name &&
                bandAddress.name.length > 0 &&
                bandAddress.address &&
                bandAddress.address.length > 0
        )

    return (
        <Form onSubmit={handleSubmit}>
            is Route?
            <Switch
                name={"isRoute"}
                checked={values.isRoute || undefined}
                // {..."Route?"}
                onChange={e => {
                    setFieldValue('isRoute', e.target.checked);
                }}
            />
            <DateTimePicker
                ampm={false}
                label={'Time start'}
                name="timeStart"
                value={values.timeStart}
                error={Boolean(errors.timeTableItem && touched.timeTableItem && touched.timeTableItem.timeStart && errors.timeTableItem.timeStart)}
                onChange={e => {
                    setFieldValue('timeStart', new Date(e!));
                    // if selected date is after timeEnd
                    if (new Date(e!) >= values.timeEnd) setFieldValue('timeEnd', new Date(e!));
                }}
            />
            {!values.isRoute &&
                <DateTimePicker
                    ampm={false}
                    label={'Time end'}
                    name="timeEnd"
                    value={values.timeEnd}
                    error={Boolean(errors.timeTableItem && touched.timeTableItem && touched.timeTableItem.timeEnd && errors.timeTableItem.timeEnd)}
                    onChange={e => {
                        setFieldValue('timeEnd', new Date(e!));
                        // if selected date is before timeStart
                        if (new Date(e!) <= values.timeStart) setFieldValue('timeStart', new Date(e!));
                    }}
                />
            }
            <TextField
                id="title"
                name="title"
                fullWidth
                label={errors.timeTableItem && touched.timeTableItem && errors.timeTableItem.title && touched.timeTableItem.title ? errors.timeTableItem.title : 'Title'}
                error={Boolean(errors.timeTableItem && touched.timeTableItem && touched.timeTableItem.title && errors.timeTableItem.title)}
                type="text"
                onChange={handleChange}
                value={values.title}
                InputLabelProps={{
                    shrink: true,
                }}
                placeholder="Getin Crew"
            />
            {values.isRoute &&
                <>
                    <Toggle>
                        {({ on, toggle }) => (
                            <>
                                <Spacer />
                                <MyTextField
                                    select
                                    label="Drive From"
                                    value={values.routeStart}
                                    InputProps={{
                                        name: 'routeStart',
                                        id: 'routeStart'
                                    }}
                                    onChange={(e: any) => {
                                        if (e.target.value === '<<<addcustomaddress>>>') {
                                            toggle();
                                        } else {
                                            setFieldValue(
                                                'routeStart',
                                                e
                                            );
                                            handleChange(e);
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    <MenuItem key={0} value={event.address.street + " " + event.address.streetnr + " " + event.address.zip + " " + event.address.city + " " + event.address.country}>
                                        {"Venue"}
                                    </MenuItem>
                                    {event.accommodation &&
                                        <MenuItem key={1} value={event.accommodation}>
                                            {"Hotel"}
                                        </MenuItem>
                                    }
                                    {bandAdresses
                                        .map(bandAddress => (
                                            <MenuItem key={bandAddress.id} value={bandAddress.address}>
                                                {bandAddress.name}
                                            </MenuItem>
                                        ))}
                                </MyTextField>
                                <Spacer />
                                <GigHubDialog open={on} onClose={toggle} />
                            </>
                        )}
                    </Toggle>

                    <Toggle>
                        {({ on, toggle }) => (
                            <>
                                <MyTextField
                                    select
                                    label="Drive To"
                                    value={values.routeEnd}
                                    InputProps={{
                                        name: 'routeEnd',
                                        id: 'routeEnd'
                                    }}
                                    onChange={(e: any) => {
                                        if (e.target.value === '<<<addcustomaddress2>>>') {
                                            toggle();
                                        } else {
                                            setFieldValue(
                                                'routeEnd',
                                                e
                                            );
                                            handleChange(e);
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    <MenuItem key={0} value={event.address.street + " " + event.address.streetnr + " " + event.address.zip + " " + event.address.city + " " + event.address.country}>
                                        {"Venue"}
                                    </MenuItem>
                                    {event.accommodation &&
                                        <MenuItem key={1} value={event.accommodation}>
                                            {"Hotel"}
                                        </MenuItem>
                                    }
                                    {bandAdresses
                                        .map(bandAddress => (
                                            <MenuItem key={bandAddress.id} value={bandAddress.address}>
                                                {bandAddress.name}
                                            </MenuItem>
                                        ))}
                                </MyTextField>
                                <Spacer />
                                <GigHubDialog open={on} onClose={toggle} />
                            </>
                        )}
                    </Toggle>

                    {values.timeTableItem && values.timeTableItem.distanceKm && values.timeTableItem.distanceH &&
                        <span>
                            distance: {values.timeTableItem.distanceKm}<br />
                            duration: {values.timeTableItem.distanceH}
                        </span>
                    }
                </>
            }
            <BlueButton className="btn" type="submit" disabled={isSubmitting}>
                Submit
            </BlueButton>
        </Form>
    )
};

const TimeTableItemForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: ({ timeTableItem }) => ({
        timeTableItem,
        title: timeTableItem && timeTableItem.title || "",
        timeStart: timeTableItem && timeTableItem.timeStart,
        timeEnd: timeTableItem && timeTableItem.timeEnd || timeTableItem && timeTableItem.timeStart,
        isRoute: timeTableItem && timeTableItem.isRoute || false,
        routeStart: timeTableItem && timeTableItem.routeStart || "",
        routeEnd: timeTableItem && timeTableItem.routeEnd || "",
        routeStartPre: "",
    }),
    validationSchema: Yup.object().shape({
        title: Yup.string()
            .required()
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(true);
        props.toggle();
        const newItem: any = new TimeTableItem({
            title: values.title,
            timeStart: values.timeStart,
            timeEnd: values.timeEnd,
            isRoute: values.isRoute,
            routeStart: values.routeStart,
            routeEnd: values.routeEnd
        });
        props.id ?
            props.handleSubmit(newItem, props.id) :
            props.handleSubmit(newItem)
            ;
        setSubmitting(false);
    },
})(InnerForm);

export default TimeTableItemForm;

interface InnerFormValues {
    address: string;
}

interface InnerFormProps {
    customAddress: string
}

const InnerCustomAddressForm: React.FC<InjectedFormikProps<InnerFormProps, InnerFormValues>> = ({
    handleSubmit,
    isSubmitting,
    values,
    setFieldValue,
}) => {
    return (
        <Form
            onSubmit={e => {
                e.stopPropagation();
                handleSubmit(e);
            }}
        >
            <LocationSearchInput

                initialAddressString={values.address}
                handleSelect={address => {
                    geocodeByAddress(address)
                        .then(results => getLatLng(results[0]))
                        .then(latLng => {
                            // todo hide key
                            fetch(
                                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng
                                }&key=AIzaSyCLaNc6hiUN1dwkwraI9JUb3FcAe1veqnY`
                            )
                                .then(res => res.json())
                                .then(data => {
                                    const { city, country, streetnr, street, lng, zip, lat } = parseAddress(data.results);
                                    setFieldValue(
                                        'routeEnd',
                                        street + " " + streetnr + " " + city + " " + country
                                    );
                                });
                        })
                        .catch(error => console.error('Error', error));
                }}
            />
            <BlueButton style={{ marginTop: '2rem' }} type="submit" disabled={isSubmitting}>
                Submit
            </BlueButton>
        </Form>
    )
};

const CustomAddressForm = withFormik<InnerFormProps, InnerFormValues>({
    mapPropsToValues: ({ customAddress }) => ({
        address: customAddress || '',
    }),
    validationSchema: Yup.object().shape({}),
    handleSubmit: async (values, { props, setSubmitting }) => {
        setSubmitting(true);

        setSubmitting(false);
    },
})(InnerCustomAddressForm);
