import styled from "styled-components";
import transparentize from "polished/lib/color/transparentize";


const TextButton = styled.button`
     cursor: pointer;
     background: none;
     outline: none;
     border: none;
     font-size: 1.5rem;
`;

export const PrimaryTextButton = styled(TextButton)`
    color: ${props => props.theme.colors.primary};
    border-bottom: 1px solid;
    border-color: currentColor;
     &:disabled {
        color: ${props => transparentize(.3, props.theme.colors.secondary)};
     }
`;

export const SecondaryTextButton = styled(TextButton)`
  color: ${props => props.theme.colors.secondary};
  border-bottom: 1px solid;
  border-color: ${props => props.theme.colors.secondary};
     &:disabled {
        color: ${props => transparentize(.3, props.theme.colors.primary)};
     }
`;

