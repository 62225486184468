import { Controller } from "./controller";
import { Collections } from "../models/basics/Collections";
import { Band } from "../models/band";
import { GighubNotification } from "../models/gighubNotification";
import { NotificationController } from "./notificationController";
import { User } from "../models/user";
import { MemberRoles } from "../models/basics/MemberRoles";
import { UserController } from "./userController";
import { gighubNotificationTexts } from "../models/basics/gighubNotificationTexts";
import { NotificationTypes } from "../models/basics/NotificationTypes";
import { toast } from "react-toastify";
import { UsersBandsController } from "./usersBandsController";
import { collection as col } from "firebase/firestore";
import { database as db } from "../database/fbApp";
import { Member } from "src/models/basics/member";
import { UsersBands } from "src/models/users-bands";


export class BandController extends Controller {

    constructor() {
        super(Collections.BANDS)
    }

    async notifyAllBandMembers(band: Band, msg: string) {
        Object.keys(band.members).forEach(idUser => (
            new NotificationController().create(new GighubNotification({ idRecipent: idUser, message: msg }))
        ));
    }

    async getAdminsAndEditors<T extends User>(band: Band) {
        const resolvedEditors = await Promise.all(Object.keys(band.members).map(async (idMember) => {
            if (band.members[idMember].role === MemberRoles.EDITOR || band.members[idMember].role === MemberRoles.ADMIN) {
                return await new UserController().get<User>(idMember) as T;
            } else {
                return null;
            }
        }));
        return resolvedEditors.filter(a => a !== null);
    }

    async deleteMember(band: Band, idMember: string) {
        try {
            const user = await new UserController().get<User>(idMember) as User;
            if (user && user.id && band.members) {
                let adminCount = 0
                Object.keys(band.members).forEach(m => {
                    if (band.members[m].role === MemberRoles.ADMIN) adminCount++
                })
                if (band.members && band.members[user.id] && band.members[user.id].role === MemberRoles.ADMIN && adminCount === 1) {
                    toast.error("You can't delete the last admin of this band");
                } else {
                    // User Update
                    if (await new UsersBandsController().deleteConnection(idMember, band.id)) {

                        delete band.members[idMember];
                        await new BandController().update<Band>(band.id, band);
                        await new NotificationController().notify({ id: idMember } as Partial<User>, "You have been removed from '" + band.name + "'", NotificationTypes.BANDRELATED, band.id, band)
                        toast.success("Removed user from your band");
                    }
                }
            } else {
                toast.error("Oops! Something went wrong");
            }
        } catch (e) {
            console.log(e)
        }
    }

    async swapInviteToMember(idBand: string, idNotification: string) {
        console.log("swapInviteToMember 0");
        await this.get<Band>(idBand).then(async (band) => {
            const currentUser = (window as any).gighub.CurrentUser;
            if (band && currentUser && currentUser.id && band.invites && band.invites[currentUser.email] && band.invites[currentUser.email].role) {
                const swap = {
                    id: currentUser.id,
                    role: band.invites[currentUser.email].role,
                    fullName: currentUser.fullName,
                    imageUrl: currentUser.imageUrl
                }
                band.members[currentUser.id] = swap as Member;
                delete band.invites[currentUser.email];
                try {
                    await new UsersBandsController().create(new UsersBands({ idUser: currentUser.id, idBand })).then(async () => {
                        await this.update<Band>(idBand, band).then(async () => {
                            await new NotificationController().delete(idNotification);
                            await new NotificationController().create(new GighubNotification({
                                message: gighubNotificationTexts.YOUJOINEDBAND + band.name,
                                type: NotificationTypes.YOUJOINEDBAND,
                                idReference: band.id,
                                idBand: band.id
                            }));
                            // Notify all admins and editors of the band
                            const adminsAndEditors = await this.getAdminsAndEditors<User>(band);
                            adminsAndEditors.forEach(async (adminOrEditor) => {
                                if (adminOrEditor && band.members[currentUser.id]) {
                                    await new NotificationController().notifyUserByEmail(adminOrEditor.email,
                                        currentUser.fullName + gighubNotificationTexts.JOINEDBAND + band.name + ' as ' + band.members[currentUser.id].role,
                                        NotificationTypes.JOINEDBAND, band.id, band);
                                }
                            });
                        }).catch(async (error) => {
                            console.log(error)
                        });
                    }).catch(async (error) => {
                        console.log(error)
                    });
                } catch (error) {
                    console.log("Update failed:", error);
                }
            } else {
                console.log("could not get band");
            }
        }).catch(async (error) => { console.log(error) });

    }

}
