import * as React from 'react';
import styled from "styled-components";

interface TopNavProps {
    text: string;
    boldText?: string;
    small?: boolean;
}

const BottomTextContainer = styled.div`
  display: flex;
  color: ${props => props.theme.colors.secondary};
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all .2s;
  margin-bottom: 1rem;
  &:hover {
    color: ${props => props.theme.colors.black};
  }
`;

const SmallText = styled.p`
  font-weight: lighter;
  font-size: ${props => (props as any).small ? '1.2rem' : '1.5rem'};
  line-height: 1.6;
`;

const BoldText = styled.strong`
  margin-left: .5rem;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.6;
`;

const BottomText = ((props: TopNavProps) => (
    <BottomTextContainer>
        <SmallText {...props}>{props.text}</SmallText>
        {props.boldText && <BoldText>{props.boldText}</BoldText>}
    </BottomTextContainer>
));


export default BottomText;