import {Model} from "./model";
import {Collections} from "./basics/Collections";

export class Formation implements Model {
    collection?: string;
    name: string;
    members?: string[];
    id?: string;
    idBand?: string;

    constructor(o:Formation){
        this.collection = Collections.FORMATIONS + "/" + o.idBand;
        this.id = o.id || "";
        this.idBand = o.idBand || '';
        this.members = o.members || [];
        this.name = o.name;
    }
}