import React, { FC, useContext } from 'react';
import styled, { withTheme } from "styled-components";
import { default as Icon, IconName, IconSize } from "../_elements/Icon";
import { Link } from "@reach/router";
import AvatarImageSmall from "../_elements/AvatarImage";
import { AppTheme } from 'src/theme';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from 'src/store/RootStore';

interface Props {
  icon?: IconName;
  image?: string;
  title: string;
  to: string;
  isactive?: number;
  notificationLabel?: boolean;
  notificationCount?: number;
  theme: AppTheme
}

const Tab = styled(Link)`
  //background: ${(props: any) => props.isactive ? props.theme.colors.primaryDark : props.theme.colors.primary};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
`;



const NotificationLabel = styled.div`
  position: absolute;
  top: 3px;
  left: calc(50% + (${IconSize.MEDIUM}/2) + 5px);
  transform: translateX(-50%);
  font-size: 1.5rem;
  width: 2rem;
  height: 2rem;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.danger};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabIcon: FC<Props> = observer(({ notificationCount, notificationLabel, ...props }) => {

  const { bandsStore: {observables: bands, selectedBand} } = useContext(RootStoreContext);
  

  return (
    <Tab {...props}>
      {notificationCount && notificationCount > 0 ? <NotificationLabel>{notificationCount}</NotificationLabel> : <></>}
      {
        props.icon ?
        <Icon {...props} name={props.icon} size={IconSize.MEDIUM} color={props.isactive ? props.theme.colors.black : props.theme.colors.grey} />
      :
            bands && bands.length > 0 && <AvatarImageSmall imgUrl={props.image} size="small" fallback={selectedBand && selectedBand.shorthand || ''} />
      }
    </Tab >
  )
})

export default withTheme(TabIcon);
