import React, { FC } from 'react';
import { useToggle } from 'src/hooks/useToggle';
import Fab from 'src/views/_common/_elements/Fab';
import styled from 'styled-components';
import {CustomerController} from "../../../../../controllers/customerController";
import {toast} from "react-toastify";
import { Dialog } from '@material-ui/core';
import { BandAddress as BandAddressModel } from 'src/models/bandAddress';
import BandAddress from './BandAddress';
import BandAddressForm from './BandAddressForm';
import { BandAddressController } from 'src/controllers/bandAddressController';


interface Props {
    idBand: string;
    bandAddresses: BandAddressModel[]
}

const Container = styled.div`
`;

const BandAddressSettings: FC<Props> = ({ bandAddresses, idBand }) => {
    async function handleDelete(id: string) {
        if (id && idBand) {
            try {
                await new BandAddressController(idBand).delete(id);
                toast.info("deleted address")
            } catch (e) {
                console.log(e)
            }
        }
    }

    const { on, toggle } = useToggle();
    return (
        <Container>
            {bandAddresses.map(bandAddress => <BandAddress
                key={bandAddress.id} idBand={idBand}
                bandAddress={bandAddress} handleDelete={handleDelete} />)}
            <Dialog open={on} onClose={toggle}>
                <BandAddressForm toggle={toggle} isNew={true} idBand={idBand} />
            </Dialog>
            <Fab type={'ADD'} onClick={toggle} />
        </Container>
    )
}

export default BandAddressSettings;