import {InjectedFormikProps, withFormik} from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import styled from "styled-components";
import {BlueButton} from "../../../../_common/_elements";
import {toast} from "react-toastify";
import {Formation} from "../../../../../models/formation";
import {FormationController} from "../../../../../controllers/formationController";
import {Band} from "../../../../../models/band";
import {FormControl, InputLabel, TextField, MenuItem, Checkbox, ListItemText, Select} from '@material-ui/core';
import OutlinedInput from '@mui/material/OutlinedInput';
import {Member} from "../../../../../models/basics/member";

interface FormValues {
    name: string;
    members: string[];
}

interface FormProps {
    toggle?: () => any;
    formation?: Formation;
    newFormation: boolean;
    band: Band;
    submitHandler?: (idCustomer: string) => any;
}

const Form = styled.form`
    padding: 2.5rem 3.5rem;
`;

const Spacer = styled.div`
    height: 1.5rem;
`;

const Field = styled(TextField)`
    && {
        > div {
            margin-top: 1.5rem;
        }
    }
`;


const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = ({
                                                                             errors,
                                                                             band,
                                                                             handleSubmit,
                                                                             isSubmitting,
                                                                             handleChange,
                                                                             values
                                                                         }) => (
    <Form onSubmit={(e) => {
        e.stopPropagation();
        handleSubmit(e)
    }}>
        <TextField
            name="name"
            type="text"
            label={errors.name ? errors.name : 'Name'}
            error={Boolean(errors.name)}
            value={values.name}
            onChange={handleChange}
            fullWidth
            required
        />
        <Spacer/>
        {band.members &&
            <FormControl style={{marginBottom: '4rem'}}>
                <InputLabel htmlFor="select-multiple-checkbox">Members</InputLabel>
                <Select
                    labelId="select-multiple-checkbox"
                    multiple
                    name='members'
                    value={values.members}
                    onChange={handleChange}
                    style={{color: 'white'}}
                    // input={<OutlinedInput label="Tag"/>}
                    renderValue={(selected: string[]) => selected.map(id => band.members[id].fullName).join(', ')}
                >
                    {Object.keys(band.members).map((memberId: string) => (
                        (band.members[memberId].fullName &&
                            <MenuItem key={memberId} value={memberId}>
                                <Checkbox checked={values.members.indexOf(memberId) > -1}/>
                                <ListItemText primary={band.members[memberId].fullName}/>
                            </MenuItem>)
                    ))}
                </Select>
            </FormControl>
        }
        <BlueButton type="submit" disabled={isSubmitting}>Submit</BlueButton>
    </Form>
);

const FormationForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: ({formation}) => ({
        name: formation && formation.name || '',
        members: formation && formation.members || [],
    }),
    validationSchema: Yup.object().shape({
            name: Yup.string().required('This is a required field')
        },
    ),
    handleSubmit: async (values, {props, setSubmitting}) => {
        setSubmitting(true);
        const formationController = new FormationController(props.band.id);
        if (props.submitHandler) {
            if (props.toggle) {
                props.toggle();
            }
            const doc = await formationController.create({
                name: values.name,
                members: values.members,
                idBand: props.band.id,
            });
            if (doc) {
                await props.submitHandler(doc.id);
                toast.success(`New Formation ${values.name}  created!`);
            } else {
                toast.error("an error occured")
            }

            return;
        } else {
            if (props.toggle) {
                props.toggle();
            }
            if (props.newFormation) {
                await formationController.create({
                    name: values.name,
                    members: values.members,
                    idBand: props.band.id,
                });
                toast.success(`New Formation ${values.name}  created!`)

            } else if (props.formation && props.formation.id) {
                await formationController.update(props.formation.id, new Formation({
                    id: props.formation.id,
                    idBand: props.formation.idBand,
                    name: values.name,
                    members: values.members,
                }));
                toast.success(`Formation ${values.name}  updated!`)
            }
        }
        setSubmitting(false);
    },
})(InnerForm);

export default FormationForm;