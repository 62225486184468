import React, { FC } from 'react';
import PaymentButton from '../../userSettings/PaymentButton';
import { Band } from 'src/models/band';
import styled from 'styled-components';
import PricingCard from 'src/views/_common/_elements/PricingCard';

interface Props {
    band: Band;
}

const Container = styled.div`

`;

const AdminSection: FC<Props> = ({ band }) => {

    return (
        <Container>
            <PricingCard band={band} /> 
            <br />
        </Container>
    )
}

export default AdminSection;