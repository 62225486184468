import { InjectedFormikProps, withFormik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';
import Select from '@material-ui/core/Select/Select';
import { Availability } from '../../../../../models/basics/availability';
import { AvailabilityStati } from '../../../../../models/basics/AvailabilityStati';
import AvatarImageSmall from '../../../../_common/_elements/AvatarImage';
import { fullnameToInitials, truncate } from '../../../../../utils/stringUtils';
import { SecondaryButton } from '../../../../_common/_elements';
import { FormControl, FormControlLabel, InputLabel, MenuItem, Switch } from '@material-ui/core';
import { Member } from '../../../../../models/basics/member';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { parseAddress } from '../../../../../utils/parseAddress';
import LocationSearchInput from '../../../../_common/_elements/LocationSearchInput';
import TextField from '@material-ui/core/TextField';

interface FormValues {
  availability: Availability;
}

interface FormProps {
  availability: Availability;
  member: Member;
  handleSubmit: (a: Availability) => any;
  toggle: () => any;
  handleDelete: (idUser: string) => any;
  idUser: string;
}

const Form = styled.form`
  padding: ${props => props.theme.layout.settingsContainerPadding};
  > .btn {
    margin-top: 4rem;
  }
`;

const FormHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 1rem;
`;

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = ({
  handleSubmit,
  isSubmitting,
  handleChange,
  values,
  member,
}) => (
  <Form onSubmit={handleSubmit}>
    <FormHeader>
      <AvatarImageSmall imgUrl={member.imageUrl} fallback={fullnameToInitials(member.fullName)} size="small" />
      <strong>{truncate(member.fullName, 18)}</strong>
    </FormHeader>
    <FormControl>
      <InputLabel htmlFor="availability.status">Status</InputLabel>
      <Select
        value={
          values.availability.status === AvailabilityStati.INVITED
            ? AvailabilityStati.ACCEPTED
            : values.availability.status
        }
        inputProps={{
          name: 'availability.status',
          id: 'availability.status',
        }}
        onChange={handleChange}
      >
        <MenuItem value={AvailabilityStati.ACCEPTED}>{AvailabilityStati.ACCEPTED}</MenuItem>
        <MenuItem value={AvailabilityStati.DECLINED}>{AvailabilityStati.DECLINED}</MenuItem>
        <MenuItem value={AvailabilityStati.PENDING}>{AvailabilityStati.PENDING}</MenuItem>
      </Select>
    </FormControl>
    <FormControlLabel
      label={'I need an accommodation'}
      control={
        <Switch
          id="availability.isInNeedOfAccommodation"
          name="availability.isInNeedOfAccommodation"
          checked={values.availability.isInNeedOfAccommodation}
          onChange={handleChange}
          value="availability.isInNeedOfAccommodation"
        />
      }
    />
    <FormControlLabel
      label={'no expenses'}
      control={
        <Switch
          id="availability.isFreeOfExpenses"
          name="availability.isFreeOfExpenses"
          checked={values.availability.isFreeOfExpenses}
          onChange={handleChange}
          value="availability.isFreeOfExpenses"
        />
      }
    />
    <LocationSearchInput
      light={true}
      value={values.availability.via}
      handleSelect={address => {
        geocodeByAddress(address)
          .then(results => getLatLng(results[0]))
          .then(latLng => {
            // todo hide key
            fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=AIzaSyCLaNc6hiUN1dwkwraI9JUb3FcAe1veqnY`
            )
              .then(res => res.json())
              .then(data => {
                const { city, country, streetnr, street, lng, zip, lat } = parseAddress(data.results);
                values.availability.via = street + ' ' + streetnr + ', ' + city + ' ' + country;
              });
          })
          .catch(error => console.error('Error', error));
      }}
    />
    <TextField
      id="availability.comment"
      name="availability.comment"
      fullWidth={true}
      label={'Comment'}
      type="textarea"
      onChange={handleChange}
      value={values.availability.comment}
      InputLabelProps={{
        shrink: true,
      }}
    />
    <SecondaryButton className="btn" type="submit" disabled={isSubmitting}>
      Submit
    </SecondaryButton>
  </Form>
);

const AvailabilityForm = withFormik<FormProps, FormValues>({
  mapPropsToValues: props => ({
    availability: props.availability,
  }),
  validationSchema: Yup.object().shape({}),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.toggle();
    props.handleSubmit(values.availability);
    setSubmitting(false);
  },
})(InnerForm);

export default AvailabilityForm;
