import { InjectedFormikProps, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';
import { EventStati } from '../../../../models/basics/EventStati';
import { Address } from '../../../../models/basics/address';
import { BlueButton, DangerButton } from '../../../_common/_elements';
import { GighubEvent } from '../../../../models/gighubEvent';
import { CustomerArray } from '../../../../models/basics/documentArray';
import 'input-moment/dist/input-moment.css';
import Toggle from '../../../_common/utilities/Toggle';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { parseAddress } from '../../../../utils/parseAddress';
import LocationSearchInput from '../../../_common/_elements/LocationSearchInput';
import { Customer } from '../../../../models/customer';
import CustomerForm from '../../../settings/bands/bandSettings/CustomerSettings/CustomerForm';
import {
    FormControl,
    FormControlLabel,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from '@material-ui/core';
import FormationForm from '../../../settings/bands/bandSettings/FormationSettings/FormationForm';
import { Band } from '../../../../models/band';
import { Formation } from 'src/models/formation';
import withStyles from '@material-ui/core/styles/withStyles';
import { AccountCircle } from '@material-ui/icons';
import { GigHubDialog } from '../../../_common/_elements/GigHubDialog';
import { DateTimePicker } from '@material-ui/pickers';
import { isBefore } from 'date-fns';
import { DeleteForm } from '../../../_common/utilities/DeleteForm';
import RichTextEditor, { EditorValue } from "react-rte";
import Label from "../../../_common/_elements/Label";
import moment from "moment";
import WYSIWYG, { WYSIWYGtoolbarConfig, WYSIWYGtoolbarConfigSlim } from "../../../_common/_elements/WYSIWYG";

interface StyleConfig {
    label: string;
    style: string;
    className?: string;
}

type StyleConfigList = StyleConfig[];
type GroupName =
    | "INLINE_STYLE_BUTTONS"
    | "BLOCK_TYPE_BUTTONS"
    | "LINK_BUTTONS"
    | "BLOCK_TYPE_DROPDOWN"
    | "HISTORY_BUTTONS"
    | "IMAGE_BUTTON";

interface ToolbarConfig {
    display: GroupName[];
    extraProps?: object;
    INLINE_STYLE_BUTTONS: StyleConfigList;
    BLOCK_TYPE_DROPDOWN: StyleConfigList;
    BLOCK_TYPE_BUTTONS: StyleConfigList;
}

interface FormValues {
    idCustomer: string;
    isSimple: boolean;
    title: string;
    locationName: string;
    dateStart: Date;
    dateEnd: Date;
    status: EventStati | string;
    comment: string;
    address: Address;
    idFormation: string;
    activeCustomer: Customer | null;
    deal: string;
    feeBilling: number;
    accommodation: string;
    autoInviteFormation: boolean;
    event?: GighubEvent;
    handleDelete?: () => void;
}

interface FormProps {
    band: Band;
    event?: GighubEvent;
    customerName?: string;
    customers: Customer[];
    formations: Formation[];
    canAddFormation: boolean;
    handleDelete?: () => void;
    handleSubmit: (event: GighubEvent, autoInviteFormation: boolean) => any;
}

const RedSwitch = withStyles({
    switchBase: {},
    checked: {},
    track: {
        backgroundColor: 'white',
    },
})(Switch);

const Form = styled.form`
  display: grid;
  grid-gap: 1rem;
  > .btn {
    margin-top: 4rem;
  }
  background: ${props => props.theme.colors.secondary};
  padding: 0 0.8rem;
  max-width: ${props => props.theme.breakpoints.desktop}px;
  margin: 0 auto;
  strong{
    font-weight: bold;
  }
`;

const SwitchContainer = styled.div`
  width: 95%;
  display: grid;
  grid-template-columns: 10fr 2fr;
  align-items: center;
  padding: 0.7rem;
`;

const FormSubTitle = styled.h3`
  font-weight: bold;
  margin-bottom: 1rem;
  &:not(:first-of-type) {
    margin-top: 4rem;
  }
`;

const Spacer = styled.div`
  height: 1rem;
`;

const FormSection = styled.div`
  background: ${props => props.theme.colors.white};
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
`;

const SubmitButton = styled(BlueButton)`
  && {
    margin-top: 1rem;
  }
`;

const FormLabel = styled(Label)`
  &&{
    color: ${props => props.theme.colors.darkGrey};
    font-size: 1.4rem;
  }
`;

async function mapJsonToGighubEvent(json: any): Promise<GighubEvent> {
    const mapped: Partial<GighubEvent> = {};

    const statusMapping: Record<string, EventStati> = {
        "1": EventStati.PENDING,
        "2": EventStati.CONFIRMED,
        "3": EventStati.CANCELLED,
        "5": EventStati.PENDING, // "nichts mehr gehoert" translated to PENDING status
        // add more mapping here
    };


    // Map known fields
    mapped.title = (json.id_anlass === 2 ? 'Hochzeit: ' : 'Event: ') + json.gemeinde;
    mapped.locationName = json.location + ', ' + json.gemeinde;
    mapped.dateStart = new Date(json.datum + ', ' + (json.getin || '13:00'));
    mapped.dateEnd = new Date(json.datum_ende + ',  23:00');
    mapped.accommodation = json.unterkunft || '';
    mapped.deal = 'Gage: ' + json.gage + '\n\n Musik: ' + json.musik;
    mapped.feeBilling = 0; // TO DO: Convert json.gage or some other field to a number
    mapped.settled = json.abgerechnet === 1;
    mapped.contract = json.vertrag === 1;
    mapped.comment += json.kommentar + '<br><br>';
    mapped.status = statusMapping[json.id_status] || EventStati.PENDING; // or any other default value
    const notMapped = ['user_info', 'musik', 'gage', 'kunde', 'kunde_tel', 'kunde_email', 'id_formation', 'brautpaar', 'besetzung']

    // Map remaining fields to the comment field
    for (const [key, value] of Object.entries(json)) {
        if (notMapped.includes(key) && value && value !== undefined) {
            mapped.comment += `<b>${key}</b>: ${value} <br><br>`;
        }
    }
    mapped.comment = mapped.comment && mapped.comment.replace(/undefined/g, '');


    // Return a new GighubEvent
    return new GighubEvent(mapped as GighubEvent);
}


const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = ({
    handleSubmit,
    isSubmitting,
    handleChange,
    handleDelete,
    values,
    errors,
    touched,
    setFieldValue,
    customers,
    formations,
    band,
    event
}) => {
    const [commentState, setCommentState] = useState<EditorValue>(values.comment ? RichTextEditor.createValueFromString(values.comment, 'html') : RichTextEditor.createEmptyValue())
    const [dealState, setDealState] = useState<EditorValue>(values.comment ? RichTextEditor.createValueFromString(values.deal, 'html') : RichTextEditor.createEmptyValue())

    useEffect(() => {
        setCommentState(values.comment ? RichTextEditor.createValueFromString(values.comment, 'html') : RichTextEditor.createEmptyValue())
        setDealState(values.comment ? RichTextEditor.createValueFromString(values.deal, 'html') : RichTextEditor.createEmptyValue())
    }, [values.comment])

    const [jsonInput, setJsonInput] = useState<string>('');

    /* useEffect(() => {
        const mapEvent = async () => {
            try {
                const parsedJson = JSON.parse(jsonInput);
                const eventFromJson = await mapJsonToGighubEvent(parsedJson);
                for (const key in eventFromJson) {
                    if (Object.prototype.hasOwnProperty.call(eventFromJson, key)) {
                        setFieldValue(key, eventFromJson[key]);
                    }
                }
            } catch (error) {
                console.log('Invalid JSON format', error);
            }
        }
        mapEvent();
    }, [jsonInput]); // This effect runs whenever jsonInput changes */


    return (
        <Form onSubmit={handleSubmit} id={"gighubform"}>
            {/* <textarea
                value={jsonInput}
                onChange={(e) => setJsonInput(e.target.value)}
                placeholder="Paste JSON here"
    /> */}
            <FormSection style={{ marginTop: '1rem' }}>
                <FormSubTitle>Formation & Customer</FormSubTitle>
                <Toggle>
                    {({ on, toggle }) => (
                        <>
                            <TextField
                                select
                                label="Formation"
                                value={values.idFormation}
                                InputProps={{
                                    name: 'idFormation',
                                    id: 'idFormation',
                                }}
                                onChange={(e: any) => {
                                    if (e.target.value === '<<<addformation>>>') {
                                        toggle();
                                    } else {
                                        handleChange(e);
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                                <MenuItem key={'add'} value={'<<<addformation>>>'} onSelect={toggle}>
                                    Add Formation
                                </MenuItem>
                                {formations
                                    .filter(
                                        formation =>
                                            formation &&
                                            formation.name &&
                                            formation.name.length > 0 &&
                                            formation.members &&
                                            formation.members.length > 0 &&
                                            formation.idBand === band.id
                                    )
                                    .map(formation => (
                                        <MenuItem key={formation.id} value={formation.id}>
                                            {formation.name}
                                        </MenuItem>
                                    ))}
                                <Spacer />
                            </TextField>
                            <GigHubDialog open={on} onClose={toggle}>
                                <FormationForm
                                    band={band}
                                    newFormation={true}
                                    toggle={toggle}
                                    submitHandler={(idFormation: string) => setFieldValue('idFormation', idFormation)}
                                />
                            </GigHubDialog>
                        </>
                    )}
                </Toggle>
                <Spacer />
                {!event && values.idFormation && (
                    <SwitchContainer>
                        <FormControlLabel
                            label={'Auto invite all members of that formation'}
                            control={
                                <RedSwitch
                                    id="autoInviteFormation"
                                    name="autoInviteFormation"
                                    checked={values.autoInviteFormation}
                                    onChange={handleChange}
                                    value="autoInviteFormation"
                                    color="secondary"
                                />
                            }
                        />
                    </SwitchContainer>
                )}
                <Spacer />
                <Toggle>
                    {({ on, toggle }) => (
                        <>
                            <TextField
                                select
                                label="Customer"
                                value={values.idCustomer}
                                InputProps={{
                                    name: 'idCustomer',
                                    id: 'idCustomer',
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e: any) => {
                                    if (e.target.value === '<<<addcustomer>>>') {
                                        toggle();
                                    } else {
                                        handleChange(e);
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                                <MenuItem key={'add'} value={'<<<addcustomer>>>'} onSelect={toggle}>
                                    Add Customer
                                </MenuItem>

                                {customers
                                    .filter(
                                        customer => customer && customer.idBand === band.id
                                    )
                                    .map((customer) => (
                                        <MenuItem key={customer.id} value={customer.id}>
                                            {customer.company && customer.company + ": "}{customer.surname && customer.surname + " "}{customer.name && customer.name}
                                        </MenuItem>
                                    ))}
                                <Spacer />
                            </TextField>
                            <GigHubDialog open={on} onClose={toggle}>
                                <CustomerForm
                                    idBand={band.id}
                                    newCustomer={true}
                                    toggle={toggle}
                                    submitHandler={(idCustomer: string) => setFieldValue('idCustomer', idCustomer)}
                                />
                            </GigHubDialog>
                        </>
                    )}
                </Toggle>
            </FormSection>
            <FormSection>
                <FormSubTitle>Basics</FormSubTitle>
                <TextField
                    id="title"
                    name="title"
                    fullWidth={true}
                    label={errors.title && touched.title ? errors.title : 'Title'}
                    error={Boolean(touched.title && errors.title)}
                    type="text"
                    onChange={handleChange}
                    value={values.title}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    placeholder="Sample Gig @ Zürich"
                />
                <Spacer />
                <DateTimePicker
                    ampm={false}
                    label={'Date start'}
                    name="dateStart"
                    value={values.dateStart}
                    error={Boolean(touched.dateStart && errors.dateStart)}
                    onChange={e => {
                        setFieldValue('dateStart', new Date(e!));
                        if (!values.dateEnd || isBefore(values.dateEnd, new Date(e!))) {
                            setFieldValue('dateEnd', new Date(new Date(e!).setHours(23, 0, 0, 0)));
                        }
                    }}
                />
                <Spacer />
                <DateTimePicker
                    ampm={false}
                    label={'Date end'}
                    error={Boolean(touched.dateEnd && errors.dateEnd)}
                    name="dateEnd"
                    value={values.dateEnd}
                    onChange={e => setFieldValue('dateEnd', new Date(e!))}
                />
                <Spacer />
                <TextField
                    id="locationName"
                    name="locationName"
                    fullWidth={true}
                    label={errors.locationName && touched.locationName ? errors.locationName : 'Location Name'}
                    error={Boolean(touched.locationName && errors.locationName)}
                    type="text"
                    onChange={handleChange}
                    value={values.locationName}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Spacer />
                <LocationSearchInput
                    initialAddress={event && event.address ? event.address : undefined}
                    handleSelect={address => {
                        geocodeByAddress(address)
                            .then(results => getLatLng(results[0]))
                            .then(latLng => {
                                // todo hide key
                                fetch(
                                    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng
                                    }&key=AIzaSyCLaNc6hiUN1dwkwraI9JUb3FcAe1veqnY`
                                )
                                    .then(res => res.json())
                                    .then(data => {
                                        const { city, country, streetnr, street, lng, zip, lat } = parseAddress(data.results);
                                        setFieldValue(
                                            'address',
                                            new Address({
                                                lat,
                                                zip,
                                                lng,
                                                street,
                                                streetnr,
                                                country,
                                                city,
                                            })
                                        );
                                    });
                            })
                            .catch(error => console.error('Error', error));
                    }}
                />
            </FormSection>
            <FormSection style={{ marginTop: '1rem' }}>
                <FormSubTitle>Additional Informations</FormSubTitle>
                <FormControl>
                    <InputLabel htmlFor="status">Status</InputLabel>
                    <Select
                        fullWidth={true}
                        value={values.status}
                        inputProps={{
                            name: 'status',
                            id: 'status',
                        }}
                        onChange={handleChange}
                    >
                        <MenuItem value={EventStati.PENDING}>{EventStati.PENDING}</MenuItem>
                        <MenuItem value={EventStati.CONFIRMED}>{EventStati.CONFIRMED}</MenuItem>
                        <MenuItem value={EventStati.CANCELLED}>{EventStati.CANCELLED}</MenuItem>
                    </Select>
                </FormControl>
                <Spacer />
                <FormLabel>Deal</FormLabel>
                <WYSIWYG
                    value={dealState}
                    onChange={(value) => {
                        setDealState(value);
                    }}
                    toolbarConfig={WYSIWYGtoolbarConfigSlim}
                    toolbarClassName="no-toolbar"
                    rootStyle={{ 'font-family': 'inherit' }}
                />
                <TextField
                    id="feeBilling"
                    name="feeBilling"
                    label={errors.feeBilling && touched.feeBilling ? errors.feeBilling : 'Fee (Billing)'}
                    error={Boolean(touched.feeBilling && errors.feeBilling)}
                    type="number"
                    onChange={handleChange}
                    value={values.feeBilling}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    id="accommodation"
                    name="accommodation"
                    label={errors.accommodation && touched.accommodation ? errors.accommodation : 'Accommodation'}
                    error={Boolean(touched.accommodation && errors.accommodation)}
                    type="text"
                    onChange={handleChange}
                    value={values.accommodation}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    placeholder="5 Star Hotel"
                />
                <Spacer />
                <FormLabel>Comment</FormLabel>
                <WYSIWYG
                    value={commentState}
                    onChange={(value) => {
                        setCommentState(value);
                    }}
                    toolbarConfig={WYSIWYGtoolbarConfig}
                    rootStyle={{ 'font-family': 'inherit' }}
                />
            </FormSection>
            <SubmitButton
                style={{ marginBottom: handleDelete ? '0' : '2rem' }}
                disabled={isSubmitting}
                className="btn"
                type="submit"
                onClick={() => {
                    values.comment = commentState.toString('html')
                    values.deal = dealState.toString('html')
                    if (document && document.getElementById("gighubform")) {
                        document!.getElementById("gighubform")!.scrollIntoView()
                    }
                }}
            >
                Save
            </SubmitButton>
            {event && handleDelete && (
                <Toggle>
                    {({ on, toggle }) => (
                        <>
                            <DeleteForm
                                open={on}
                                handleClose={toggle}
                                modelName={'Event'}
                                handleDelete={handleDelete}
                                model={event}
                            />
                            <DangerButton style={{ marginBottom: '1rem' }} type="button" color="secondary"
                                onClick={toggle}>
                                Delete this event
                            </DangerButton>
                        </>
                    )}
                </Toggle>
            )}
        </Form>
    );
};
const EventForm = withFormik<FormProps, FormValues>({
    enableReinitialize: true,
    mapPropsToValues: props => ({
        idCustomer: (props.event && props.event.idCustomer) || (props.customers && props.customers.length > 0 && props.customers && props.customers[0] && props.customers[0].id) || "",
        idFormation: (props.event && props.event.idFormation) || (props.formations && props.formations.length > 0 && props.formations && props.formations[0] && props.formations[0].id) || "",
        customerName: (props.event && props.customerName) || '',
        isSimple: (props.event && props.event.isSimple) || false,
        title: (props.event && props.event.title) || '',
        locationName: (props.event && props.event.locationName) || '',
        dateStart: (props.event && props.event.dateStart) || moment("5:00:00 PM", "HH:mm:SS A").toDate(),
        dateEnd: (props.event && props.event.dateEnd) || moment("11:00:00 PM", "HH:mm:SS A").toDate(),
        status: (props.event && props.event.status) || EventStati.PENDING,
        comment: (props.event && props.event.comment) || '',
        address: (props.event && props.event.address) || new Address({}),
        customers: (props.customers && props.customers.length > 0) || new CustomerArray(),
        activeCustomer: (props.event && props.event.idCustomer && props.customers && props.customers[props.event.idCustomer]) || (props.customers && props.customers.length > 0 && props.customers && props.customers[0]) || null,
        deal: (props.event && props.event.deal) || '',
        feeBilling: (props.event && props.event.feeBilling) || 0,
        accommodation: (props.event && props.event.accommodation) || '',
        autoInviteFormation: false,
        event: props.event,
        handleDelete: props.handleDelete,
    }),
    validationSchema: Yup.object().shape({
        title: Yup.string()
            .required()
            .max(60, "Field shouldn't be longer than 60 characters"),
        dateStart: Yup.date().required(),
        dateEnd: Yup.date().required(),
        feeBilling: Yup.number().min(0)
    }),
    handleSubmit: async (values, { props, setSubmitting, setFieldError }) => {
        setSubmitting(true);
        if (isBefore(values.dateEnd, values.dateStart)) {
            setFieldError('dateEnd', "End date can't be before Start date");
            window.scrollTo(0, 0)
        } else {
            const {
                title,
                dateStart,
                dateEnd,
                address,
                idCustomer,
                isSimple,
                status,
                comment,
                deal,
                feeBilling,
                accommodation,
                locationName,
                idFormation,
            } = values;
            await props.handleSubmit(
                props.event
                    ? Object.assign(props.event, {
                        idBand: props.band.id,
                        idCustomer,
                        isSimple,
                        title,
                        locationName,
                        dateStart,
                        dateEnd,
                        status,
                        comment,
                        address,
                        deal,
                        feeBilling,
                        accommodation,
                        idFormation
                    })
                    : new GighubEvent({
                        idBand: props.band.id,
                        idCustomer,
                        isSimple,
                        title,
                        locationName,
                        dateStart,
                        dateEnd,
                        status,
                        comment,
                        address,
                        deal,
                        feeBilling,
                        accommodation,
                        idFormation
                    }),
                values.autoInviteFormation
            );
        }
        setSubmitting(false);
    },
})(InnerForm);

export default EventForm;
