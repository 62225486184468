import * as React from 'react';
import { FC, useContext } from 'react';
import styled from 'styled-components';
import AvatarImage from '../../../_common/_elements/AvatarImage';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from 'src/store/RootStore';
import {Link, navigate} from '@reach/router';
import { Routes } from '../../../../constants/Routes';
import useWindowSize from 'src/hooks/useWindowSize';
import {BlueButton} from "../../../_common/_elements";

const Container = styled.div`
  display: grid;
  grid-column-gap: 2.5rem;
  grid-auto-flow: column;
  overflow-x: auto;
`;

const ImageContainer = styled('div')<{ marginLeft: boolean }>`
  display: grid;
  grid-row: auto auto;
  align-content: start;
  margin-left: ${props => (props.marginLeft ? '1rem' : 0)};
  > h4 {
    margin-top: 0.75rem;
    text-align: center;
    cursor: pointer;
  }
`;

const YourBands: FC = observer(() => {
  const {
    bandsStore: { observables: bands, setSelectedBand },
  } = useContext(RootStoreContext);
  const { width } = useWindowSize();
  const itemWidth = 120;

  return (
    <Container>
      {width &&
        bands.length > 0 ? bands.map(band => (
          <ImageContainer
            marginLeft={bands.length * itemWidth < width}
            key={band.id}
            onClick={() => {
              setSelectedBand(band.id);
              navigate(Routes.EVENTS);
            }}
          >
            <AvatarImage imgUrl={band.imageUrl} fallback={band.shorthand} size="medium" />
          </ImageContainer>
        )) : <>
        <Link style={{ width: '100%' }} to={Routes.BAND_SETTINGS}>
          <BlueButton>Create a Band!</BlueButton>
        </Link>
      </>}
    </Container>
  );
});

export default YourBands;
