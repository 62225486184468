import {Controller} from "./controller";
import {Collections} from "../models/basics/Collections";
import {getDocs, query, where} from "firebase/firestore";


export class UsersBandsController extends Controller {
    constructor() {
        super(Collections.USERSBANDS);
    }

    async deleteConnection(idUser: string, idBand: string) {
        try {
            let q = query(this.subscribe())
            q = query(q, where("idUser", "==", idUser))
            q = query(q, where("idBand", "==", idBand))
            const res = await getDocs(q);
            if (res && res.docs) {
                res.docs.map((doc)=>{
                    this.delete(doc.id)
                })
            }
            return true;
        } catch (e) {
            console.log(e)
            return false;
        }
    }

}