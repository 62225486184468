import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { Address } from '../../../models/basics/address';
import TextFieldLight from './TextFieldLight';
import {TextInput} from "./index";

// hack to fix types of places auto complete
const Places: any = PlacesAutocomplete;

interface Props {
  handleSelect: (address: string) => any;
  value?: string;
  label?: string;
  light?: boolean;
  initialAddress?: Address;
  initialAddressString?: string;
}

const Suggestions = styled.div`
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  background: white;
  color: rgba(0, 0, 0, 0.75);
  cursor: pointer;
  padding: 1rem 2rem;
  line-height: 1.7;
  display: grid;
  grid-gap: 0.5rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.1);
`;

const LocationSearchInput: FC<Props> = props => {
  const [address, setAddress] = useState<string>('');
  const [suggestionsOpen, setSuggestionsOpen] = useState(false);
  const [touched, setTouched] = useState(false);

  const { initialAddress, initialAddressString, handleSelect, label } = props;
  useEffect(() => {
    if (initialAddress) {
      setAddress(
        (initialAddress.street ? initialAddress.street + ' ' : '') +
          (initialAddress.streetnr ? initialAddress.streetnr + ' ' : '') +
          (initialAddress.zip ? initialAddress.zip + ' ' : '') +
          (initialAddress.city ? initialAddress.city + ' ' : '') +
          (initialAddress.country ? initialAddress.country + ' ' : '')
      );
    } else if(initialAddressString){
      setAddress(initialAddressString)
    }
  }, [initialAddress]);

  useEffect(() => {
    if (address.length > 0 && touched) {
      if (suggestionsOpen) {
        setSuggestionsOpen(false);
      } else {
        setSuggestionsOpen(true);
      }
    }
  }, [address]);

  function handleChange(newAddress: string) {
    setAddress(newAddress);
    setSuggestionsOpen(false);
  }

  return (
    <Places
      onSelect={(newAddress: string) => {
        setAddress(newAddress);
        handleSelect(newAddress);
      }}
      value={address}
      onChange={handleChange}
      inputProps=""
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
        <div>
          {props.light ? (
            <TextInput
              onFocus={() => setTouched(true)}
              id="locationInput"
              name="locationInput"
              label={label || 'Address'}
              fullWidth={true}
              value={address}
              {...getInputProps({
                placeholder: 'Search places...',
                className: 'location-search-input',
              })}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder={'Samplestreet 42, 8989 Somewhere'}
            />
          ) : (
            <TextField
              onFocus={() => setTouched(true)}
              id="locationInput"
              name="locationInput"
              label={label || 'Address'}
              fullWidth={true}
              value={address}
              {...getInputProps({
                placeholder: 'Search places...',
                className: 'location-search-input',
              })}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder={'Samplestreet 42, 8989 Somewhere'}
            />
          )}
          {address.length > 0 && suggestionsOpen && (
            <Suggestions {...props}>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion: any) => {
                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';

                return (
                  <div
                    key={'unique_key_required_for_this'}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </Suggestions>
          )}
        </div>
      )}
    </Places>
  );
};

export default LocationSearchInput;
