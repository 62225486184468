import {Model} from "./model";
import {Collections} from "./basics/Collections";
import {EventStati} from "./basics/EventStati";
import {auth} from "../database/fbApp";
import {Address} from "./basics/address";
import {TimeTableItem} from "./basics/TimeTableItem";
import {BandStati} from "./basics/BandStati";
import {Availability} from "./basics/availability";
import { GuestlistEntry } from "./basics/GuestlistEntry";

export class GighubEvent implements Model {
    collection?: string;
    id?: string;

    idBand: string;
    idCustomer: string;
    title: string;
    locationName: string;
    dateStart: Date;
    address: Address;
    dateEnd: Date;
    dateCreated?: Date;
    idFormation?: string;
    dateEdited?: Date;
    idCreator?: string;
    idEditor?: string;
    status?: EventStati | string;
    bandStatus?: BandStati;
    isSimple?: boolean;
    comment?: string;
    accommodation?: string;
    deal?: string;
    feeBilling?: number;
    fee?: string;
    timeTable?: { [id: string]: TimeTableItem };
    availabilities?: { [idUser: string]: Availability };
    guestlist?: GuestlistEntry[];
    settled?: boolean;
    contract?: boolean;
    uploadedDocs?: Array<{ name: string; url: string; public_id: string }>;
    // for Date Comparison in DB:
    timestamp?: number;
    bookingStatus?: string;



    constructor(o: GighubEvent) {
        this.collection = Collections.BANDS + "/" + o.idBand;
        this.idBand = o.idBand;
        this.idFormation = o.idFormation || '';
        this.title = o.title;
        this.idCustomer = o.idCustomer;
        this.locationName = o.locationName;
        this.dateStart = new Date(o.dateStart);
        this.dateEnd = new Date(o.dateEnd) || new Date(o.dateStart);
        this.address = o.address || new Address({});
        this.id = o.id || "";
        this.dateEdited = o.dateEdited || new Date();
        this.idEditor = o.idEditor || (auth.currentUser ? auth.currentUser.uid : "");
        this.status = o.status || EventStati.PENDING;
        this.bandStatus = o.bandStatus || BandStati.INCOMPLETE;
        this.isSimple = o.isSimple || false;
        this.comment = o.comment || "";
        this.accommodation = o.accommodation || "";
        this.deal = o.deal || "";
        this.feeBilling = o.feeBilling || 0;
        if(o.fee)this.deal = o.fee;
        this.settled = o.settled || false;
        this.contract = o.contract || false;
        this.timeTable = o.timeTable || {};
        this.uploadedDocs = o.uploadedDocs || [];
        this.bookingStatus = o.bookingStatus || "";
        if (!o.dateCreated) {
            this.dateCreated = new Date();
            this.idCreator = (auth.currentUser ? auth.currentUser.uid : "");
        }
        if (o.availabilities) {
            this.availabilities = o.availabilities;
        }
        if (o.guestlist) {
            this.guestlist = o.guestlist;
        } else {
            this.guestlist = [];
        }
    }

}