import React, {FC, SyntheticEvent} from 'react';
import styled from 'styled-components';
import Title from '../../pages/_elements/Title';
import SubTitle from '../../pages/_elements/SubTitle';
import AvatarImageSmall from '../../_common/_elements/AvatarImage';
import {Links} from '../../../constants/Routes';
import {navigate} from '@reach/router';
import {Band} from 'src/models/band';
import {canWriteBand} from "../../../models/basics/MemberRoles";
import {IconName} from "../../_common/_elements/Icon";
import {Icon} from "../../_common/_elements";

interface Props {
    band: Band;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  grid-column-gap: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${props => props.theme.colors.secondary};
  &:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BandTitle = styled(Title)`
  color: ${props => props.theme.colors.secondary};
`;

const BandWrapper: FC<Props> = ({band, band: {id, shorthand, name, imageUrl}}) => {

    return (
        <Container onClick={() => canWriteBand(band) && navigate(Links.BAND_SETTINGS + id)}>
            <AvatarImageSmall imgUrl={imageUrl} size="small" fallback={shorthand}/>
            <TitleContainer>
                <BandTitle>{name}</BandTitle>
                <SubTitle>{shorthand}</SubTitle>
            </TitleContainer>
            {canWriteBand(band) && <Icon name={IconName.SETTINGS}/>}
        </Container>
    );
};

export default BandWrapper;
