import React, { FC, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import Icon, { IconName, IconSize } from '../_elements/Icon';
import { Routes, Links } from 'src/constants/Routes';
import { Band } from 'src/models/band';
import { RootStoreContext } from 'src/store/RootStore';
import { observer } from 'mobx-react-lite';
import { useToggle } from 'src/hooks/useToggle';
import SearchDialog from '../SearchDialog';
import { RouteComponentProps, NavigateFn } from '@reach/router';
import BandSelect from '../_elements/BandSelect';

interface Props {
  sidebarToggleHandler: () => any;
  sidebarOpen: boolean;
  navigate: NavigateFn;
}

const Header = styled.header`
  padding: 0 1rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => props.theme.layout.headerHeight};
  z-index: 999;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 2rem;
  justify-content: space-between;
  background: ${props => props.theme.colors.background};
  align-items: center;
`;

const MenuIcon = styled(Icon)`
  display: ${(props: any) => (props.sidebarOpen ? 'none' : 'block')};
`;

const SearchIcon = styled(Icon)`
  transform: rotate(180deg);
  fill: #7a7a7a;
  grid-column: -1;
  margin-right: 1rem;
  cursor: pointer;
`;

const MobileHeader: FC<Props> = observer(props => {
  const [currentPage, setCurrentPage] = useState('');
  const { on, toggle } = useToggle();

  useEffect(() => {
    setCurrentPage(window.location.pathname);
  }, [currentPage]);

  return (
    <Header>
      <MenuIcon
        {...props}
        color="#090909"
        name={IconName.GIGHUB_LOGO}
        testId="gighub_logo" 
        size={IconSize.XL}
        onClick={props.sidebarToggleHandler}
      />
      {(currentPage === Routes.EVENTS || currentPage === Links.EVENTS) && <BandSelect />}
      <SearchIcon name={IconName.SEARCH} size={IconSize.LARGE} onClick={toggle} />
      {on && <SearchDialog open={on} toggle={toggle} navigate={props.navigate} />}
    </Header>
  );
});

export default MobileHeader;
