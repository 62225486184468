import * as React from 'react';
import styled from 'styled-components';
import Icon, { IconName } from './Icon';

interface TopNavProps {
  text?: string;
  testId?: string;
  clickHandler?: () => any;
}

const TopNavContainer = styled.div`
  height: ${props => props.theme.layout.headerHeight};
  display: flex;
  align-items: center;
  padding: 0 1rem;
  justify-content: space-between;
`;

const Label = styled.p`
  color: ${props => props.theme.colors.secondary};
`;

const BackButton = styled(Icon)`
  text-align: center;
  cursor: pointer;
`;

const TopNav = (props: TopNavProps) => (
  <TopNavContainer {...props}>
    {props.clickHandler ? (
      <BackButton
        testId={props.testId || ''}
        onClick={props.clickHandler}
        name={IconName.BACK}
      />
    ) : (
      <BackButton testId={props.testId || ''} name={IconName.BACK} />
    )}
    <Label>{props.text ? props.text : ''}</Label>
  </TopNavContainer>
);

export default TopNav;
