import {Model} from "./model";
import {Collections} from "./basics/Collections";

export class BandAddress implements Model {
    collection?: string;
    id?: string;
    idBand: string;
    name: string;
    address: string;

    constructor(o:BandAddress){
        this.collection = Collections.BANDS + "/" + o.idBand;
        this.id = o.id || ""
        this.idBand = o.idBand;
        this.name = o.name;
        this. address = o.address
    }
}