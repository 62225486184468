import {database as db} from "../database/fbApp";
import {Model} from "../models/model";
import {deepCopy} from "../utils/deepCopy";
import {
    collection as col,
    query,
    where,
    getDocs,
    doc,
    getDoc,
    addDoc,
    updateDoc,
    deleteDoc
} from "firebase/firestore";
import {GighubEvent} from "../models/gighubEvent";
import {Collections} from "../models/basics/Collections";


export abstract class Controller {
    collection: string;
    parentCollection?: Collections
    scope: any;

    protected constructor(collection: Collections, parentCollection?: Collections, idParent?: string) {
        this.collection = collection
        if (idParent && parentCollection) {
            this.parentCollection = parentCollection
            this.scope = col(db, this.parentCollection, idParent, this.collection);
        } else {
            this.scope = col(db, this.collection)
        }
    }

    async create<T extends Model>(data: T) {
        const {collection, id, ...model} = data;
        return await addDoc(this.scope, deepCopy(model));
    };

    async getFirebaseDoc(id: string) {
        return doc(this.scope, id);
    }

    async get<T extends Model>(id: string) {
        try {
            const ref = await getDoc(await this.getFirebaseDoc(id));
            if (ref.exists()) {
                const o = ref.data() as T;
                o.id = ref.id;
                return o;
            } else {
                console.log("doc does not exist")
                return null;
            }
        } catch (e) {
            console.log(e)
            console.log("fehler get von bei", id, this.collection, this.parentCollection)
            return null;
        }
    };

    subscribe() {
        return this.scope
    }

    subscribeQ() {
        return query(this.subscribe())
    }

    async update<T extends Model>(uid: string, o: T) {
        const {collection, id, ...model} = o as Model;
        return updateDoc(await this.getFirebaseDoc(uid), deepCopy(model as any)).catch(err => {
           console.log(err)
        });
    }

    async delete(id: string) {
        return deleteDoc(await this.getFirebaseDoc(id))
    }

    async getAllNew() {
        /* const subColRef = col(db, Collections.BANDS, "RemRw3xODxgE9J1VWPvP", Collections.FORMATIONS);
        const qSnap = await getDocs(subColRef)
        console.log(qSnap.docs.map(d => ({id: d.id, ...d.data()}))) */
    }

    async getWithWhereQ<T extends Model>(fieldName: keyof T, value: string, orderBy?: any) {
        if (orderBy) {
            return query(this.scope, where(fieldName.toString(), "==", value), orderBy);
        } else {
            return query(this.scope, where(fieldName.toString(), "==", value));

        }
    }

    async getWithWhere<T extends Model>(fieldName: keyof T, value: string, orderBy?: any) {
        const q = await this.getWithWhereQ(fieldName, value, orderBy || undefined)
        return await getDocs(q);
    }

    async getByField<T extends Model>(fieldName: keyof T, value: string, scope?: any) {
        try {
            const res = await this.getWithWhere(fieldName, value);
            if (res.docs.length > 0) {
                return res.docs.map((u: any) => (
                    {...u.data(), id: u.id} as T
                )) as T[];
            }
        } catch (e) {
            console.log(e);
            console.log("fehler get von bei", fieldName, this.collection, this.parentCollection)
        }
        return null;
    };

    async getFirstByField<T extends Model>(fieldName: keyof T, value: string, scope?: any) {
        try {
            const res = await this.getWithWhere(fieldName, value, scope || null);
            if (res.docs.length > 0) {
                const d = res.docs[0].data() as T;
                d.id = res.docs[0].id;
                return d;
            }
        } catch (e) {
            console.log(e);
            console.log("fehler get von bei", fieldName, this.collection, this.parentCollection)
        }
        return null;
    };

    isCyclic(obj: GighubEvent) {
        const seenObjects: any = [];

        function detect(obj1: any) {
            if (obj1 && typeof obj1 === 'object') {
                if (seenObjects.indexOf(obj1) !== -1) {
                    return true;
                }
                seenObjects.push(obj1);
                for (const key in obj1) {
                    if (obj1.hasOwnProperty(key) && detect(obj1[key])) {
                        console.log(obj1, 'cycle at ' + key);
                        return true;
                    }
                }
            }
            return false;
        }

        return detect(obj);
    }
}
