import * as React from 'react';
import { FC, useContext, useEffect, useState } from 'react';
import DetailEventContainer from "../../_elements/DetailEventContainer";
import styled from "styled-components";
import { GighubEvent } from "../../../../models/gighubEvent";
import GeneralNote from "./GeneralNotes";
import { Band } from "../../../../models/band";
import { Customer } from "../../../../models/customer";
import { Formation } from "../../../../models/formation";
import Availabilities from "./availabilities/Availabilites";
import { RootStoreContext } from "../../../../store/RootStore";
import { UserController } from "../../../../controllers/userController";
import { User } from "../../../../models/user";
import * as moment from "moment";
import { MemberRoles, canWriteBand } from "../../../../models/basics/MemberRoles";
import { BillingInfos } from "../../../../models/billingInfos";
import { useToggle } from "../../../../hooks/useToggle";
import CustomerForm from "../../../settings/bands/bandSettings/CustomerSettings/CustomerForm";
import { Dialog, Icon } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import useFileUploader from 'src/hooks/useFileUploader';
import { EventController } from 'src/controllers/eventController';
import TimeTable from './timetable/TimeTable';

interface Props {
    event: GighubEvent;
    band: Band;
    billingInfos: BillingInfos | null;
    customer: Customer | null;
    formation: Formation | null;
    teaser?: boolean;
    fullInfo?: boolean | false;
    itinerary?: boolean | false;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1.5rem;
  margin-top: 2rem;
  strong {
    font-weight: bold;
  }
  p {
  }
`;

const UploadButton = styled(Icon)`
  position: absolute;
  top: 40%;
  left: 50%;
  opacity: 0.9;
  transform: translate3d(-50%, -50%, 0);
`;

const InfoContainer = styled.div`
  margin-top: 2rem;
`;

const Title = styled.h2`
  margin: 0;
`;

const ActionButton = styled.button`
  padding: 8px 16px;
  border: none;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
`;

const UploadedDocsContainer = styled.ul`
  margin-top: 1rem;
`;

const UploadedDocItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const DocName = styled.a`
  margin-right: 8px;
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const DeleteButton = styled.button`
  background-color: #dc3545;
  color: #fff;
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const Separator = styled.hr`
  margin-top: 3rem;
  margin-bottom: 2rem;
`;


const ItineraryDialog = styled(Dialog)`
    /* -webkit-print-color-adjust: exact; */
`;

const InformationPage: FC<Props> = ({ event, band, customer, formation, teaser, billingInfos, itinerary }) => {

    const { title, comment, dateStart, dateEnd, address, locationName, accommodation, deal, idEditor, dateEdited } = event;
    const [creator, setCreator] = useState<User | null>(null)
    const [editor, setEditor] = useState<User | null>(null)
    const [uploadedFiles, setUploadedFiles] = useState<Array<{ name: string; url: string; public_id: string }>>([]);

    const handleFileUploadSuccess = React.useCallback((newFile) => {
        console.log("schiss")
        setUploadedFiles((prevUploadedFiles: Array<{ name: string; url: string; public_id: string }>) => [...prevUploadedFiles, newFile]);

        // Automatically save the uploaded documents after the file upload is complete
        (async () => {
            await new EventController(band.id).updateUploadedDocs(
                event.id!,
                [...uploadedFiles, newFile]
            );
        })();
    }, []);

    const handleFileDelete = async (publicId: string) => {
        // Delete the file from the Cloudinary server
        /* (window as any).cloudinary.v2.uploader.destroy({
            cloudName: 'bassplayerch',
            uploadPreset: 'vi80oj9l'
        }, async (error: any, result: any) => {
            if (error) {
                alert("Error deleting file: " + error)
                return;
            }
            if (result.event === 'success') {

                // Remove the file from the GighubEvent object
                const updatedFiles = uploadedFiles.filter((file) => file.public_id !== publicId);
                setUploadedFiles(updatedFiles);

                // Update the event with the new uploadedDocs list
                await new EventController(band.id).updateUploadedDocs(event.id!, updatedFiles);
            }
        }); */
    };

    if (!teaser) {
        useFileUploader(
            {
                widgetOpenerSelector: '#upload-button',
                uploadSettings: {
                    folder: 'gig-docs',
                    allowedFormats: ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'],
                },
            },
            handleFileUploadSuccess
        );
    }


    useEffect(() => {
        setUsers()
    }, [])

    async function setUsers() {
        setCreator(await new UserController().get<User>(idEditor || ""))
        setEditor(await new UserController().get<User>(idEditor || ""))
    }

    const { on, toggle } = useToggle();

    function urlReplacer(str: string) {
        const match = str.match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig as RegExp) || [];
        let final = str;
        match.map((url: any) => {
            final = final.replace(url, "<a href=\"" + url + "\" target=\"_BLANK\">link</a>")
        })
        return final;
    }
    const fullInfo = false;
    const adressString = address.street + ' ' + address.streetnr + ' ' + address.zip + ' ' + address.city + ' ' + address.country

    return (
        <DetailEventContainer>
            {teaser && <Availabilities teaser={teaser} event={event} band={band} itinerary={itinerary} />}
            {(!teaser || itinerary) && comment && <GeneralNote title={title} text={urlReplacer(comment)} />}
            <Container>
                {!teaser && !comment && <>
                    <strong>Title:</strong>
                    <p>{title}</p>
                </>
                }
                {fullInfo && <>
                    <strong>Start:</strong>
                    <p>{moment(dateStart).format('DD. MMMM YYYY, HH:mm')}</p>
                    {dateEnd && (
                        <>
                            <strong>End:</strong>
                            <p>{moment(dateEnd).format('DD. MMMM YYYY, HH:mm')}</p>
                        </>
                    )}
                </>}
                {address && (
                    <>
                        {/*<strong>Location:</strong>
                        <p>{locationName}</p>*/}
                        <strong>Address:</strong>

                        <a href={'https://www.google.com/maps?q=' + adressString} target='_blank'>{adressString}</a>
                    </>
                )}
                {fullInfo && customer && <>
                    <strong>Contact:</strong>
                    <p>{customer.surname && customer.surname + ' '}{customer.name && customer.name}: {customer.tel && customer.tel}</p></>
                }
                {fullInfo && formation && <>
                    <strong>Formation:</strong>
                    <p>{formation.name}</p></>
                }
                {accommodation && <>
                    <strong>Accommodation:</strong>
                    <p>{accommodation}</p></>
                }
                {fullInfo && deal && canWriteBand(band) && <>
                    <strong>Deal:</strong>
                    <p dangerouslySetInnerHTML={{ __html: deal }} />
                </>
                }
                {!teaser && creator && <>
                    <strong>Created by:</strong>
                    <p>{creator.surname} {creator.name}</p>
                </>
                }
                {!teaser && editor && dateEdited && <>
                    <strong>Last edited by:</strong>
                    <p>{editor.surname} {editor.name} ({new Date(dateEdited).toLocaleDateString()})</p>
                </>
                }
            </Container>
            {customer && billingInfos && null && <>
                <strong>Invoice:</strong>
                <Link onClick={toggle}>generate</Link>
                <Dialog open={on} onClose={toggle}>
                    <h3 style={{ width: "70vw" }}>Your Invoice</h3>
                    {/* <DocumentRenderer
                            document={getPDFDocumentFromEvent(event, band, (formation && formation.name) || "", billingInfos, PDFDesigns.BILL_DESIGN_1)}
                            customer={customer}
                            billingInfos={billingInfos}
                band={band} />*/}
                </Dialog>
            </>
            }
            {(!teaser || itinerary) &&
                <>
                    <Separator />
                    {event.uploadedDocs && event.uploadedDocs.length > 0 && (
                        <>
                            <h3>Documents:</h3>
                            <UploadedDocsContainer>
                                {event.uploadedDocs.map((doc) => (
                                    <UploadedDocItem key={doc.public_id}>
                                        <DocName href={doc.url} target="_blank" rel="noopener noreferrer">
                                            {doc.name}
                                        </DocName>
                                        {!itinerary && <>
                                            <span>&nbsp;&nbsp;&nbsp;</span>
                                            <DeleteButton onClick={() => handleFileDelete(doc.public_id)}>
                                                Delete
                                            </DeleteButton>
                                        </>}
                                    </UploadedDocItem>
                                ))}
                            </UploadedDocsContainer>
                            <br />
                        </>
                    )}
                    {!itinerary &&
                        <div>
                            <Link
                                id="upload-button"
                            >Upload Document</Link>
                            <div id="upload_widget_opener" />
                        </div>
                    }
                </>
            }

            {!teaser && (<>
                <Separator />
                <ActionButton onClick={toggle}>Generate Itinerary</ActionButton>
                <ItineraryDialog open={on} onClose={toggle}>
                    <h2>{band.name} @ {event.title}</h2>
                    <InformationPage
                        teaser={true}
                        formation={formation}
                        event={event}
                        band={band}
                        customer={customer || null}
                        billingInfos={billingInfos}
                        itinerary={true}
                    />
                    <TimeTable event={event} authRole={MemberRoles.SUB} />
                </ItineraryDialog></>
            )
            }

        </DetailEventContainer >
    )
}


export default InformationPage;