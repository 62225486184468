import * as React from 'react';
import styled from "styled-components";
import {EventStati} from "../../../models/basics/EventStati";

export enum StatusColor {
    GREEN= "#baecc3",
    YELLOW = "#fcf197",
    RED = "#fcb0a3",
    GREENISH = "#70A4AA",
    BROWN = "#CCBBA7"
}

export enum StatusSizes {
    MEDIUM = "18px",
    SMALL = "7px"
}

interface StatusIconProps {
    status: EventStati;
    size: StatusSizes;
    children: any
}

const StatusCircle = styled.div`
  background: ${props => props.color};
  border-radius: 50%;
`;

const StatusIcon = (props: any) => (
    <StatusCircle {...props} style = {{
        width: props.size,
        height: props.size
    }} color={props.status === EventStati.CONFIRMED
        ? StatusColor.GREEN :
        props.status === EventStati.CANCELLED ?
            StatusColor.RED :
             StatusColor.YELLOW}

    >
        {props.children}
    </StatusCircle>
);

export default StatusIcon;