import React, {FC, useContext, useEffect, useState} from 'react';
import SettingsContainer from '../_elements/SettingsContainer';
import styled from 'styled-components';
import {Routes} from '../../../constants/Routes';
import {navigate} from '@reach/router';
import {RootStoreContext} from 'src/store/RootStore';
import {observer} from 'mobx-react-lite';
import SettingsTopNav from '../_elements/SettingsTopNav';
import {PrimaryButton} from '../../_common/_elements';
import {UserController} from "../../../controllers/userController";
import CopyToClipboard from 'react-copy-to-clipboard';
import {toast} from "react-toastify";
import DesktopSidebar from "../../_common/sidebar/DesktopSidebar";
import useIsDesktop from "../../../hooks/useIsDesktop";

interface Props {
    path: string;
}


const ICalContainer = styled.div`
  height: 100%;
  padding: 1.5rem;
  color: white;
`;

const ICal: FC<Props> = observer(() => {

    const {userStore: {currentUser}} = useContext(RootStoreContext);
    const [secretToken, setSecretToken] = useState<string>("")
    const isDesktop = useIsDesktop();

    useEffect(() => {
        getSecretToken();
    }, [])

    async function getSecretToken() {
        if (currentUser && currentUser.id) {
            const token = await new UserController().getSecretToken(currentUser.id)
            if (token) setSecretToken(token)
        }
    }

    async function createSecretToken() {
        if (currentUser && currentUser.id) {
            const res = await new UserController().createSecretToken(currentUser.id)
            if (res && res.id) {
                setSecretToken(res.id)
            }
        }
    }


    return (
        <SettingsContainer>
            {isDesktop && <DesktopSidebar/>}
            <div style={{width: '100%', marginTop: '2rem', position: 'relative'}}>
                {!isDesktop && <SettingsTopNav clickHandler={() => navigate(Routes.HOME)} text={'Link your calendar'}/>}
                <ICalContainer>
                    {(secretToken ?
                            <CopyToClipboard text={"https://gighub-calendar.onrender.com/" + secretToken}
                                             onCopy={() => toast("added link to clipboard")}>
                                <PrimaryButton>Copy link to clipboard</PrimaryButton>
                            </CopyToClipboard>
                            :
                            <PrimaryButton onClick={() => createSecretToken()}>Create calendar link</PrimaryButton>
                    )}
                    <>
                        <br/>
                        <p><strong>Google (Safe method):</strong> Open up your Google Calendar (Or create one if you
                            don't have one). Click on the +
                            button next to "Add a Friend's Calendar," then click "From URL."
                            Then, paste in your Gighub Calendar link and click "Add Calendar." Congratulations,
                            your Google Calendar is now synchronized with your Gighub database. Keep in mind,
                            after you sync your changes in Gighub, it may take a few hours for your Google Calendar to
                            reflect these changes due to the way Google pulls information from calendar feeds.

                            <strong>iOS:</strong> You can also directly import your Calendar-Link into your IOS
                            Calendar.
                            But by doing so, you are making it possible for others to read your calendar since the feed
                            will be crawled directly from your phone/machine and not from the google server.
                            This means that if someone is reading your traffic, they
                        </p>
                        <br/>
                        <br/>
                        <img width={"100%"}
                             src={"https://support.eventric.com/hc/article_attachments/360000890923/Screen_Shot_2018-03-15_at_12.25.34_PM.png"}/>
                    </>
                </ICalContainer>
            </div>
        </SettingsContainer>
    )
})


export default ICal;
