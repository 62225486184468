import { TextField} from "@material-ui/core";
import styled from "styled-components";
import * as React from 'react';

const TextFieldLight = styled(TextField)`
  && {
  &:hover {
  border: none;
  }
  &:not(:last-of-type) {
   margin-bottom: 0.5rem;
  }
  > label {
    color: ${props => props.error ? props.theme.colors.danger : props.theme.colors.secondary};
    font-size: 1.6rem;
    }
  > div input {
        color: ${props => props.theme.colors.secondary};
        font-size: 1.6rem;
        padding-top: 0.5rem;
    }
    > div::before, > div::after {
       border-color: ${props => props.error ? props.theme.colors.danger : props.theme.colors.secondary};
    }
  }
`;



export default TextFieldLight;