import * as React from 'react';
import {FC} from 'react';
import {default as Icon, IconName, IconSize} from "../../../../_common/_elements/Icon";
import {default as styled, withTheme} from "styled-components";
import {AppTheme} from "../../../../../theme";
import {transparentize} from 'polished';
import {canWrite, MemberRoles} from "../../../../../models/basics/MemberRoles";
import TimeTableItemForm from "./TimeTableItemForm";
import Toggle from "../../../../_common/utilities/Toggle";
import {truncate} from "../../../../../utils/stringUtils";
import {Dialog} from "@material-ui/core";
import {TimeTableItem} from "../../../../../models/basics/TimeTableItem";
import { GighubEvent } from 'src/models/gighubEvent';

interface Props {
    timeTableItem: TimeTableItem;
    id: string;
    theme: AppTheme;
    authRole: MemberRoles;
    handleSubmit: (timeTableItem: TimeTableItem, id?: string) => any;
    handleDelete: (id: string) => any;
    children: React.ReactNode;
    idBand: string;
    event: GighubEvent
}

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto auto 1fr auto auto;
  align-items: center;
  grid-column-gap: 1rem;
  padding: 1rem;
  &:first-of-type{
    margin-top: 1rem
  }
`;

const Time = styled.p`
  font-size: 1.5rem;
  color: ${props => props.theme.colors.darkGrey};
  :nth-of-type(2) {
    min-width: 60px;
  }
`;

const Text = styled.p`
  font-size: 1.5rem;
  padding-bottom: .2rem;
  padding-left: 1rem;
  align-items: left;
  text-align: left;
`;

const TimeTableItemView: FC<Props> = ({
                                          id,
                                          timeTableItem,
                                          theme,
                                          authRole,
                                          handleSubmit,
                                          handleDelete,
                                          idBand,
                                          event
                                      }) => (
    <Container>
        {timeTableItem.isRoute ?
            <Icon name={IconName.LOCATION} color={transparentize(0.2, theme.colors.black)}/>
            :
            <Icon name={IconName.CLOCK} color={transparentize(0.2, theme.colors.black)}/>
        }
        <Time>{new Date(timeTableItem.timeStart).toLocaleTimeString('de-DE', {
            hour: '2-digit',
            minute: '2-digit'
        })}</Time>
        <Time>
        {new Date(timeTableItem.timeEnd).toLocaleTimeString('de-DE', {
                hour: '2-digit',
                minute: '2-digit'
            }) !== new Date(timeTableItem.timeStart).toLocaleTimeString('de-DE', {
                hour: '2-digit',
                minute: '2-digit'
            }) &&
            <>- {new Date(timeTableItem.timeEnd).toLocaleTimeString('de-DE', {
                hour: '2-digit',
                minute: '2-digit'
            })}
            </>
        }</Time>
        <Text>{truncate(timeTableItem.title, 40)}</Text>
        {canWrite(authRole) && (
            <Toggle>
                {({toggle, on}) => (
                    <>
                        <Icon onClick={toggle} name={IconName.EDIT} size={IconSize.MEDIUM_SMALL}
                              color={transparentize(0.2, theme.colors.black)}/>
                        <Icon onClick={() => handleDelete(id)} name={IconName.DELETE}
                              color={transparentize(0.2, theme.colors.black)}/>
                        {on && <Dialog open={on} onClose={toggle}>
                            {
                                <TimeTableItemForm handleSubmit={handleSubmit}
                                                   toggle={toggle}
                                                   timeTableItem={timeTableItem}
                                                   id={id}
                                                   idBand={idBand}
                                                   event={event}
                                />
                            }
                        </Dialog>
                        }
                    </>
                )}
            </Toggle>
        )}
    </Container>
);

export default withTheme(TimeTableItemView);