import styled from 'styled-components';

const SettingsContainer = styled.div`
  background: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.settingsWhite};
  min-height: 100vh;
  display: flex;
`;

export default SettingsContainer;
