import React, { FC } from 'react';
import Sidebar from 'src/views/_common/sidebar/MobileSidebar';
import { useToggle } from 'src/hooks/useToggle';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { Icon, BlueButton } from 'src/views/_common/_elements';
import { IconName, IconSize } from 'src/views/_common/_elements/Icon';
import { Link, RouteComponentProps } from '@reach/router';
import { Routes } from 'src/constants/Routes';
import { rgba } from 'polished';

const Container = styled.div`
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  background: ${props => props.theme.colors.background};
  overflow-x: hidden;
  padding: 1rem 1rem;
`;

const Header = styled.header``;

const MenuIcon = styled(Icon)`
  display: ${(props: any) => (props.sidebarOpen ? 'none' : 'block')};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  text-align: center;

  h2 {
    margin-top: 2rem;
    font-weight: bold;
  }
  p {
    margin-top: 2rem;
  }
  button {
    margin-top: 2rem;
  }
`;

const Image = styled.div`
  background-image: linear-gradient(
      150.91deg,
      ${props => rgba(props.theme.colors.primary, 0.9)} -1.26%,
      ${props => rgba(props.theme.colors.primary, 0.9)} 45.89%,
      ${props => rgba(props.theme.colors.primaryLight, 0.9)} 100.63%
    ),
    url('https://res.cloudinary.com/bassplayerch/image/upload/c_scale,f_auto,h_1545,q_auto/v1530704427/gighub/ron-smith-328524-unsplash.jpg');
  background-size: cover;
  background-position-y: 53%;
  height: 30vh;
  width: 100%;
`;

const SuccessPage: FC<RouteComponentProps<{ idBand: string }>> = observer(
  ({ idBand }) => {
    const { on, toggle } = useToggle();

    return (
      <>
        <Sidebar open={on} toggle={toggle} />
        <Container>
          <Header>
            <MenuIcon
              testId="gighub_logo"
              name={IconName.GIGHUB_LOGO}
              size={IconSize.XL}
              onClick={toggle}
              color="#090909"
            />
          </Header>
          {idBand && (
            <Wrapper>
              <Image />
              <h2>Congratulations</h2>
              <p>Your Band now benefits from all the premium features.</p>
              <p>This might take a moment to apply</p>
              <Link style={{ width: '100%' }} to={Routes.HOME}>
                <BlueButton>Go Back</BlueButton>
              </Link>
            </Wrapper>
          )}
        </Container>
      </>
    );
  }
);

export default SuccessPage;
