import { auth } from "../../database/fbApp";
import { AvailabilityStati } from "./AvailabilityStati";
import { Model } from "../model";
import { GighubEvent } from "../gighubEvent";
import { Band } from "../band";
import { GighubNotification } from "../gighubNotification";
import { Customer } from "../customer";
import firebase from "firebase/compat/app";
import DocumentChange = firebase.firestore.DocumentChange;
import { Formation } from "../formation";
import { User } from '../user';

export class DocumentArray<T extends Model> extends Array<T> {

    constructor() {
        super();
        Object.setPrototypeOf(this, new.target.prototype);
    }

    getById(id: string) {
        return this.find(t => t.id === id) as T;
    }

    documentIndex(id: string) {
        return this.findIndex(t => t.id === id)
    }

    update(model: T, id: string): DocumentArray<T> {
        model.id = id;
        const objIndex = this.findIndex(t => t.id === model.id);
        if (objIndex > -1) {
        this[objIndex] = model;
               } else {
        this.push(model);
               }
        return this as DocumentArray<T>;
           }

}

export class EventArray extends DocumentArray<GighubEvent> {
    constructor() {
        super();
        Object.setPrototypeOf(this, new.target.prototype);
    }

    getParticipating(): EventArray {
        const participating = new EventArray();
        const authId = (auth.currentUser ? auth.currentUser.uid : "");
        this.forEach((event) => {
            if (event.availabilities !== undefined) {
                if (event.availabilities[authId] !== undefined) {
                    if (event.availabilities[authId]
                        .status === AvailabilityStati.ACCEPTED) {
                        participating.push(event)
                    }
                }
            }
        });
        return participating;
    }

    updateChange(change: DocumentChange): EventArray {
        const id = change.doc.id;
        const o = new GighubEvent(change.doc.data() as GighubEvent);
        o.id = id;
        if (change.type === 'removed') {
            const index = this.indexOf(o);
            if (index > -1) {
                this.splice(index, 1);
            }
        } else {
            const objIndex = this.findIndex(t => t.id === o.id);
            if (objIndex > -1) {
                this[objIndex] = o;
            } else {
                this.push(o);
            }
        }
        return this;
    }
}


export class BandArray extends DocumentArray<Band> {
    constructor() {
        super();
        Object.setPrototypeOf(this, new.target.prototype);
    }

}


export class NotificationArray extends DocumentArray<GighubNotification> {
    constructor() {
        super();
        Object.setPrototypeOf(this, new.target.prototype);
    }

    updateChange(change: DocumentChange): DocumentArray<GighubNotification> {
        const id = change.doc.id;
        const o = new GighubNotification(change.doc.data() as GighubNotification);
        o.id = id;
        if (change.type === 'removed') {
            return this.filter(item => item !== o) as NotificationArray
        } else {
            const objIndex = this.findIndex(t => t.id === o.id);
            if (objIndex > -1) {
                this[objIndex] = o;
            } else {
                this.push(o);
            }
        }
        return this as DocumentArray<GighubNotification>;
    }

    getNotificationsByUser(idRecipent: string) {
        return this.filter((n: GighubNotification) => n.idRecipent === idRecipent);
    }

    getUnseenNotificationsByUser(idRecipent: string) {
        return this.filter((n: GighubNotification) => n.idRecipent === idRecipent && !n.isSeen);

    }
}

export class CustomerArray extends DocumentArray<Customer> {
    constructor() {
        super();
        Object.setPrototypeOf(this, new.target.prototype);
    }

    updateChange(change: DocumentChange): DocumentArray<Customer> {
        const id = change.doc.id;
        const o = new Customer(change.doc.data() as Customer);
        o.id = id;
        if (change.type === 'removed') {
            const index = this.indexOf(o);
            if (index > -1) {
                this.splice(index, 1);
            }
        } else {
            const objIndex = this.findIndex(t => t.id === o.id);
            if (objIndex > -1) {
                this[objIndex] = o;
            } else {
                this.push(o);
            }
        }
        return this as DocumentArray<Customer>;
    }
};

export class FormationArray extends DocumentArray<Formation> {
    constructor() {
        super();
        Object.setPrototypeOf(this, new.target.prototype);
    }

    updateChange(change: DocumentChange): DocumentArray<Formation> {
        const id = change.doc.id;
        const o = new Formation(change.doc.data() as Formation);
        o.id = id;
        if (change.type === 'removed') {
            const index = this.indexOf(o);
            if (index > -1) {
                this.splice(index, 1);
            }
        } else {
            const objIndex = this.findIndex(t => t.id === o.id);
            if (objIndex > -1) {
                this[objIndex] = o;
            } else {
                this.push(o);
            }
        }
        return this as DocumentArray<Formation>;
    }
};

export class UserArray extends DocumentArray<User>{
    constructor() {
        super();
        Object.setPrototypeOf(this, new.target.prototype);
    }
}