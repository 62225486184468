import React, {FC, useContext, useState} from 'react';
import SettingsContainer from '../_elements/SettingsContainer';
import styled, {withTheme} from 'styled-components';
import {Routes} from '../../../constants/Routes';
import {Link, navigate} from '@reach/router';
import BandWrapper from './BandWrapper';
import {AppTheme} from '../../../theme';
import Fab from '../../_common/_elements/Fab';
import {RootStoreContext} from 'src/store/RootStore';
import {observer} from 'mobx-react-lite';
import SettingsTopNav from '../_elements/SettingsTopNav';
import DesktopSidebar from 'src/views/_common/sidebar/DesktopSidebar';
import {Band} from 'src/models/band';
import TextFieldLight from 'src/views/_common/_elements/TextFieldLight';
import useIsDesktop from 'src/hooks/useIsDesktop';

interface Props {
    theme: AppTheme;
    path: string;
}

const BandsSettingsContainer = styled('div')<{ isDesktop: boolean }>`
  padding: ${props => (props.isDesktop ? '4rem' : '2rem')};
  max-width: 1200px;
  margin: 1rem auto;
  position: relative;
  overflow-y: auto;
`;

const BandContainer = styled.div`
  color: ${props => props.theme.colors.secondary};
  position: relative;
`;

const SearchField = styled(TextFieldLight)`
  && {
    width: 100%;
    margin-bottom: 3rem;
    input {
      color: ${props => props.theme.colors.white};
    }
  }
`;

const Bands: FC<Props> = observer(({theme}) => {
    const {
        bandsStore: {observables: bands},
    } = useContext(RootStoreContext);
    const [query, setQuery] = useState('');
    const isDesktop = useIsDesktop();

    function filterBand(band: Band) {
        const searchQuery = query.toLowerCase();
        return band.name.toLowerCase().includes(searchQuery) || band.shorthand.toLowerCase().includes(searchQuery);
    }

    function renderBands() {
        if (!bands) {
            return <h1>no bands</h1>;
        } else {
            return bands.filter(filterBand).map(band => <BandWrapper key={band.id} band={band}/>);
        }
    }

    return (
        <SettingsContainer>
            {isDesktop && <DesktopSidebar/>}
            <div style={{width: '100%', marginTop: '2rem', position: 'relative'}}>
                {!isDesktop && <SettingsTopNav clickHandler={() => navigate(Routes.HOME)} text={'My Bands'}/>}
                <BandsSettingsContainer isDesktop={isDesktop}>
                    <SearchField
                        placeholder="Search for a band.."
                        autoFocus
                        value={query}
                        onChange={e => setQuery(e.target.value)}
                    />
                    <BandContainer>{renderBands()}</BandContainer>
                </BandsSettingsContainer>
                <Link to={Routes.BAND_SETTINGS}>
                    <Fab type={'ADD'}/>
                </Link>
            </div>
        </SettingsContainer>
    );
});

export default withTheme(Bands);
