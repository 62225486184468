import * as React from 'react';

interface IconProps {
  name: IconName;
  color?: string;
  size?: IconSize | string;
  className?: string;
  onClick?: any;
  testId?: string;
}

export enum IconName {
  CLOCK,
  CLOSE,
  SETTINGS,
  PERSON,
  NOTE,
  BACK,
  MENU,
  SEARCH,
  HOME,
  CALENDAR,
  GIGHUB_LOGO,
  EVENT,
  NOTIFICATIONS,
  ARROW_DOWN,
  ARROW_UP,
  ARROW_LEFT,
  ARROW_RIGHT,
  LOCATION,
  PEOPLE,
  CUSTOMERS,
  POWER,
  USER_ACCOUNT,
  ADD,
  EDIT,
  EMAIL,
  PHONE,
  DELETE,
  CHECK,
  CROSS,
  UPLOAD
}

export enum IconSize {
  TINY = '10px',
  SMALL = '20px',
  MEDIUM_SMALL = '24px',
  MEDIUM = '28px',
  LARGE = '36px',
  XL = '48px',
  XXL = '64px'
}

export default class Icon extends React.Component<IconProps, {}> {
  // todo makes this come from the theme!
  static defaultProps = {
    color: '#F3F3F3',
    size: IconSize.MEDIUM
  };

  render() {
    switch (this.props.name) {
      case IconName.CLOSE:
        return (
          <svg
            className={this.props.className}
            onClick={this.props.onClick}
            x="0px"
            y="0px"
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            width={this.props.size}
            height={this.props.size}
            viewBox="0 0 31.11 31.11"
            enableBackground="new 0 0 31.11 31.11"
          >
            <polygon
              fill={this.props.color}
              points="31.11,1.41 29.7,0 15.56,14.14 1.41,0 0,1.41 14.14,15.56 0,29.7 1.41,31.11 15.56,16.97   29.7,31.11 31.11,29.7 16.97,15.56 "
            />
          </svg>
        );
      case IconName.SETTINGS:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            onClick={this.props.onClick}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            width={this.props.size}
            height={this.props.size}
            viewBox="0 0 20 20"
          >
            <path fill="none" d="M0 0h20v20H0V0z" />
            <path
              fill={this.props.color}
              d="M15.95 10.78c.03-.25.05-.51.05-.78s-.02-.53-.06-.78l1.69-1.32c.15-.12.19-.34.1-.51l-1.6-2.77c-.1-.18-.31-.24-.49-.18l-1.99.8c-.42-.32-.86-.58-1.35-.78L12 2.34c-.03-.2-.2-.34-.4-.34H8.4c-.2 0-.36.14-.39.34l-.3 2.12c-.49.2-.94.47-1.35.78l-1.99-.8c-.18-.07-.39 0-.49.18l-1.6 2.77c-.1.18-.06.39.1.51l1.69 1.32c-.04.25-.07.52-.07.78s.02.53.06.78L2.37 12.1c-.15.12-.19.34-.1.51l1.6 2.77c.1.18.31.24.49.18l1.99-.8c.42.32.86.58 1.35.78l.3 2.12c.04.2.2.34.4.34h3.2c.2 0 .37-.14.39-.34l.3-2.12c.49-.2.94-.47 1.35-.78l1.99.8c.18.07.39 0 .49-.18l1.6-2.77c.1-.18.06-.39-.1-.51l-1.67-1.32zM10 13c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"
            />
          </svg>
        );
      case IconName.PERSON:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            className={this.props.className}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 24 24"
          >
            <path
              fill={this.props.color}
              d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        );
      case IconName.NOTE:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            height={this.props.size}
            onClick={this.props.onClick}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill={this.props.color}
              d="M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6z"
            />
          </svg>
        );
      case IconName.BACK:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            onClick={this.props.onClick}
            width={this.props.size}
            data-testid={this.props.testId || ''}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill={this.props.color}
              d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"
            />
          </svg>
        );
      case IconName.GIGHUB_LOGO:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            onClick={this.props.onClick}
            data-testid={this.props.testId || ''}
            width={this.props.size}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0, 0, 400,400"
          >
            <path
              d="M46.982 1.899 C 26.305 7.459,9.197 24.169,2.807 45.045 C 0.717 51.871,0.601 60.030,0.601 199.399 L 0.601 346.547 3.253 354.955 C 9.605 375.089,24.925 390.424,44.995 396.738 L 53.453 399.399 200.000 399.399 C 338.847 399.399,346.925 399.283,353.754 397.191 C 376.197 390.313,392.587 373.750,398.097 352.379 C 400.492 343.092,401.028 60.303,398.673 49.008 C 394.084 27.002,375.450 7.721,352.996 1.745 C 343.307 -0.834,56.607 -0.690,46.982 1.899 M240.976 70.176 C 306.448 83.892,355.124 127.188,365.002 180.496 C 366.348 187.760,370.190 187.146,319.520 187.757 L 278.078 188.256 272.169 175.760 C 237.524 102.504,167.448 64.214,129.865 98.005 C 112.244 113.848,105.725 150.906,114.625 184.643 L 116.202 190.625 95.639 191.405 C 84.329 191.834,65.744 192.186,54.340 192.189 L 33.605 192.192 34.345 187.087 C 46.251 104.904,143.660 49.790,240.976 70.176 M365.776 215.315 C 356.541 261.471,326.642 296.378,278.435 317.287 C 182.265 358.998,59.234 312.798,37.317 226.743 L 35.095 218.018 41.271 217.232 C 44.668 216.799,64.775 216.242,85.954 215.995 L 124.460 215.544 129.522 225.944 C 140.909 249.332,163.210 277.409,181.381 291.232 C 245.587 340.077,302.264 303.598,287.669 222.823 C 286.714 217.538,285.923 213.093,285.910 212.946 C 285.880 212.616,312.457 212.028,343.339 211.676 L 366.557 211.411 365.776 215.315 "
              fill={this.props.color || '#090909'}
              fillRule="evenodd"
            />
          </svg>
        );
      case IconName.MENU:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill={this.props.color}
              d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"
            />
          </svg>
        );
      case IconName.SEARCH:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 27.93 27.81"
          >
            <path
              d="M59.34,36.88l-8.67,8.61a9.86,9.86,0,1,0,3.82,3.85l8.67-8.61a2.71,2.71,0,1,0-3.82-3.85ZM50.28,54.07a4.41,4.41,0,1,1-4.41-4.41A4.42,4.42,0,0,1,50.28,54.07Z"
              transform="translate(-36.03 -36.09)"
            />
          </svg>
        );
      case IconName.HOME:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 79.85 79.95"
          >
            <path
              fill={this.props.color}
              d="M70.62,90.07H29.32C14.07,90.07,10,77.46,10,70.79v-22a15.51,15.51,0,0,1,4.59-11.06L36.34,16.13c10.78-10.78,22.54-4.71,27.26,0l21.77,22a15.51,15.51,0,0,1,4.53,11V70.79C89.9,86,77.29,90.07,70.62,90.07ZM49.68,15.62c-2.89,0-6.15,1.11-9.45,4.4L18.52,41.67a10,10,0,0,0-3,7.15v22c0,1.4.49,13.77,13.77,13.77h41.3c1.4,0,13.77-.49,13.77-13.77V49.08A10,10,0,0,0,81.46,42L59.7,20C59.05,19.38,55,15.62,49.68,15.62Z"
              transform="translate(-10.04 -10.11)"
            />
          </svg>
        );
      case IconName.NOTIFICATIONS:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 79.76 82.78"
          >
            <path
              fill={this.props.color}
              d="M70.55,9.89H29.3c-6.66,0-19.26,4-19.26,19.25v27.5c0,6.66,4,19.25,19.26,19.25H51l-3.51,7c-.68,1.36-2.67,6,.08,8.55a4.61,4.61,0,0,0,3.28,1.2c2.73,0,6.06-1.68,9.12-3.49.59-.32,14.46-7.88,21.53-14.77h0C87,69,89.6,62.44,89.8,53.57V29.14C89.8,22.48,85.78,9.89,70.55,9.89Zm7.07,60.57h0c-6.51,6.36-20.17,13.8-20.39,13.92A24.84,24.84,0,0,1,51.78,87a9.16,9.16,0,0,1,.6-1.61l4-7.95a4.86,4.86,0,0,0-4.34-7H29.3C16,70.39,15.55,58,15.54,56.64V29.14c0-13.27,12.36-13.74,13.76-13.75H70.55c13.27,0,13.74,12.35,13.75,13.75V53.51C84.09,63,80.62,67.53,77.62,70.46Z"
              transform="translate(-10.04 -9.89)"
            />
          </svg>
        );
      case IconName.CALENDAR:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 79.73 82.48"
          >
            <path
              fill={this.props.color}
              d="M74,18.4V9.89a2.75,2.75,0,0,0-5.5,0v8.25H30V9.89a2.75,2.75,0,1,0-5.5,0v8.86c-6.58,1.58-14.43,6.61-14.43,18.63v33c0,6.65,4,19.24,19.24,19.24H70.55c6.66,0,19.25-4,19.25-19.24v-33C89.8,31.26,86.37,20.15,74,18.4ZM29.32,23.64H70.55c11.21,0,13.28,8.79,13.66,12.37H15.65C16.49,24.13,28,23.65,29.32,23.64Zm55,46.74C84.3,83.64,72,84.11,70.55,84.12H29.31c-13.26,0-13.73-12.35-13.74-13.74V41.51H84.3Z"
              transform="translate(-10.07 -7.14)"
            />
          </svg>
        );
      case IconName.EVENT:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 24 24"
          >
            <path fill={this.props.color} d="M0 0h24v24H0z" />
            <path
              fill={this.props.color}
              d="M21 3H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H3V5h18v14zM8 15c0-1.66 1.34-3 3-3 .35 0 .69.07 1 .18V6h5v2h-3v7.03c-.02 1.64-1.35 2.97-3 2.97-1.66 0-3-1.34-3-3z"
            />
          </svg>
        );
      case IconName.ARROW_UP:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill={this.props.color}
              d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"
            />
          </svg>
        );
      case IconName.UPLOAD:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill={this.props.color}
              d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"
            />
          </svg>
        );
      case IconName.ARROW_DOWN:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill={this.props.color}
              d="M7.41,8.59L12,13.17l4.59-4.58L18,10l-6,6l-6-6L7.41,8.59z"
            />
          </svg>
        );
      case IconName.LOCATION:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill={this.props.color}
              d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
            />
          </svg>
        );
      case IconName.PEOPLE:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill={this.props.color}
              d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"
            />
          </svg>
        );
      case IconName.CUSTOMERS:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill={this.props.color}
              d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1z"
            />
          </svg>
        );
      case IconName.POWER:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill={this.props.color}
              d="M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z"
            />
          </svg>
        );
      case IconName.USER_ACCOUNT:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill={this.props.color}
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"
            />
          </svg>
        );
      case IconName.CLOCK:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill={this.props.color}
              d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
            />
            <path
              fill={this.props.color}
              d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"
            />
          </svg>
        );
      case IconName.ARROW_LEFT:
        return (
          <svg
            className={this.props.className}
            data-testid={this.props.testId || ''}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 18.95 37.73"
          >
            <path
              d="M56.81,68.87a2.65,2.65,0,0,1-1.88-.78L43.35,56.51c-4.77-4.78-2.61-10.4,0-13L54.93,31.91a2.67,2.67,0,0,1,3.77,3.77L47.12,47.26a3.62,3.62,0,0,0,0,5.49L58.7,64.32a2.67,2.67,0,0,1-1.89,4.55Z"
              transform="translate(-40.52 -31.13)"
            />
          </svg>
        );
      case IconName.ARROW_RIGHT:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill={this.props.color}
              d="M8.59,16.59L13.17,12L8.59,7.41L10,6l6,6l-6,6L8.59,16.59z"
            />
          </svg>
        );
      case IconName.ADD:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill={this.props.color}
              d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"
            />
          </svg>
        );
      case IconName.EDIT:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 23.7 23.63"
          >
            <g fill={this.props.color || ''}>
              <path
                d="M53.9,42.24,39,57.12a2.74,2.74,0,0,0,1.94,4.69,2.68,2.68,0,0,0,1.93-.8l15-14.89Z"
                transform="translate(-38.15 -38.19)"
              />
              <path
                d="M61.05,39a2.75,2.75,0,0,0-3.88,0l-2.29,2.28,3.88,3.88L61,42.88A2.75,2.75,0,0,0,61.05,39Z"
                transform="translate(-38.15 -38.19)"
              />
            </g>
          </svg>
        );
      case IconName.EMAIL:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill={this.props.color}
              d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
            />
          </svg>
        );
      case IconName.PHONE:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill={this.props.color}
              d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"
            />
          </svg>
        );
      case IconName.DELETE:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill={this.props.color}
              d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
            />
          </svg>
        );
      case IconName.CHECK:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 7.7 7.56"
          >
            <defs>
              <clipPath id="clip-path" transform="translate(0 0.02)">
                <rect className="cls-1" y="-0.02" width="7.7" height="7.56" />
              </clipPath>
            </defs>
            <title>Icon_Check_RZ</title>
            <g className="cls-2">
              <path
                className="cls-3"
                d="M7.31.08A.8.8,0,0,0,6.24.37l-2.89,5-2-2.43a.78.78,0,0,0-1.1-.12.79.79,0,0,0-.12,1.1L2.85,7.24l.06,0a1.07,1.07,0,0,0,.16.14l0,0,.05,0a.82.82,0,0,0,.3.07h0a.82.82,0,0,0,.34-.09l.08,0,.07,0a.9.9,0,0,0,.16-.18l0,0,3.46-6A.78.78,0,0,0,7.31.08Z"
                transform="translate(0 0.02)"
                fill={'grey'}
              />
            </g>
          </svg>
        );
      case IconName.CROSS:
        return (
          <svg
            className={this.props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}
            onClick={this.props.onClick}
            height={this.props.size}
            style={this.props.onClick ? { cursor: 'pointer' } : {}}
            viewBox="0 0 7.71 7.51"
          >
            <defs>
              <clipPath id="clip-path" transform="translate(0)">
                <rect className="cls-1" width="7.7" height="7.51" />
              </clipPath>
            </defs>
            <title>Icon_Cross_RZ</title>
            <g className="cls-2">
              <path
                className="cls-3"
                d="M7.44,6,5.1,3.76,7.44,1.5a.88.88,0,0,0,0-1.23.86.86,0,0,0-1.23,0L3.85,2.55,1.48.25A.86.86,0,0,0,.25.27a.86.86,0,0,0,0,1.23L2.6,3.76.27,6a.87.87,0,0,0,0,1.23.87.87,0,0,0,.63.26.89.89,0,0,0,.61-.24L3.85,5l2.38,2.3a.88.88,0,0,0,.6.24.85.85,0,0,0,.63-.26A.87.87,0,0,0,7.44,6Z"
                transform="translate(0)"
                fill={'grey'}
              />
            </g>
          </svg>
        );
      case IconName.CROSS:
        return (
            <svg
                className={this.props.className}
                xmlns="http://www.w3.org/2000/svg"
                width={this.props.size}
                onClick={this.props.onClick}
                height={this.props.size}
                style={this.props.onClick ? { cursor: 'pointer' } : {}}
                viewBox="0 0 7.71 7.51"
            >
              <defs>
                <clipPath id="clip-path" transform="translate(0)">
                  <rect className="cls-1" width="7.7" height="7.51" />
                </clipPath>
              </defs>
              <title>Icon_Cross_RZ</title>
              <g className="cls-2">
                <path
                    className="cls-3"
                    d="M7.44,6,5.1,3.76,7.44,1.5a.88.88,0,0,0,0-1.23.86.86,0,0,0-1.23,0L3.85,2.55,1.48.25A.86.86,0,0,0,.25.27a.86.86,0,0,0,0,1.23L2.6,3.76.27,6a.87.87,0,0,0,0,1.23.87.87,0,0,0,.63.26.89.89,0,0,0,.61-.24L3.85,5l2.38,2.3a.88.88,0,0,0,.6.24.85.85,0,0,0,.63-.26A.87.87,0,0,0,7.44,6Z"
                    transform="translate(0)"
                    fill={'grey'}
                />
              </g>
            </svg>
        );
      default:
        return null;
    }
  }
}
