import React, {FC} from 'react';
import MobileHeader from './MobileHeader';
import MobileFooter from './MobileFooter';
import styled, {withTheme} from 'styled-components';
import MobileSidebar from '../sidebar/MobileSidebar';
import {NavigateFn} from '@reach/router';
import {useToggle} from 'src/hooks/useToggle';
import {AppTheme} from 'src/theme';
import DesktopSidebar from '../sidebar/DesktopSidebar';
import useIsDesktop from 'src/hooks/useIsDesktop';
import {GighubMain} from "../_elements/GighubMain";


interface Props {
    style?: any;
    navigate: NavigateFn;
    theme: AppTheme;
    children?: any;
}


const MainContainer = styled.div`
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  background: ${props => props.theme.colors.background};
  overflow-x: hidden;
  display: flex;
`;

const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: calc(100vh - ${props => props.theme.layout.headerHeight} - ${props => props.theme.layout.footerHeight});
  margin-top: ${props => props.theme.layout.headerHeight};
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1350px;
  overflow-y: auto;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0 auto;
`;

const Page: FC<Props> = ({children, navigate, theme}) => {
    const {on: sidebarOpen, toggle: toggleSidebar} = useToggle();
    const isDesktop = useIsDesktop();
    return (
        <>
            {!isDesktop && <MobileSidebar open={sidebarOpen} toggle={toggleSidebar}/>}
            <MainContainer>
                {isDesktop && <DesktopSidebar/>}
                {!isDesktop && (
                    <>
                        <MobileHeader sidebarOpen={sidebarOpen} sidebarToggleHandler={toggleSidebar}
                                      navigate={navigate}/>
                        <Main>
                            <Content style={{padding: isDesktop ? '0 3.5rem' : '0'}}>{children}</Content>
                        </Main>
                    </>
                )}
                {isDesktop && (
                    <GighubMain>
                        <Content style={{padding: isDesktop ? '0 3.5rem' : '0'}}>{children}</Content>
                    </GighubMain>
                )}
                {!isDesktop && <MobileFooter/>}
            </MainContainer>
        </>
    );
};

export default withTheme(Page);
