import React, { useEffect, useState, FC, useContext } from 'react';
import styled from "styled-components";
import { IconName } from "../_elements/Icon";
import TabIcon from "./TabIcon";
import { Routes } from "../../../constants/Routes";
import { Band } from 'src/models/band';
import { RootStoreContext } from 'src/store/RootStore';
import { observer } from 'mobx-react-lite';



const Footer = styled.footer`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  position: fixed;
  align-items: center;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: ${props => props.theme.layout.footerHeight};
  background: ${props => props.theme.colors.background};
  `;



const MobileFooter: FC = observer(() => {

    const [location, setLocation] = useState('');
    const { notificationsStore: { getUnseenNotifications }, userStore: { currentUser }, bandsStore: { observables: bands, selectedBand, setSelectedBand } } = useContext(RootStoreContext);

    useEffect(() => {
        setLocation(window.location.pathname)
    }, [])

    function getSelectedBand() {
        if (selectedBand) {
            return selectedBand.imageUrl!;
        } else if (bands.length > 0 && setSelectedBand) {
            const selectedBandIdFromLocalStorage = localStorage.getItem('gighub-selected-band');
            if (selectedBandIdFromLocalStorage) {
                const newSelectedBand = bands.find((b: Band) => b.id === selectedBandIdFromLocalStorage);
                if (newSelectedBand) {
                    setSelectedBand(newSelectedBand.id);
                    return newSelectedBand.imageUrl || '';
                }
                return '';
            } else {
                setSelectedBand(bands[0].id);
                return bands[0].imageUrl!;
            }
        } else {
            return ""
        }
    }

    return (
        <Footer>
            {/*todo add notificationcount to context to have acces to it here*/}
            <TabIcon isactive={location === Routes.HOME ? 1 : 0} to={Routes.HOME} icon={IconName.HOME} title="Hub" />
            <TabIcon isactive={location === Routes.CALENDAR ? 1 : 0} to={Routes.CALENDAR} icon={IconName.CALENDAR}
                title={'Calendar'} />
            <TabIcon
                isactive={location === Routes.NOTIFICATIONS ? 1 : 0}
                to={Routes.NOTIFICATIONS}
                icon={IconName.NOTIFICATIONS}
                notificationCount={currentUser && getUnseenNotifications(currentUser.id!).length || 0}
                notificationLabel={true}
                title="Notifications" />
            <TabIcon isactive={location === Routes.EVENTS ? 1 : 0} to={Routes.EVENTS} image={getSelectedBand()}
                title="Events" />
        </Footer>
    )
})



export default MobileFooter;