import * as React from 'react';
import { FC, useState } from 'react';
import { GighubEvent } from '../../../../../models/gighubEvent';
import styled from 'styled-components';
import { TimeTableItem } from "../../../../../models/basics/TimeTableItem";
import { canWrite, MemberRoles } from '../../../../../models/basics/MemberRoles';
import Toggle from '../../../../_common/utilities/Toggle';
import TimeTableItemForm from './TimeTableItemForm';
import { TimeTableItem as TimeTableItemModel } from '../../../../../models/basics/TimeTableItem';
import { EventController } from '../../../../../controllers/eventController';
import { Dialog } from '@material-ui/core';
import { compareAsc } from 'date-fns';
import TimeTableItemView from "./TimeTableItemView";
import { UUID } from 'uuid-generator-ts';
import { RootStoreContext } from 'src/store/RootStore';
import { toast } from 'react-toastify';

interface Props {
    event: GighubEvent;
    authRole: MemberRoles;
}

const Title = styled.h2`
  text-align: center;
  padding: 1.5rem 0;
  background: #2D2D2D!important;
  color: white;
  margin-top: 5px;
`;

const AddTimeTableItem = styled.div`
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.secondary};
  text-align: center;
  color: ${props => props.theme.colors.blue};
  text-decoration: underline;
  cursor: pointer;
  margin-top: 2em;
`;

const TimeTableWrapper = styled.div`
`
const Container = styled.div`
  & ${TimeTableWrapper} > :nth-child(odd) {
    background: #ededed;
  }
`;

const Button = styled.button`
  background-color: grey; 
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 1rem;
`;

const TimeTable: FC<Props> = ({ event, authRole }) => {
    const [timeTable, setTimeTable] = useState<{ [id: string]: TimeTableItemModel }>(event.timeTable || {});
    const {
        bandAddressesStore,
    } = React.useContext(RootStoreContext);

    const handleSubmit = async (timeTableItem: TimeTableItem, id?: string) => {
        const uuid = new UUID();
        timeTableItem.id = id || uuid.getDashFreeUUID();
        try {
            if (event && event.id && timeTableItem) {
                if (timeTableItem.isRoute) {
                    const request = {
                        origin: timeTableItem.routeStart,
                        destination: timeTableItem.routeEnd,
                        travelMode: google.maps.TravelMode.DRIVING,
                        drivingOptions: {
                            departureTime: timeTableItem.timeStart,
                            trafficModel: google.maps.TrafficModel.PESSIMISTIC
                        }
                    }

                    await new google.maps.DirectionsService().route(request, async (response, status) => {
                        // @ts-ignore possible bug mit google maps typescript api. funktioniert aber
                        if (status === 'OK' && response) {
                            if (response.routes.length > 0 && response.routes[0].legs.length > 0) {
                                const newRoute = response.routes[0].legs[0];
                                if (newRoute && newRoute.distance && newRoute.duration) {
                                    timeTableItem.isRoute = true;
                                    timeTableItem.distanceH = newRoute.duration_in_traffic ? newRoute.duration_in_traffic.text : newRoute.duration.text;
                                    timeTableItem.distanceKm = newRoute.distance.text;
                                    timeTableItem.timeEnd = new Date(timeTableItem.timeStart.getTime() + (newRoute.duration_in_traffic ? newRoute.duration_in_traffic.value : newRoute.duration.value) * 1000);
                                    timeTable[timeTableItem.id] = new TimeTableItemModel(timeTableItem);
                                    event.timeTable = timeTable;
                                    await new EventController(event.idBand).update(event.id ? event.id : "", event);
                                    setTimeTable(timeTable);
                                }
                            }
                        } else {
                            console.log(response, status)
                            alert("there was a problem calculating your route. please take a look at your addresses")
                        }
                    });
                } else {
                    timeTable[timeTableItem.id] = new TimeTableItemModel(timeTableItem);
                    event.timeTable = timeTable;
                    await new EventController(event.idBand).update(event.id, event);
                    setTimeTable(timeTable);
                }
            }
        } catch (e) {
            alert(e.message);
        }
    }

    async function handleDelete(id: string) {
        try {
            if (event && event.id && event.timeTable && event.timeTable[id]) {
                delete event.timeTable[id];
                await new EventController(event.idBand).update(event.id, event);
                setTimeTable(event.timeTable);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const [clipboardText, setClipboardText] = useState('');

    const handleCopyToClipboard = () => {
        setClipboardText(timeTableContent);
        // Copy the text to the clipboard
        if (navigator.clipboard) {
            navigator.clipboard.writeText(timeTableContent).then(() => {
                toast('Time table copied to clipboard!');
            });
        }
    };

    const generateTimeTableContent = (timeTableItem: TimeTableItem) => {
        const { timeStart, timeEnd, title, isRoute } = timeTableItem;

        // Define a format for the time display
        const timeFormat = 'HH:mm';

        // Create a formatted string for the time table item
        const formattedTimeStart = new Date(timeStart).toLocaleString('de-DE', {
            hour: '2-digit',
            minute: '2-digit'
        });

        const formattedTimeEnd = new Date(timeEnd).toLocaleString('de-DE', {
            hour: '2-digit',
            minute: '2-digit'
        });

        let timeTableText = '';

        // Check if it's a route or regular time table item
        if (isRoute) {
            // If it's a route, include the time start and end
            timeTableText = `🚗 ${formattedTimeStart} - ${formattedTimeEnd}, ${title}`;
        } else {
            // If it's not a route, include only the time start and description
            if (formattedTimeStart !== formattedTimeEnd) {
                timeTableText = `🕐 ${formattedTimeStart} - ${formattedTimeEnd}: ${title}`;
            } else {
                timeTableText = `🕐 ${formattedTimeStart}: ${title}`; 
            }
        }

        return timeTableText + "\n";
    };


    let lastDay = new Date()
    const timeTableArray: TimeTableItemModel[] = []
    Object.keys(timeTable).forEach(key => {
        timeTableArray.push(timeTable[key])
    });
    let timeTableContent = '';
    return (
        <Container>
            <TimeTableWrapper>
                {timeTableArray
                    .slice()
                    .sort((a: TimeTableItemModel, b: TimeTableItemModel) => compareAsc(new Date(a.timeStart), new Date(b.timeStart)))
                    .map(
                        (item, i) => {
                            item.timeStart = new Date(item.timeStart)
                            const ttiv = []
                            if (lastDay.getDay() !== item.timeStart.getDay()) {
                                ttiv.push(<Title>{item.timeStart.toLocaleDateString('de', {
                                    weekday: "short",
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric"
                                })}</Title>)
                            }
                            ttiv.push(<TimeTableItemView
                                key={item.id}
                                id={item.id}
                                timeTableItem={item}
                                authRole={authRole}
                                handleSubmit={handleSubmit}
                                handleDelete={handleDelete}
                                idBand={event.idBand}
                                event={event}
                            >
                                {i}
                            </TimeTableItemView>)
                            const itemContent = generateTimeTableContent(item);
                            timeTableContent += itemContent; // Concatenate the item content to the result
                            lastDay = item.timeStart
                            return ttiv
                        }
                    )}
                {canWrite(authRole) && (
                    <AddTimeTableItem>
                        <Toggle>
                            {({ toggle, on }) => (
                                <>
                                    <label onClick={toggle}>add timetable element</label>
                                    <br />
                                    {on && (
                                        <Dialog open={on} onClose={toggle}>
                                            <TimeTableItemForm
                                                handleSubmit={handleSubmit}
                                                timeTableItem={new TimeTableItem({
                                                    timeStart: event.dateStart,
                                                    timeEnd: event.dateStart
                                                })}
                                                toggle={toggle}
                                                idBand={event.idBand}
                                                event={event}
                                            />
                                        </Dialog>
                                    )}
                                </>
                            )}
                        </Toggle>
                    </AddTimeTableItem>
                )}
            </TimeTableWrapper>
            <br />
            <Button onClick={handleCopyToClipboard}>Copy to Clipboard</Button>
        </Container>
    );
};
export default TimeTable;
