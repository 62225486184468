import React, { FC } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@material-ui/core';
import { BlueButton } from '.';
import styled from 'styled-components';
import { User } from 'src/models/user';
import { Links } from 'src/constants/Routes';

interface Props {
  on: boolean;
  toggle: () => void;
  currentUser: User;
  bandId: string;
}

const PriceText = styled.p`
  color: ${props => props.theme.colors.blue};
  margin-top: 2rem;
  > strong {
    font-size: 2rem 3;
  }
`;

const PaymentDialog: FC<Props> = ({ on, toggle, currentUser, bandId }) => {
  function handlePayment() {
    // TODO: successpage
    if (currentUser.id) {
      (window as any).stripe
        .redirectToCheckout({
          items: [{ plan: 'plan_FwnI3iQANOqjAW', quantity: 1 }],
          successUrl: `${Links.SUCCESS}/${bandId}`,
          cancelUrl: 'https://gighub.ch/',
          clientReferenceId: bandId,
          customerEmail: currentUser.email
        })
        .then(function(result: any) {
          console.log('Results from stripe checkout', { result });
          if (result.error) {
            console.log(result.error.message);
          }
        });
    }
  }
  return (
    <Dialog open={on} onClose={toggle}>
      <DialogTitle>Gighub - Premium</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Remove all limitiations (unlimited events, bands and customers)
        </DialogContentText>
        <PriceText>
          $<strong>{'4.95'}</strong>/ Month
        </PriceText>
        <BlueButton
          style={{ marginTop: '2rem' }}
          id="checkout-button-plan_FPs1NcH4TY1oKN"
          role="link"
          onClick={handlePayment}
        >
          Upgrade
        </BlueButton>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentDialog;
