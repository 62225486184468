import * as React from 'react';
import { FC, useContext } from 'react';
import { RootStoreContext } from 'src/store/RootStore';
import { observer } from 'mobx-react-lite';
import { BlueButton } from 'src/views/_common/_elements';
import { useToggle } from 'src/hooks/useToggle';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import styled from 'styled-components';
import PaymentDialog from 'src/views/_common/_elements/PaymentDialog';

interface Props {
  bandId: string;
}



const PaymentButton: FC<Props> = observer(({ bandId }) => {
  const { on, toggle } = useToggle(false);
  const {
    userStore: { currentUser },
  } = useContext(RootStoreContext);


  return (
    <>
      <BlueButton style={{ marginTop: '2rem' }} onClick={toggle}>
        Upgrade to Premium
    </BlueButton>
      {currentUser && <PaymentDialog on={on} toggle={toggle} currentUser={currentUser} bandId={bandId} />}
    </>
  );
});

export default PaymentButton;
