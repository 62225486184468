import styled from "styled-components";
import transparentize from "polished/lib/color/transparentize";


const Dialog = styled.div`
  width: 100%;
  padding: 1.3rem 2rem;
  margin: .7rem auto;
  text-align: center;
  border-radius: 5px;
`;

export const DangerDialog = styled(Dialog)`
  background: ${props => props.theme.colors.danger};
  color: ${props => props.theme.colors.secondary};
     &:disabled {
        background: ${props => transparentize(.3, props.theme.colors.primary)};
        color: ${props => transparentize(.3, props.theme.colors.secondary)};
     }
`;

export const SuccessDialog = styled(Dialog)`
  background: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.primary};
     &:disabled {
        background: ${props => transparentize(.3, props.theme.colors.secondary)};
        color: ${props => transparentize(.3, props.theme.colors.primary)};
     }
`;
